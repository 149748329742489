import { LocalStorageService } from '@/cpm/shared/services/localStorage.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from '@/cpm/shared/services/user.service';

@Component({
  selector: 'app-cpm-main-page',
  templateUrl: './cpm-main-page.component.html',
  styleUrls: ['./cpm-main-page.component.scss']
})
export class CPMMainPageComponent implements OnInit {

  constructor(public locaStorageService: LocalStorageService,
    private userService:UserService) { }

  ngOnInit(): void {
    const url = new URL(window.location.href);

    const queryParams = url.searchParams;
    this.handleQueryParams(queryParams);

    const pathName = url.pathname;
    const pathParts = pathName.split('/');
    let vofm = JSON.parse(localStorage.getItem('isVofm'))
    if (pathParts[2] === 'public') {
      this.userService.setUserType(
        {
          isPublic: true,
          linkShareUuid: pathParts[3],
          isVofm: vofm
        }
      )
    } else {
      this.userService.setUserType(
        {
          isPublic: false,
          linkShareUuid: '',
          isVofm: vofm
        }
      );
    }
  }

  ngAfterViewInit() {
    this.loadGoogleTranslate();
  }
  
  loadGoogleTranslate() {
    const defaultLanguage = new URL(window.location.href).searchParams.get('preferredLanguage') ?? 'en';
    if(defaultLanguage == 'en') {
        document.body.classList.add('notranslate');
    } else {
        document.body.classList.remove('notranslate');
    }
    window['gtranslateSettings'] = {
        "default_language": "",
        "detect_browser_language": false,
        "wrapper_selector": ".gtranslate_wrapper",
        "horizontal_position": "left",
        "vertical_position": "bottom",
        "select_language_label": "select"
    };
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.gtranslate.net/widgets/latest/dropdown.js';
    script.onload = () => {
        const intervalId = setInterval(() => {
            const select = document.querySelector('.goog-te-combo') as HTMLSelectElement;
            if (select && select.options.length > 0) {
              this.applyPreselectedLanguage(defaultLanguage);
              clearInterval(intervalId);
            }
          }, 500);
    }
    script.onerror = () => {
        console.log('Error loading google translate script');
    }
    document.head.appendChild(script);
  }

  applyPreselectedLanguage(lang: string) {
    const intervalId = setInterval(() => {
      const select = document.querySelector('.goog-te-combo') as HTMLSelectElement;
      if (select && select.options.length > 0) {
        select.value = lang;
        select.dispatchEvent(new Event('change'));
        clearInterval(intervalId);
      }
    }, 100);
  }

  handleQueryParams(params) {
    if (params.has('token')) {
      const token = params.get('token')
      this.locaStorageService.persistToken(token);
    }
    if (params.has('userId')) {
      const userId = params.get('userId')
      this.locaStorageService.persistUserId(userId);
    }

    if (params.has('portal')) {
      const portal = params.get('portal');
      localStorage.setItem("portal",JSON.stringify(portal));
    }
    if (params.has('siteDetail')) {
      const siteDetail = params.get('siteDetail');
      const siteNameFromFacPortal = JSON.parse(siteDetail).siteName;
      const siteId = JSON.parse(siteDetail).siteId;
      localStorage.setItem("siteNameFromFacPortal",JSON.stringify(siteNameFromFacPortal));
      localStorage.setItem("siteId",JSON.stringify(siteId));
    }
    if (params.has('lastViewedDashboard')) {
      const lastViewedPlantId = params.get('lastViewedDashboard');
      localStorage.setItem("lastViewedPlantId",JSON.stringify(lastViewedPlantId));
    }
    if (params.has('isVofm')) {
      const isVofm = params.get('isVofm');
      localStorage.setItem("isVofm",isVofm);
    }else{
      localStorage.setItem("isVofm",'false');
    }

    if (params.has('certificateLevel')) {
      const certificateLevel = params.get('certificateLevel');
      localStorage.setItem("certificateLevel",JSON.stringify(certificateLevel));
    }
  }

}
