export class Drawflow {
    constructor(container, render = null, parent = null) {
      this.events = {};
      this.container = container;
      this.precanvas = null;
      this.nodeId = 1;
      this.ele_selected = null;
      this.node_selected = null;
      this.drag = false;
      this.reroute = false;
      this.reroute_fix_curvature = false;
      this.curvature = 0.5;
      this.reroute_curvature_start_end = 0.5;
      this.reroute_curvature = 0.5;
      this.reroute_width = 6;
      this.drag_point = false;
      this.editor_selected = false;
      this.connection = false;
      this.connection_ele = null;
      this.connection_selected = null;
      this.canvas_x = 0;
      this.canvas_y = 0;
      this.pos_x = 0;
      this.pos_x_start = 0;
      this.pos_y = 0;
      this.pos_y_start = 0;
      this.mouse_x = 0;
      this.mouse_y = 0;
      this.line_path = 5;
      this.first_click = null;
      this.force_first_input = false;
      this.draggable_inputs = true;
      this.useuuid = false;
      this.parent = parent;
  
      this.noderegister = {};
      this.render = render;
      this.drawflow = { "drawflow": { "Home": { "data": {} }}};
      // Configurable options
      this.module = 'Home';
      this.editor_mode = 'edit';
      this.zoom = 0.06;
      this.zoom_max = 0.8;
      this.zoom_min = 0.06;
      this.zoom_value = 0.01;
      this.zoom_last_value = 0.06;
      this.deletedNodeData = {};
      this.nodeDeleteClicked = false;
      this.shiftPressed = false;
  
      // Mobile
      this.evCache = new Array();
      this.prevDiff = -1;
      this.previousSelectedNode = null;

     this.equipConfiguration = {
        bypassPipeList: [
          "chilledWaterBypassHeaderPipe",
          "condenserWaterBypassHeaderPipe",
          "hotWaterBypassHeaderPipe",
          "dualTempBypassHeaderPipe"
        ],
      
        headerPipeList: [
          "chilledWaterReturnHeaderPipe",
          "chilledWaterSupplyHeaderPipe",
          "condenserWaterReturnHeaderPipe",
          "condenserWaterSupplyHeaderPipe",
          "hotWaterReturnHeaderPipe",
          "hotWaterSupplyHeaderPipe",
          "dualTempReturnHeaderPipe",
          "dualTempSupplyHeaderPipe"
        ],
      
        parentEquipList: [
          "waterCooledChiller",
          "airCooledChiller",
          "steamBoiler",
          "condenserBoiler",
          "coolingTowerSingleFan",
          "coolingTowerTwoFan",
          "coolingTowerFourFan",
          "heatExchanger",
          "building2Pipe",
          "building2PipeHot",
          "dualTempBuilding2Pipe",
          "building4Pipe",
          "pump1Vertical_forward",
          "pump1Vertical_backward",
          "heatExchanger_forward",
          "heatExchanger_backward"
        ],
      
        btuandPumpList: [
          "btuMeter",
          "pump1Vertical",
          "pump1Vertical_forward",
          "pump1Vertical_backward"
        ],
      
        pumpList: [
          "pump1Vertical",
          "pump1Vertical_forward",
          "pump1Vertical_backward"
        ],
      
        dualTempPipeList: [
          "dualTempReturnHeaderPipe",
          "dualTempSupplyHeaderPipe"
        ],
      
        nonBuildingParentEquip: [
          "waterCooledChiller",
          "airCooledChiller",
          "steamBoiler",
          "condenserBoiler",
          "coolingTowerSingleFan",
          "coolingTowerTwoFan",
          "coolingTowerFourFan"
        ],
      
        buildingList: [
          "building2Pipe",
          "building2PipeHot",
          "dualTempBuilding2Pipe",
          "building4Pipe"
        ],
      
        dualTempEquip: [
          "dualTempBuilding2Pipe",
          "dualTempReturnHeaderPipe",
          "dualTempSupplyHeaderPipe"
        ],
      
        threeWayValveList: [
          "threeWayValveDivert_forward",
          "threeWayValveDivert_backward",
          "threeWayValvemixing_forward",
          "threeWayValvemixing_backward"
        ],
      
        headerPipeClassMapping: {
          'chilledWaterReturnHeaderPipe': "CWReturnClass",
          'chilledWaterSupplyHeaderPipe': "CWSupplyClass",
          'condenserWaterReturnHeaderPipe': "CDWReturnClass",
          'condenserWaterSupplyHeaderPipe': "CDWSupplyClass",
          'hotWaterReturnHeaderPipe': "boilerReturnClass",
          'hotWaterSupplyHeaderPipe': "boilerSupplyClass",
          'chilledWaterBypassHeaderPipe': "CWBypassClass",
          'condenserWaterBypassHeaderPipe': "CDWBypassClass",
          'hotWaterBypassHeaderPipe': "HotBypassClass",
          'dualTempHeaderPipe': "DTClass",
          'dualTempReturnHeaderPipe': 'DTReturnClass',
          'dualTempSupplyHeaderPipe': "DTSupplyClass",
          'dualTempBypassHeaderPipe': "DTBypassClass"
        },
      
        pipeClassMappingToHeaderPipe: {
          "CWReturnClass": 'chilledWaterReturnHeaderPipe',
          "CWSupplyClass": 'chilledWaterSupplyHeaderPipe',
          "CDWReturnClass": 'condenserWaterReturnHeaderPipe',
          "CDWSupplyClass": 'condenserWaterSupplyHeaderPipe',
          "boilerReturnClass": 'hotWaterReturnHeaderPipe',
          "boilerSupplyClass": 'hotWaterSupplyHeaderPipe',
          "CWBypassClass": 'chilledWaterBypassHeaderPipe',
          "CDWBypassClass": 'condenserWaterBypassHeaderPipe',
          "HotBypassClass": 'hotWaterBypassHeaderPipe',
          "DTClass": 'dualTempHeaderPipe',
          'DTReturnClass': 'dualTempReturnHeaderPipe',
          "DTSupplyClass": 'dualTempSupplyHeaderPipe',
          "DTBypassClass": 'dualTempBypassHeaderPipe'
        },
      
        sensorEquipList: [
          "valveActuator_forward",
          "valveActuator_backward",
          "temperatureSensor",
          "temperatureSensor_forward",
          "temperatureSensor_backward",
          "wellTemperature",
          "wellTemperature_forward",
          "wellTemperature_backward",
          "pressureSensor_forward",
          "pressureSensor_backward",
          "diffPressureSensor_forward",
          "diffPressureSensor_backward",
          "flowSensor_forward",
          "flowSensor_backward",
          "flowSwitch_forward",
          "flowSwitch_backward",
          "btuMeter",
          "threeWayValveDivert_forward",
          "threeWayValveDivert_backward",
          "threeWayValvemixing_forward",
          "threeWayValvemixing_backward",
          "connectorLbend"
        ],
      
        mainEquipList: [
          "waterCooledChiller",
          "airCooledChiller",
          "steamBoiler",
          "condenserBoiler",
          "coolingTowerSingleFan",
          "coolingTowerTwoFan",
          "coolingTowerFourFan",
          "building2Pipe",
          "building2PipeHot",
          "dualTempBuilding2Pipe"
        ],
      
        connectorList:[
          "byPassLine-connectTwo",
          "subEquip-connectTwo",
          "connectorLbend",
          "connectTwo",
          "pipeDemarkation"
        ],
      
        chiller_Or_Boiler :[
          "waterCooledChiller",
          "airCooledChiller",
          "steamBoiler",
          "condenserBoiler"
        ],

       coolingTowerList : [
         "coolingTowerSingleFan",
         "coolingTowerTwoFan",
         "coolingTowerFourFan"
       ],
      
        pipeColorMapping: {
          boilerSupplyClass: '#FFB3B3',
          boilerReturnClass: '#FFDBD0',
          CWSupplyClass: '#9DD1FF',
          CWReturnClass: '#D4EBFF',
          CDWSupplyClass: '#CBF6E4',
          CDWReturnClass: '#99EBC7',
          DTSupplyClass: '#b9b9b9',
          DTReturnClass: '#e4e4e4'
        },
      
        leadnLagColorMapping: {
          CWReturnClass: ['CWSupplyClass'],
          CWSupplyClass: ['CWReturnClass'],
          boilerReturnClass: ['boilerSupplyClass'],
          boilerSupplyClass: ['boilerReturnClass']
        },
      
        condensorPipeClass: ['CDWSupplyClass',
          'CDWReturnClass'],
      
        // For dual temp pipe PathBorder color
        pipeColorObj: {
          light: {
            boilerReturnObj: '#FFDBD0',
            boilerSupplyObj: '#FFB3B3',
            chillerReturnObj: '#D4EBFF',
            chillerSupplyObj: '#9DD1FF',
            condenserReturnObj: '#CBF6E4',
            condenserSupplyObj: '#99EBC7',
          },
          dark: {
            boilerReturnObj: '#AE7769',
            boilerSupplyObj: '#8C4D4D',
            chillerReturnObj: '#A1BCD1',
            chillerSupplyObj: '#50799E',
            condenserReturnObj: '#A8C9BB',
            condenserSupplyObj: '#6DA38C',
          }
        },

        // For dual temp pipe MainPath color
        patternColorObj: {
          light: {
            boilerReturnObj: '#FF9371',
            boilerSupplyObj: '#d7704f',
            chillerReturnObj: '#65B7FF',
            chillerSupplyObj: '#2D9CFF',
            condenserReturnObj: '#18D084',
            condenserSupplyObj: '#16B872',
          },
          dark: {
            boilerReturnObj: '#FAA78D',
            boilerSupplyObj: '#F98888',
            chillerReturnObj: '#214F77',
            chillerSupplyObj: '#6FBAFC',
            condenserReturnObj: '#329F72',
            condenserSupplyObj: '#5AFFB8',
          }
        },
      
        forwardSensorsList: [
          "valveActuator_forward",
          "diffPressureSensor_forward",
          "flowSensor_forward",
          "temperatureSensor_forward",
          "threeWayValvemixing_forward",
          "threeWayValveDivert_forward",
          "pressureSensor_forward",
          "pump1Vertical_forward",
          "flowSwitch_forward"
        ],
      
        heatExchangerList: [
          "heatExchanger",
          "heatExchanger_forward",
          "heatExchanger_backward"
        ],
      
        rightsideEquipList: [
          'coolingTowerSingleFan',
          'coolingTowerTwoFan',
          'coolingTowerFourFan',
          'building2PipeHot',
          'building2Pipe',
          'building4Pipe',
          'dualTempBuilding2Pipe'
        ],

        animationLefttoRight: [
          'CWReturnClass',
          'CDWReturnClass',
          'boilerSupplyClass'
        ],
        animationRighttoLeft: [
          'CWSupplyClass',
          'CDWSupplyClass',
          'boilerReturnClass'
        ],

        dualTempPipeClass: ['DTSupplyClass', 'DTReturnClass'],
      }
    }

    equipPointDropDown() {
      document.getElementById("myDropdown").classList.toggle("show");
    }
  
    start () {
      // console.info("Start Drawflow!!");
      this.container.classList.add("parent-drawflow");
      this.container.tabIndex = 0;
      this.precanvas = document.createElement('div');
      this.precanvas.classList.add("drawflow");
      this.container.appendChild(this.precanvas);
  
      /* Mouse and Touch Actions */
      this.container.addEventListener('mouseup', this.dragEnd.bind(this));
      this.container.addEventListener('mousemove', this.position.bind(this));
      this.container.addEventListener('mousedown', this.click.bind(this) );
  
      // this.container.addEventListener('touchend', this.dragEnd.bind(this));
      // this.container.addEventListener('touchmove', this.position.bind(this));
      // this.container.addEventListener('touchstart', this.click.bind(this));
  
      /* Context Menu */
      this.container.addEventListener('contextmenu', this.contextmenu.bind(this));
      /* Delete */
      this.container.addEventListener('keydown', this.key.bind(this));
  
      /* Zoom Mouse */
      this.container.addEventListener('wheel', this.zoom_enter.bind(this));
      /* Update data Nodes */
      this.container.addEventListener('input', this.updateNodeValue.bind(this));
  
      this.container.addEventListener('dblclick', this.dblclick.bind(this));
      /* Mobile zoom */
      this.container.onpointerdown = this.pointerdown_handler.bind(this);
      this.container.onpointermove = this.pointermove_handler.bind(this);
      this.container.onpointerup = this.pointerup_handler.bind(this);
      this.container.onpointercancel = this.pointerup_handler.bind(this);
      this.container.onpointerout = this.pointerup_handler.bind(this);
      this.container.onpointerleave = this.pointerup_handler.bind(this);          
      this.load();

      var canvas = document.getElementById('drawflow');
      canvas.onselectstart = function () { return false; }
    }
  
    /* Mobile zoom */
    pointerdown_handler(ev) {
     this.evCache.push(ev);
    }
  
    pointermove_handler(ev) {
     for (var i = 0; i < this.evCache.length; i++) {
       if (ev.pointerId == this.evCache[i].pointerId) {
          this.evCache[i] = ev;
       break;
       }
     }
  
     if (this.evCache.length == 2) {
       // Calculate the distance between the two pointers
       var curDiff = Math.abs(this.evCache[0].clientX - this.evCache[1].clientX);
  
       if (this.prevDiff > 100) {
         if (curDiff > this.prevDiff) {
           // The distance between the two pointers has increased
  
           this.zoom_in();
         }
         if (curDiff < this.prevDiff) {
           // The distance between the two pointers has decreased
           this.zoom_out();
         }
       }
       this.prevDiff = curDiff;
     }
    }
  
    pointerup_handler(ev) {
      this.remove_event(ev);
      if (this.evCache.length < 2) {
        this.prevDiff = -1;
      }
    }
    remove_event(ev) {
     // Remove this event from the target's cache
     for (var i = 0; i < this.evCache.length; i++) {
       if (this.evCache[i].pointerId == ev.pointerId) {
         this.evCache.splice(i, 1);
         break;
       }
     }
    }

    // applyConnectionAnimation(connection) {
    //   const fromNode = connection.output_id;
    //   const toNode = connection.input_id;
  
    //   const fromNodePosition = this.getNodeFromId(fromNode);
    //   const toNodePosition = this.getNodeFromId(toNode);
  
    //   let direction;
    //   if (fromNodePosition.pos_x < toNodePosition.pos_x) {
    //     direction = "left-to-right";
    //   } else {
    //     direction = "right-to-left";
    //   }
  
    //   const connectionElement = document.querySelector(`.connection.node_in_node-${toNode}.node_out_node-${fromNode}`);
    //   if (connectionElement) {
    //     if (direction === "left-to-right") {
    //       connectionElement.classList.add("connection-left-to-right");
    //     } else if (direction === "right-to-left") {
    //       connectionElement.classList.add("connection-right-to-left");
    //     }
    //   }
    // }




    /* End Mobile Zoom */
    load() {
      for (var key in this.drawflow.drawflow[this.module].data) {
        this.addNodeImport(this.drawflow.drawflow[this.module].data[key], this.precanvas, key);
      }
  
      if(this.reroute) {
        for (var key in this.drawflow.drawflow[this.module].data) {
          this.addRerouteImport(this.drawflow.drawflow[this.module].data[key]);
        }
      }
  
      for (var key in this.drawflow.drawflow[this.module].data) {
        this.updateConnectionNodes('node-'+key);
      }

      // Object.values(this.drawflow.drawflow.Home.data).forEach(node => {
      //   node.outputs.output_1.connections.forEach(connection => {
      //     this.applyConnectionAnimation(connection);
      //   });
      // });
  
      const editor = this.drawflow.drawflow;
      let number = 1;
      Object.keys(editor).map(function(moduleName, index) {
        Object.keys(editor[moduleName].data).map(function(id, index2) {
          if(parseInt(id) >= number) {
            number = parseInt(id)+1;
          }
        });
      });
      this.nodeId = number;

      if (this.editor_mode == "fixed") {
        this.dispatch('loadMasterViewPoints', this.drawflow);
        let LbendconnectorElement = document.querySelectorAll(".connectorLbend");
        LbendconnectorElement = Array.from(LbendconnectorElement);
        if (LbendconnectorElement.length) {
          LbendconnectorElement.forEach(element => {
            element.style.display = 'none';
          });
        }
      }
      this.dispatch('plantImported',this.drawflow );
      this.dispatch('hideLoader', true);
    }
  
    removeReouteConnectionSelected(){
      this.dispatch('connectionUnselected', true);
      if(this.reroute_fix_curvature) {
        this.connection_selected.parentElement.querySelectorAll(".main-path").forEach((item, i) => {
          item.classList.remove("selected");
        });
      }
    }
  
    click(e) {
      this.nodeDeleteClicked = false;
      this.drag = false;
      this.dispatch('click', e);
      if(this.editor_mode === 'fixed') {
        this.dispatch('clickEndFixed', e);
        if(e.srcElement.className == 'masterPointSelect'|| e.srcElement.className == 'masterPointSelectOption'){

        }else{
          e.preventDefault();
        }
        //return false;
       
         if(e.target.classList[0] === 'parent-drawflow' || e.target.classList[0] === 'drawflow') {
           this.ele_selected = e.target.closest(".parent-drawflow");
         } else {
           return false;
         }
      } else if(this.editor_mode === 'view') {
        if(e.target.closest(".drawflow") != null || e.target.matches('.parent-drawflow')) {
          this.ele_selected = e.target.closest(".parent-drawflow");
          e.preventDefault();
        }
      } else {
        this.first_click = e.target;
        this.ele_selected = e.target;
        if(e.button === 0) {
          this.contextmenuDel();
        }
  
        if(e.target.closest(".drawflow_content_node") != null) {
          this.ele_selected = e.target.closest(".drawflow_content_node").parentElement;
        }
      }
      switch (this.ele_selected.classList[0]) {
        case 'drawflow-node':
          if(this.node_selected != null) {
            this.node_selected.classList.remove("selected");
            if(this.node_selected != this.ele_selected) {
              this.dispatch('nodeUnselected', true);
            }
          }
          if(this.connection_selected != null) {
            this.connection_selected.classList.remove("selected");
            this.removeReouteConnectionSelected();
            this.connection_selected = null;
          }
          if(this.node_selected != this.ele_selected) {
            this.dispatch('nodeSelected', this.ele_selected.id.slice(5));
          }
          this.node_selected = this.ele_selected;
          this.node_selected.classList.add("selected");
          if(!this.draggable_inputs) {
            if(e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA' && e.target.tagName !== 'SELECT' && e.target.hasAttribute('contenteditable') !== true) {
              this.drag = true;
            }
          } else {
            if(e.target.tagName !== 'SELECT') {
              this.drag = true;
            }
          }
          break;
        case 'output':
          let outputId = e.target.parentElement.parentElement.id.slice(5);
          let outputClass = e.target.classList[1];
          // Create only single connection from output.
          if(this.getNodeFromId(outputId).class !== 'pipeDemarkation' && this.getNodeFromId(outputId).class !== 'byPassLine-connectTwo'  && this.getNodeFromId(outputId).class !== 'connectTwo'  && this.getNodeFromId(outputId).class !== 'connectorLbend' && this.getNodeFromId(outputId).outputs[outputClass].connections.length > 0 && this.getNodeFromId(outputId).class !== 'subEquip-connectTwo'){
            // do nothing in case the node is "connector" and there are no connections from this node.
          } else{        
            this.connection = true;
            if(this.node_selected != null) {
              this.node_selected.classList.remove("selected");
              this.node_selected = null;
              this.dispatch('nodeUnselected', true);
            }
            if(this.connection_selected != null) {
              this.connection_selected.classList.remove("selected");
              this.removeReouteConnectionSelected();
              this.connection_selected = null;
            }
            this.drawConnection(e.target);
          }
          break;
          case 'input':
            let inputId = e.target.parentElement.parentElement.id.slice(5);
            let inputClass = e.target.classList[1];
            // Create only single connection from output.
            if(this.getNodeFromId(inputId).class !== 'connectTwo' && this.getNodeFromId(inputId).inputs[inputClass].connections.length > 0){
              // do nothing in case the node is "connector" and there are no connections from this node.
            }else{        
              this.connection = true;
              if(this.node_selected != null) {
                this.node_selected.classList.remove("selected");
                this.node_selected = null;
                this.dispatch('nodeUnselected', true);
              }
              if(this.connection_selected != null) {
                this.connection_selected.classList.remove("selected");
                this.removeReouteConnectionSelected();
                this.connection_selected = null;
              }
              this.drawConnection(e.target);
            }
            break;
        case 'parent-drawflow':
        case 'hideConfigs':
          if(this.node_selected != null) {
            this.node_selected.classList.remove("selected");
            this.node_selected = null;
            this.dispatch('nodeUnselected', true);
          }
          if(this.connection_selected != null) {
            this.connection_selected.classList.remove("selected");
            this.removeReouteConnectionSelected();
            this.connection_selected = null;
          }
          this.editor_selected = true;
          break;
        case 'drawflow':
          if(this.node_selected != null) {
            this.node_selected.classList.remove("selected");
            this.node_selected = null;
            this.dispatch('nodeUnselected', true);
          }
          if(this.connection_selected != null) {
            this.connection_selected.classList.remove("selected");
            this.removeReouteConnectionSelected();
            this.connection_selected = null;
          }
          this.editor_selected = true;
          break;
        case 'main-path':
          if(this.node_selected != null) {
            this.node_selected.classList.remove("selected");
            this.node_selected = null;
            this.dispatch('nodeUnselected', true);
          }
          if(this.connection_selected != null) {
            this.connection_selected.classList.remove("selected");
            this.removeReouteConnectionSelected();
            this.connection_selected = null;
          }

          
          let inputNodeClass = this.getNodeFromId(this.ele_selected.parentElement.classList[1].slice(13)).class;
          let outputNodeClass = this.getNodeFromId(this.ele_selected.parentElement.classList[2].slice(14)).class;
          if (!(this.isPipeDemarkationOrConnectTwo(inputNodeClass, outputNodeClass)) && (!this.isSensorNode(inputNodeClass, true) && !this.isSensorNode(outputNodeClass, true)) || this.ele_selected.parentElement.classList.contains("connector-pipe")) {
            this.connection_selected = this.ele_selected;
            this.connection_selected.classList.add("selected");
            const listclassConnection = this.connection_selected.parentElement.classList;
            if (listclassConnection.length > 1) {
              this.dispatch('connectionSelected', { output_id: listclassConnection[2].slice(14), input_id: listclassConnection[1].slice(13), output_class: listclassConnection[3], input_class: listclassConnection[4] });
              if (this.reroute_fix_curvature) {
                this.connection_selected.parentElement.querySelectorAll(".main-path").forEach((item, i) => {
                  item.classList.add("selected");
                });
              }
            }
          }
        break;
        case 'point':
          this.drag_point = true;
          this.ele_selected.classList.add("selected");
        break;
        case 'drawflow-delete':
          if (this.connection_selected != null) {
            this.node_selected = null;
            this.removeConnection();
          }

          if (this.connection_selected != null) {
            this.connection_selected.classList.remove("selected");
            this.removeReouteConnectionSelected();
            this.connection_selected = null;
          }

          if (this.node_selected != null) {
            this.nodeDeleteClicked = true;
            this.removeNodeId(this.node_selected.id);
          }

          if (this.node_selected != null) {
            this.node_selected.classList.remove("selected");
            this.node_selected = null;
            this.dispatch('nodeUnselected', true);
          }
  
        break;
        default:
      }
      if (e.type === "touchstart") {
        this.pos_x = e.touches[0].clientX;
        this.pos_x_start = e.touches[0].clientX;
        this.pos_y = e.touches[0].clientY;
        this.pos_y_start = e.touches[0].clientY;
      } else {
        this.pos_x = e.clientX;
        this.pos_x_start = e.clientX;
        this.pos_y = e.clientY;
        this.pos_y_start = e.clientY;
      }
      if (['input','output','main-path'].includes(this.ele_selected.classList[0])) {
        e.preventDefault();
      }
      this.dispatch('clickEnd', e);
    }
  
    position(e) {
      if (e.type === "touchmove") {
        var e_pos_x = e.touches[0].clientX;
        var e_pos_y = e.touches[0].clientY;
      } else {
        var e_pos_x = e.clientX;
        var e_pos_y = e.clientY;
      }
      if(this.connection) {
        this.updateConnection(e_pos_x, e_pos_y);
      }
      if(this.editor_selected) {
        x =  this.canvas_x + (-(this.pos_x - e_pos_x))
        y = this.canvas_y + (-(this.pos_y - e_pos_y))
        this.dispatch('translate', { x: x, y: y});
        this.precanvas.style.transform = "translate("+x+"px, "+y+"px) scale("+this.zoom+")";
      }
      if (this.drag) {
        e.preventDefault();
        if (this.ele_selected.id.includes('node')) {
          if (this.node_selected?.classList?.length && this.node_selected?.classList[1] == "GROUP") {
            if (this.shiftPressed) {
              var x = (this.pos_x - e_pos_x) * this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom);
              var y = (this.pos_y - e_pos_y) * this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom);
              this.pos_x = e_pos_x;
              this.pos_y = e_pos_y;

              this.ele_selected.style.top = (this.ele_selected.offsetTop - y) + "px";
              this.ele_selected.style.left = (this.ele_selected.offsetLeft - x) + "px";

              this.drawflow.drawflow[this.module].data[this.ele_selected.id.slice(5)].pos_x = (this.ele_selected.offsetLeft - x);
              this.drawflow.drawflow[this.module].data[this.ele_selected.id.slice(5)].pos_y = (this.ele_selected.offsetTop - y);

              this.drawflow.drawflow[this.module].data[this.ele_selected.id.slice(5)].drop_x =  this.pos_x;
              this.drawflow.drawflow[this.module].data[this.ele_selected.id.slice(5)].drop_y =  this.pos_y;

              this.updateConnectionNodes(this.ele_selected.id);
            }
          } else if (this.node_selected?.classList?.length && this.node_selected?.classList[1] != "GROUP" && this.node_selected.classList[1] != "connectorLbend") {
            var x = (this.pos_x - e_pos_x) * this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom);
            var y = (this.pos_y - e_pos_y) * this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom);
            this.pos_x = e_pos_x;
            this.pos_y = e_pos_y;

            this.ele_selected.style.top = (this.ele_selected.offsetTop - y) + "px";
            this.ele_selected.style.left = (this.ele_selected.offsetLeft - x) + "px";

            this.drawflow.drawflow[this.module].data[this.ele_selected.id.slice(5)].pos_x = (this.ele_selected.offsetLeft - x);
            this.drawflow.drawflow[this.module].data[this.ele_selected.id.slice(5)].pos_y = (this.ele_selected.offsetTop - y);

            this.drawflow.drawflow[this.module].data[this.ele_selected.id.slice(5)].drop_x =  this.pos_x;
            this.drawflow.drawflow[this.module].data[this.ele_selected.id.slice(5)].drop_y =  this.pos_y;

            this.updateConnectionNodes(this.ele_selected.id);
            this.dispatch('nodeMoved', { id: this.ele_selected.id.slice(5) });
          }
        }
      }
  
      if(this.drag_point) {
  
        var x = (this.pos_x - e_pos_x) * this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom);
        var y = (this.pos_y - e_pos_y) * this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom);
        this.pos_x = e_pos_x;
        this.pos_y = e_pos_y;
  
        var pos_x = this.pos_x * ( this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)) - (this.precanvas.getBoundingClientRect().x * ( this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)));
        var pos_y = this.pos_y * ( this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)) - (this.precanvas.getBoundingClientRect().y * ( this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)));
  
        this.ele_selected.setAttributeNS(null, 'cx', pos_x);
        this.ele_selected.setAttributeNS(null, 'cy', pos_y);
  
        const nodeUpdate = this.ele_selected.parentElement.classList[2].slice(9);
        const nodeUpdateIn = this.ele_selected.parentElement.classList[1].slice(13);
        const output_class = this.ele_selected.parentElement.classList[3];
        const input_class = this.ele_selected.parentElement.classList[4];
  
        let numberPointPosition = Array.from(this.ele_selected.parentElement.children).indexOf(this.ele_selected)-1;
  
        if(this.reroute_fix_curvature) {
          const numberMainPath = this.ele_selected.parentElement.querySelectorAll(".main-path").length-1;
          numberPointPosition -= numberMainPath;
          if(numberPointPosition < 0) {
            numberPointPosition = 0;
          }
        }
  
        const nodeId = nodeUpdate.slice(5);
        const searchConnection = this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections.findIndex(function(item,i) {
          return item.node ===  nodeUpdateIn && item.output === input_class;
        });
  
        this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points[numberPointPosition] = { pos_x: pos_x, pos_y: pos_y };
  
        const parentSelected = this.ele_selected.parentElement.classList[2].slice(9);
  
        this.updateConnectionNodes(parentSelected);
      }
  
      if (e.type === "touchmove") {
        this.mouse_x = e_pos_x;
        this.mouse_y = e_pos_y;
      }
      this.dispatch('mouseMove', {x: e_pos_x,y: e_pos_y });
    }

    checkLbendConnection(currentNode) {
      let previousNodeDataObj = this.getNodeFromId(this.previousSelectedNode);
      let currentNodeDataObj = this.getNodeFromId(currentNode);
      if (previousNodeDataObj.class == "connectorLbend" || currentNodeDataObj.class == "connectorLbend" && !(previousNodeDataObj.class == "connectorLbend" && currentNodeDataObj.class == "connectorLbend")) {
        return true;
      } else {
        return false;
      }
    }

// check lead and lag connection
    checkLeadLagConnection(currentNode) {
      let previousNodeDataObj = this.getNodeFromId(this.previousSelectedNode);
      let currentNodeDataObj = this.getNodeFromId(currentNode);
      if ((previousNodeDataObj.equipId != currentNodeDataObj.equipId) &&
        (((previousNodeDataObj.class == "connectTwo" && this.isChiller_Or_Boiler(previousNodeDataObj.childData.class)) && (previousNodeDataObj.class == "connectTwo" && this.isChiller_Or_Boiler(previousNodeDataObj.childData.class))) ||
        ((currentNodeDataObj.class == "connectTwo" && this.isChiller_Or_Boiler(currentNodeDataObj.childData.class)) && (previousNodeDataObj.class == "connectTwo" && this.isChiller_Or_Boiler(currentNodeDataObj.childData.class))))) {
          if (this.equipConfiguration.condensorPipeClass.indexOf(previousNodeDataObj.nodePipeClass) == -1 && this.equipConfiguration.condensorPipeClass.indexOf(currentNodeDataObj.nodePipeClass) == -1) {
            if(this.equipConfiguration.leadnLagColorMapping[previousNodeDataObj.nodePipeClass]?.indexOf(currentNodeDataObj.nodePipeClass) != -1){
              return true;
            }
          }
          return false; 
      }
      return false;
    }

    checkPipeConnectionValidation(currentNode) {
      if (this.pipeValidationForBypassHeaderPipe(currentNode)) {
        return true;
      }
      let previousNodeDataObj = this.getNodeFromId(this.previousSelectedNode);
      let currentNodeDataObj = this.getNodeFromId(currentNode);

      if(this.isDualTempPipe(previousNodeDataObj.childData.class) || this.isDualTempPipe(currentNodeDataObj.childData.class) && !(this.isHeaderPipe(previousNodeDataObj.childData.class) && this.isHeaderPipe(currentNodeDataObj.childData.class))){
        return true;
      }
      
      const pipeClassList =  ["CDWReturnClass", "CDWSupplyClass", "CWReturnClass", "CWSupplyClass", "boilerReturnClass", "boilerSupplyClass", "DTSupplyClass", "DTReturnClass"];
      const previousNodeConnection = document.getElementById('node-' + this.previousSelectedNode).getElementsByClassName('drawflow_content_node')[0].classList;
      const previousNodeClass = pipeClassList.filter(r => previousNodeConnection.contains(r));
      const currentNodeConnection = document.getElementById('node-' + currentNode).getElementsByClassName('drawflow_content_node')[0].classList;
      const currentNodeClass = pipeClassList.filter(r => currentNodeConnection.contains(r));
      const dualTempTagList = ["DTSupplyClass", "DTReturnClass"];
      if (dualTempTagList.includes(previousNodeClass?.[0]) || dualTempTagList.includes(currentNodeClass?.[0])) {
        return true;
      }
      if (!previousNodeClass[0] || !currentNodeClass[0]) {
        if (!previousNodeClass[0]) {
          if (this.drawflow.drawflow.Home.data[this.previousSelectedNode].equipData?.subEquips[0].pipeClass.includes(currentNodeClass[0])){
            this.drawflow.drawflow.Home.data[this.previousSelectedNode].equipData.subEquips[0].selectedPipeClass = currentNodeClass[0];
            document.getElementById('node-' + this.previousSelectedNode).getElementsByClassName('drawflow_content_node')[0].classList.add(currentNodeClass[0]);
            return true;
          } 
          return false;
        } else {
          if (this.drawflow.drawflow.Home.data[currentNode].equipData?.subEquips[0].pipeClass.includes(previousNodeClass[0])) {
            this.drawflow.drawflow.Home.data[currentNode].equipData.subEquips[0].selectedPipeClass = previousNodeClass[0];
            document.getElementById('node-' + currentNode).getElementsByClassName('drawflow_content_node')[0].classList.add(previousNodeClass[0]);
            return true;
          }
          return false;
        }
      } else if (previousNodeClass[0] == currentNodeClass[0]) {
        return true;
      }
      return false;
    }

    checkPipeConnectionFrom(nodeId, ele_last) {
      if ((this.checkPipeConnectionValidation(nodeId) &&
      ((ele_last.classList.contains('headerPipe')) ||
      (document.getElementById('node-' + this.previousSelectedNode).classList.contains('headerPipe') ||
      document.getElementById('node-' + this.previousSelectedNode).classList.contains('bypass-headerPipe') ||
      document.getElementById('node-' + this.previousSelectedNode).classList.contains('subEquip-connectTwo'))))) {
        return true;
      }
        return false;
    }

    checkHeatExchangerPipeValidation(currentNode) {
      let currentNodeData, previousNodeData;
      currentNodeData = this.drawflow.drawflow.Home.data[currentNode];
      previousNodeData = this.drawflow.drawflow.Home.data[this.previousSelectedNode];
      if ((currentNodeData.childData.class.includes('heatExchanger') || previousNodeData.childData.class.includes('heatExchanger') )) {
        return true;
      } else {
        return false;
      }
    }

    pipeValidationForBypassHeaderPipe(currentNode) {
      let currentNodeData, previousNodeData;
      currentNodeData = this.drawflow.drawflow.Home.data[currentNode];
      previousNodeData = this.drawflow.drawflow.Home.data[this.previousSelectedNode];

      if (currentNodeData.class == "pipeDemarkation" && previousNodeData.class == "pipeDemarkation") {
        if (this.isBypassPipe(currentNodeData?.childData?.class) || this.isBypassPipe(previousNodeData?.childData?.class)) {
          return false;
        }
      }
      
      if (this.isBypassPipe(currentNodeData?.childData?.class) || this.isBypassPipe(previousNodeData?.childData?.class)) {
        let byePassPipeData,headerPipeData;
        byePassPipeData = this.isBypassPipe(currentNodeData?.childData?.class) ? currentNodeData : previousNodeData;
        headerPipeData = this.isHeaderPipe(currentNodeData?.childData?.class) ? currentNodeData : previousNodeData;

        if (byePassPipeData.inputs.input_1.connections.length >= 1 && byePassPipeData.outputs.output_1.connections.length >= 1) {
          return false;
        }
        if (headerPipeData.inputs.input_1.connections.length > 1 || headerPipeData.outputs.output_1.connections.length > 1) {
          return false;
        }

        let existingByPassConnectionData;
        Object.keys(this.drawflow.drawflow[this.module].data).forEach(ele => {
          if (this.drawflow.drawflow[this.module].data[ele].class === "GROUP" && this.drawflow.drawflow[this.module].data[ele].equipId == byePassPipeData.equipId) {
            existingByPassConnectionData = this.drawflow.drawflow[this.module].data[ele];
          }
        })
        let isValid =  false;
        if (existingByPassConnectionData.byePassConnection1 == undefined && existingByPassConnectionData.byePassConnection2 == undefined) {
          isValid = true;
        } else if (existingByPassConnectionData.byePassConnection1 == "return" && existingByPassConnectionData.byePassConnection2 == undefined || existingByPassConnectionData.byePassConnection1 == undefined && existingByPassConnectionData.byePassConnection2 == "return") {
          isValid = this.checkSupplyOrReturnPipe(currentNodeData) == 'supply'? true: false;
        } else if (existingByPassConnectionData.byePassConnection1 == "supply" && existingByPassConnectionData.byePassConnection2 == undefined || existingByPassConnectionData.byePassConnection1 == undefined && existingByPassConnectionData.byePassConnection2 == "supply") {
          isValid = this.checkSupplyOrReturnPipe(currentNodeData) == 'return'? true: false;
        } 

        if (isValid) {
          const tags = ['hot', 'chilled', 'condenser'];
          const previousNodeselectedTags = this.drawflow.drawflow.Home.data[this.previousSelectedNode].childData.equipData.subEquips[0].parentEquipsTags;
          const currentNodeSelectedTags = this.drawflow.drawflow.Home.data[currentNode].childData.equipData.subEquips[0].parentEquipsTags;
          if (previousNodeselectedTags?.length && currentNodeSelectedTags?.length) {
            let isHeaderPipe = this.isHeaderPipe(this.drawflow.drawflow.Home.data[currentNode].childData.class);
            if (!isHeaderPipe) {
              isHeaderPipe = this.isBypassPipe(this.drawflow.drawflow.Home.data[currentNode].childData.class);
            }
            let isBypassPipe = this.isHeaderPipe(this.drawflow.drawflow.Home.data[currentNode].childData.class);
            if (!isBypassPipe) {
              isBypassPipe = this.isBypassPipe(this.drawflow.drawflow.Home.data[currentNode].childData.class);
            }
            if (previousNodeselectedTags.filter(_tag => tags.includes(_tag))[0] == currentNodeSelectedTags.filter(_tag => tags.includes(_tag))[0]) {
              if (isBypassPipe && isHeaderPipe) {
                return true;
              }
              return false;
            }
          }
          return false;
        } else {
          return false;
        }
      }
  
    }


  pipeValidationFor3WayValve(currentNode) {
    let currentNodeData, previousNodeData;
    currentNodeData = this.drawflow.drawflow.Home.data[currentNode];
    previousNodeData = this.drawflow.drawflow.Home.data[this.previousSelectedNode];
    if ((currentNodeData.equipType == '3wayValve' || previousNodeData.equipType == '3wayValve') && !(currentNodeData.equipType == '3wayValve' && previousNodeData.equipType == '3wayValve')) {
      return true;
    } else {
      return false;
    }
  }


  /**
   * Checks if there is a bypass connection between the current node and the previously selected node.
   *
   * @param {string} currentNode - The ID of the current node being checked.
   * @returns {boolean} - Returns true if there is a bypass connection, otherwise false.
   */
  checkBypassConnection(currentNode) {
    let currentNodeData, previousNodeData;
    currentNodeData = this.drawflow.drawflow.Home.data[currentNode];
    previousNodeData = this.drawflow.drawflow.Home.data[this.previousSelectedNode];
    if ((currentNodeData.class == 'byPassLine-connectTwo' || previousNodeData.class == 'byPassLine-connectTwo') && !(currentNodeData.class == 'byPassLine-connectTwo' && previousNodeData.class == 'byPassLine-connectTwo')) {
      return true;
    } else {
      return false;
    }
  }

    checkSupplyOrReturnPipe(data) {
      if (data.nodePipeClass == "CDWReturnClass" || data.nodePipeClass == "CWSupplyClass" || data.nodePipeClass == "boilerSupplyClass" || data.nodePipeClass == "DTSupplyClass") {
        return 'supply';
      } else {
        return 'return';
      }
    }

    findPipeClass(pipeClassList) {
      const classArray = ["CDWReturnClass", "CDWSupplyClass", "CWReturnClass", "CWSupplyClass", "boilerReturnClass", "boilerSupplyClass", "DTSupplyClass", "DTReturnClass"];
      return classArray.find(_pipeClass => pipeClassList.contains(_pipeClass));
    }

    getParentNodeData(nodeId) {
      if (nodeId) {
        const nodeData = this.getNodeFromId(nodeId);
        if (nodeData.class == 'pump1Vertical') {

        } else {
          let parentClass = null;
          document.getElementById('node-'+nodeId).classList.forEach(cssClass => {
            if (cssClass.includes('parent')) {
              parentClass = cssClass;
            }
          });
          const parentId = document.getElementsByClassName(`GROUP ${parentClass}`);
          if (parentId.length) {
            const parentNodeData = this.getNodeFromId(parentId[0].id.slice(5));
            return parentNodeData;
          }
          return null;
        }
      }
      return null;
    }

    checkPipeValidations(connectionStartNodeId, connectionEndNodeId) {
      const connectionStartNodeData = this.getNodeFromId(connectionStartNodeId);
      const connectionEndNodeData = this.getNodeFromId(connectionEndNodeId);
      if (connectionStartNodeData.class == "subEquip-connectTwo" && connectionStartNodeData.class == "subEquip-connectTwo") {
        return true;
      }else if(connectionStartNodeData.class == "connectTwo" && connectionStartNodeData.class == "connectTwo"){
        return true;
      } else if (connectionStartNodeData.equipType == '3wayValve' || connectionEndNodeData.equipType == '3wayValve') {
        return true;
      } else if (connectionStartNodeData.class == 'connectorLbend' || connectionEndNodeData.class == 'connectorLbend') {
        return true;
      }  else if (connectionStartNodeData.class == 'pump1Vertical' || connectionEndNodeData.class == 'pump1Vertical') {
        return true;
      }  else if (connectionStartNodeData.class != 'pump1Vertical' && connectionEndNodeData.class != 'pump1Vertical') {
        const parentConnectionStartNodeData = this.getParentNodeData(connectionStartNodeId);
        const parentConnectionEndNodeData = this.getParentNodeData(connectionEndNodeId);
        const minVal = Math.min.apply(Math, parentConnectionEndNodeData.data.groupNodeIds.map(n => Number(n)));
        const maxVal = Math.max.apply(Math, parentConnectionEndNodeData.data.groupNodeIds.map(n => Number(n)));
        let pipeType = 'pipeArrowImg_forward';
        document.getElementById('node-'+connectionEndNodeId).classList.forEach(cssClass => {
          if (cssClass.includes('parent')) {
            if (document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward') && document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward').length) {
              pipeType = 'pipeArrowImg_backward';
            }
          }
        });
        const parentNodeClass = parentConnectionStartNodeData.childData.class;
        if (Number(connectionEndNodeId) == Number(minVal) && pipeType == 'pipeArrowImg_backward') {
          if ((parentNodeClass == 'airCooledChiller' || parentNodeClass == 'waterCooledChiller' || parentNodeClass == 'steamBoiler' || parentNodeClass == 'condenserBoiler' || parentNodeClass == 'pump1Vertical' || parentNodeClass == 'heatExchanger')) {
            return true;
          }
        } else if(Number(connectionEndNodeId) == Number(maxVal) && pipeType == 'pipeArrowImg_backward') {
          if (this.isForwardEquipment(parentNodeClass)) {
            return true;
          }
        }

        if (Number(connectionEndNodeId) == Number(maxVal) && pipeType == 'pipeArrowImg_forward') {
          if ((parentNodeClass == 'airCooledChiller' || parentNodeClass == 'waterCooledChiller' || parentNodeClass == 'steamBoiler' || parentNodeClass == 'condenserBoiler' || parentNodeClass == 'pump1Vertical' || parentNodeClass == 'heatExchanger')) {
            return true;
          }
        } else if(Number(connectionEndNodeId) == Number(minVal) && pipeType == 'pipeArrowImg_forward') {
          if (this.isForwardEquipment(parentNodeClass)) {            
            return true;
          }
        }
      }
      return false;
    }

  // checks input and output Node is subEquip Connector or not 
  checksubEquipConnector(inputNodedata, outputNodeData) {
    if (inputNodedata == "subEquip-connectTwo" || outputNodeData == "subEquip-connectTwo") {
      return (inputNodedata == "subEquip-connectTwo" && outputNodeData == "subEquip-connectTwo") ? false : true;
    } else {
      return false;
    }
  }
  
    dragEnd(e) {
      if (e.type === "touchend") {
        var e_pos_x = this.mouse_x;
        var e_pos_y = this.mouse_y;
        var ele_last = document.elementFromPoint(e_pos_x, e_pos_y);
      } else {
        var e_pos_x = e.clientX;
        var e_pos_y = e.clientY;
        var ele_last = e.target;
      }
  
      if(this.drag) {
        if (this.ele_selected && this.ele_selected.id) {
          const nodeData = this.getNodeFromId(this.ele_selected.id.slice(5));
          if (nodeData && nodeData.childData && nodeData.childData.class == 'btuMeter') {
            const eventClassList = e.target.parentElement.classList;
            let connectionStart;
            let connectionEnd;
            let outputIdx;
            let inputIdx;
            eventClassList.forEach(_class => {
              if (_class.includes('node_in_node-')) {
                connectionEnd = Number(_class.split("node_in_node-")[1]);
              }
              if (_class.includes('node_out_node-')) {
                connectionStart = _class.split("node_out_node-")[1];
              }
              if (_class.includes('output')) {
                outputIdx = _class;
              }
              if (_class.includes('input')) {
                inputIdx = _class;
              }
            });
            this.dispatch('nodeMoved', { id: this.ele_selected.id.slice(5), connectionStart, connectionEnd, inputIdx, outputIdx });
          } else if(this.pos_x_start != e_pos_x || this.pos_y_start != e_pos_y) {
            this.dispatch('nodeMoved', { id: this.ele_selected.id.slice(5) });
          }
        }
      }
  
      if(this.drag_point) {
        this.ele_selected.classList.remove("selected");
          if(this.pos_x_start != e_pos_x || this.pos_y_start != e_pos_y) {
            this.dispatch('rerouteMoved', this.ele_selected.parentElement.classList[2].slice(14));
          }
      }
  
      if(this.editor_selected) {
        this.canvas_x = this.canvas_x + (-(this.pos_x - e_pos_x));
        this.canvas_y = this.canvas_y + (-(this.pos_y - e_pos_y));
        this.editor_selected = false;
      }
      if (ele_last.parentElement.tagName != 'svg') {
        const nodeId = Number(ele_last.parentElement.parentElement.id.toString().split('-')[1]);
        if(this.connection === true) {
             //  Fix connection;
             let inputNodeData, outputNodeData, inputNodeClass, outputNodeClass, is3WayValve = false;
             if(ele_last.classList[0] === 'input' || ele_last.classList[0] === 'output' ){
              var input_id = ele_last.parentElement.parentElement.id;
              var input_class = ele_last.classList[1];
              var output_id = this.ele_selected.parentElement.parentElement.id;
              var output_class = this.ele_selected.classList[1];
              inputNodeData = this.getNodeFromId(output_id.slice(5));
              inputNodeClass = inputNodeData.class;

              var input_id = this.ele_selected.parentElement.parentElement.id;
              var input_class = this.ele_selected.classList[1];
              var output_id = ele_last.parentElement.parentElement.id;
              var output_class = ele_last.classList[1] ;
              outputNodeData = this.getNodeFromId(input_id.slice(5));
              outputNodeClass = outputNodeData.class;
             }
              
          if ((ele_last.classList.contains('headerPipe') && document.getElementById('node-' + this.previousSelectedNode).classList.contains('headerPipe')) || this.checksubEquipConnector(inputNodeClass,outputNodeClass)) {
            // Remove Connection;
            this.dispatch('connectionCancel', true);
            this.connection_ele.remove();
            this.connection_ele = null;
            this.previousSelectedNode = null;
          } else if(ele_last.classList[0] === 'input' || ele_last.classList[0] === 'output' ) {
            // check pipe Conenction validation
            if (this.checkPipeConnectionFrom(nodeId, ele_last) ||
              this.pipeValidationFor3WayValve(nodeId) ||
              this.checkLbendConnection(nodeId) ||
              this.checkLeadLagConnection(nodeId) || 
              this.checkHeatExchangerPipeValidation(nodeId) || 
              this.checkBypassConnection(nodeId)
              ) {
              
              if (ele_last.classList[0] === 'input') {
                var input_id = ele_last.parentElement.parentElement.id;
                var input_class = ele_last.classList[1];
                var output_id = this.ele_selected.parentElement.parentElement.id;
                var output_class = this.ele_selected.classList[1];
              }
              if (ele_last.classList[0] === 'output') {
                var input_id = this.ele_selected.parentElement.parentElement.id;
                var input_class = this.ele_selected.classList[1];
                var output_id = ele_last.parentElement.parentElement.id;
                var output_class = ele_last.classList[1];
              }

              let inputNodeData, outputNodeData, inputNodeClass, outputNodeClass, is3WayValve = false;
              inputNodeData = this.getNodeFromId(output_id.slice(5));
              outputNodeData = this.getNodeFromId(input_id.slice(5));

              inputNodeClass = inputNodeData.class;
              outputNodeClass = outputNodeData.class;
              // Reset the previous node connection.
            this.previousSelectedNode = null;
            let isValidConnection = false;
            let parentComponentClass = '';
   
            if ((inputNodeClass == 'connectTwo' || inputNodeClass == 'pipeDemarkation') && (outputNodeClass == 'connectTwo' || outputNodeClass == 'pipeDemarkation')) {
              input_class = 'input_1';
              output_class = 'output_1';
            }

            if ((inputNodeClass == 'connectTwo' || inputNodeClass == 'pipeDemarkation') && (outputNodeClass == 'connectorLbend')) {
              input_class = 'input_1';
              output_class = 'output_1';
            }

            if ((inputNodeClass == 'connectorLbend') && (outputNodeClass == 'connectTwo' || outputNodeClass == 'pipeDemarkation')) {
              input_class = 'input_1';
              output_class = 'output_1';
            }

            //Make connection as isValidConnection if both are connectorLbend
            if (inputNodeClass == 'connectorLbend' && outputNodeClass == 'connectorLbend') {
              isValidConnection = false;
            }

            if ((inputNodeClass == 'connectTwo') && outputNodeClass == 'connectTwo') {
              input_class = 'input_1';
              output_class = 'output_1';
              isValidConnection = true;
            }

            if ((outputNodeClass == 'connectTwo') && inputNodeClass == 'connectTwo') {
              input_class = 'input_1';
              output_class = 'output_1';
              isValidConnection = true;
            }

            let inputNodeId = input_id;
            let outputNodeId = output_id;
            if (inputNodeClass == 'pump1Vertical') {
              input_class = 'input_1';
              output_class = 'output_1';
            }

            if (outputNodeClass == 'pump1Vertical' && output_class == 'output_1' && input_class == 'output_1') {
              inputNodeId = output_id;
              outputNodeId = input_id;
              input_class = 'input_1';
              output_class = 'output_1';
            }
            
            if( (outputNodeClass == 'connectTwo' || outputNodeClass == 'pipeDemarkation') && (inputNodeClass == 'airCooledChiller' || inputNodeClass == 'waterCooledChiller' || inputNodeClass == 'steamBoiler' || inputNodeClass == 'condenserBoiler' || inputNodeClass == 'pump1Vertical' || inputNodeClass == 'heatExchanger')) {
              input_class = 'input_1';
            } else if ((inputNodeClass == 'connectTwo' || inputNodeClass == 'pipeDemarkation') && (outputNodeClass == 'airCooledChiller' || outputNodeClass == 'waterCooledChiller' || outputNodeClass == 'steamBoiler' || outputNodeClass == 'condenserBoiler' || outputNodeClass == 'pump1Vertical' || outputNodeClass == 'heatExchanger')) {
              let tempInputId = input_id;
              let tempOutputId = output_id;
              let tempInputClass = input_class;
  
              if ((inputNodeClass == 'connectTwo' || inputNodeClass == 'pump1Vertical') && (outputNodeClass == 'connectTwo' || outputNodeClass == 'pump1Vertical')) {
                input_class = 'input_1';
                output_class = 'output_1';
              }
            }

            // check if connection is bypass connection and connected from connector to header pipe then true else false
            let isByPassConnection = false;
            if (outputNodeClass == 'byPassLine-connectTwo' && inputNodeClass == 'pipeDemarkation') {
              input_class = 'input_1';
              output_class = 'output_1';
              isValidConnection = true;
              isByPassConnection = true;
            } else if (inputNodeClass == 'byPassLine-connectTwo' && outputNodeClass == 'pipeDemarkation') {
              input_class = 'input_1';
              output_class = 'output_1';
              isValidConnection = false;
              isByPassConnection = true;
            }

            if (inputNodeData.class == 'subEquip-connectTwo' && outputNodeData.class == 'subEquip-connectTwo') {
              input_class = 'input_1';
              output_class = 'output_1';
              if (inputNodeData.nodePipeClass == outputNodeData.nodePipeClass) {
                parentComponentClass = inputNodeData.nodePipeClass;
                isValidConnection = true;
              }
            }
     
            if (inputNodeData.equipType == "3wayValve" || outputNodeData.equipType == "3wayValve") {
              is3WayValve = true;
              let threeWayValveDataObj;
              if (inputNodeData.equipType == "3wayValve" && outputNodeData.equipType == "3wayValve") {
                isValidConnection = false;
              }
              if (inputNodeData.equipType == "connector" && outputNodeData.equipType == "3wayValve") {
                parentComponentClass = inputNodeData.nodePipeClass;
                threeWayValveDataObj = outputNodeData;
              } else if (inputNodeData.equipType == "3wayValve" && outputNodeData.equipType == "connector") {
                parentComponentClass = outputNodeData.nodePipeClass;
                threeWayValveDataObj = inputNodeData;
              }

              if (inputNodeData.equipType == "3wayValve" && outputNodeData.equipType == "3wayValve") {
                isValidConnection = false;
              } else if (threeWayValveDataObj?.inputs?.input_2?.connections?.length != 0) {
                isValidConnection = false;
              } else if (!isByPassConnection) {
                isValidConnection = this.checkPipeValidations(nodeId, this.ele_selected.parentElement.parentElement.id.toString().split('-')[1]);
              }
            } else if (this.ele_selected.classList.contains('headerPipe') && !isByPassConnection) {
              parentComponentClass = this.getParentNodeData(this.ele_selected.parentElement.parentElement.id.toString().split('-')[1]);
              isValidConnection = this.checkPipeValidations(nodeId,this.ele_selected.parentElement.parentElement.id.toString().split('-')[1]);
            } else {
              if (inputNodeData.childData.class.includes('heatExchanger') || outputNodeData.childData.class.includes('heatExchanger')) {
                isValidConnection = true;
                parentComponentClass = this.getParentNodeData(nodeId);
              } else if (!isByPassConnection) {
                parentComponentClass = this.getParentNodeData(nodeId);
                isValidConnection = this.checkPipeValidations(this.ele_selected.parentElement.parentElement.id.toString().split('-')[1], nodeId);
              }
            }
            if(!isValidConnection){
              // // do nothing if connection exists
              // // Remove Connection;
              this.dispatch('connectionCancel', true);
              this.connection_ele.remove();
              this.connection_ele = null;
              this.previousSelectedNode = null;
            } else {
                if(this.container.querySelectorAll('.connection.node_in_'+inputNodeId+'.node_out_'+outputNodeId+'.'+output_class+'.'+input_class).length === 0) {
                this.ele_selected?.parentElement?.parentElement?.classList.forEach(cssClass => {
                  if (cssClass.includes('parent')) {
                    if (document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_forward') && document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_forward').length) {
                      document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_forward')[0].style.display = 'none';
                    } else if (document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward') && document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward').length) {
                      document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward')[0].style.display = 'none';
                    }
                  }
                });
                document.getElementById('node-'+nodeId)?.classList?.forEach(cssClass => {
                  if (cssClass.includes('parent')) {
                    if (document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_forward') && document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_forward').length) {
                      document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_forward')[0].style.display = 'none';
                    } else if (document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward') && document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward').length) {
                      document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward')[0].style.display = 'none';
                    }
                  }
                });
                // Conection no exist save connection
                this.connection_ele.classList.add("node_in_"+inputNodeId);
                this.connection_ele.classList.add("node_out_"+outputNodeId);
                this.connection_ele.classList.add(output_class);
                this.connection_ele.classList.add(input_class);
                var id_input = inputNodeId.slice(5);
                var id_output = outputNodeId.slice(5);
                let inputData = this.drawflow.drawflow[this.module].data[id_input];
                let outputData = this.drawflow.drawflow[this.module].data[id_output];
                this.drawflow.drawflow[this.module].data[id_output].outputs[output_class].connections.push( {"node": id_input, "output": input_class});
                this.drawflow.drawflow[this.module].data[id_input].inputs[input_class].connections.push( {"node": id_output, "input": output_class});                  
                this.updateConnectionNodes('node-'+id_output);
                this.updateConnectionNodes('node-'+id_input);
                // to remove the point-event-none
                this.connection_ele.childNodes[1].classList.remove('point-event-none');
                if (this.drawflow.drawflow.Home.data[id_output].class == 'pump1Vertical') {
                  parentComponentClass = {childData: {class: ''}};
                  parentComponentClass['childData']['class'] = this.drawflow.drawflow.Home.data[id_output].equipData.subEquips[0].selectedPipeClass;
                } else if (this.drawflow.drawflow.Home.data[id_input].class == 'pump1Vertical') {
                  parentComponentClass = {childData: {class: ''}};
                  parentComponentClass['childData']['class'] = this.drawflow.drawflow.Home.data[id_input].equipData.subEquips[0].selectedPipeClass;
                } else {
                  if (!Object.keys(parentComponentClass).length && !is3WayValve) {
                    parentComponentClass = {childData: {class: ''}};
                  }
                  if ((this.isChiller_Or_Boiler(inputData.childData.class) && inputData.class == "connectTwo") && this.isDualTempPipe(outputData.childData.class) && !is3WayValve) {
                    parentComponentClass['childData']['class'] = inputData.nodePipeClass;
                  } else if ((this.isChiller_Or_Boiler(outputData.childData.class) && outputData.class == "connectTwo") && this.isDualTempPipe(inputData.childData.class) && !is3WayValve) {
                    parentComponentClass['childData']['class'] = outputData.nodePipeClass;
                  } else if (this.drawflow.drawflow.Home.data[id_input]?.nodePipeClass && !is3WayValve) {
                    parentComponentClass['childData']['class'] = this.drawflow.drawflow.Home.data[id_input]?.nodePipeClass;
                  } else if (this.drawflow.drawflow.Home.data[id_output]?.nodePipeClass && !is3WayValve) {
                    parentComponentClass['childData']['class'] = this.drawflow.drawflow.Home.data[id_output]?.nodePipeClass;
                  } else {
                    parentComponentClass = this.assignBypassPipeClass(parentComponentClass, id_output, id_input);
                  }
                }
                this.dispatch('connectionCreated', { output_id: id_output, input_id: id_input, output_class:  output_class, input_class: input_class, parentComponentClass: is3WayValve ? parentComponentClass:parentComponentClass.childData.class , is3WayValve: is3WayValve});
              } else {
                this.dispatch('connectionCancel', true);
                this.connection_ele.remove();
                this.connection_ele = null;
                this.previousSelectedNode = null;
              } 
            }
            } else {
              // Remove Connection;
              this.dispatch('connectionCancel', true);
              this.connection_ele.remove();
              this.connection_ele = null;
              this.previousSelectedNode = null;
            }
          } else {
            // Remove Connection;
            this.dispatch('connectionCancel', true);
            this.connection_ele.remove();
            this.connection_ele = null;
            this.previousSelectedNode = null;
          }
        }
      } else {
        if (this.connection == true ) {
          // Remove Connection;
          this.dispatch('connectionCancel', true);
          this.connection_ele?.remove();
          this.connection_ele = null;
          this.previousSelectedNode = null;
        }
      }
  
      this.drag = false;
      this.drag_point = false;
      this.connection = false;
      this.ele_selected = null;
      this.editor_selected = false;
  
      this.dispatch('equipClick', e);
    }

    getPipeClass(equipName) {
      if (equipName == 'chilledWaterBypassHeaderPipe') {
        return "CWBypassClass";
      } else if (equipName == 'condenserWaterBypassHeaderPipe') {
        return "CDWBypassClass";
      } else if (equipName == 'hotWaterBypassHeaderPipe') {
        return "HotBypassClass";
      }else if (equipName == 'dualTempBypassHeaderPipe') {
        return "DTBypassClass";
      } else {
        return '';
      }
    }

    assignBypassPipeClass(parentComponentClass, id_output, id_input) {
      if (this.isBypassPipe(this.drawflow.drawflow.Home.data[id_output].childData.class)) {
        parentComponentClass = {childData: {class: ''}};
        parentComponentClass['childData']['class'] = this.drawflow.drawflow.Home.data[id_output].childData.class;
      } else if (this.isBypassPipe(this.drawflow.drawflow.Home.data[id_input].childData.class)) {
        parentComponentClass = {childData: {class: ''}};
        parentComponentClass['childData']['class'] = this.drawflow.drawflow.Home.data[id_input].childData.class;
      }
      return parentComponentClass;
    }
    
    contextmenu(e) {
      this.dispatch('contextmenu', e);
      e.preventDefault();
      if( this.editor_mode === 'fixed' || this.editor_mode === 'view') {
        return false;
      }

      if (e.srcElement?.offsetParent?.classList != undefined) {
        this.node_selected = document.getElementById(e.srcElement?.offsetParent.id);
        this.dispatch('nodeSelected', e.srcElement?.offsetParent.id.slice(5));
      }

      if(this.precanvas.getElementsByClassName("drawflow-delete").length) {
        this.precanvas.getElementsByClassName("drawflow-delete")[0].remove()
      };
      let classList = ["waterCooledChiller", 'airCooledChiller','pump1Vertical','coolingTowerSingleFan', 'coolingTowerTwoFan', 'coolingTowerFourFan','steamBoiler', 'condenserBoiler','valveActuator',
       'valveActuator',  'valveActuator_forward',  'valveActuator_backward',  'temperatureSensor',  'temperatureSensor_forward',  'temperatureSensor_backward',  'wellTemperature',  'pressureSensor_forward', 
       'pressureSensor_backward',  'wellTemperature_forward',  'wellTemperature_backward',  'diffPressureSensor',  'diffPressureSensor_forward',  'diffPressureSensor_backward',
       'flowSensor',    'flowSensor_forward',    'flowSensor_backward',    'flowSwitch',    'flowSwitch_forward',    'flowSwitch_backward',   'building2Pipe','threeWayValveDivert_forward', 
       'threeWayValveDivert_backward', 'threeWayValvemixing_forward', 'threeWayValvemixing_backward', "building4Pipe"]
      let parentEquip = false;
      if(this.node_selected instanceof HTMLCollection){
        this.node_selected = this.node_selected[0]
      }
      // for right click on equips and sensors other than pump and btu
      if(this.node_selected && classList.includes(this.getNodeFromId(this.node_selected?.id.slice(5))?.class )){
        let groupID = this.getNodeFromId(this.node_selected?.id.slice(5)).equipId;
        this.node_selected = document.getElementsByClassName(`group-${groupID}`)[0];
        parentEquip = true;
      }
      // for right click on any pipe other than connecting pipe
      if(!this.node_selected && e.target?.ownerSVGElement?.classList.value.includes("node_in_node-") &&
      (!e.target?.ownerSVGElement?.classList.contains("connector-pipe"))){
        const classList = e.target?.ownerSVGElement?.classList;
        const className = Array.from(classList).find(className => className.includes('node-'));
        const groupID =  this.getNodeFromId(className.split('-')[1]).equipId;
        this.node_selected = document.getElementsByClassName(`group-${groupID}`)[0];
        parentEquip = true;
      }
      if(e.target?.ownerSVGElement?.classList.contains("connector-pipe ") ||
       (this.node_selected && (parentEquip || this.getNodeFromId(this.node_selected?.id.slice(5))?.class == 'GROUP' ||
         this.getNodeFromId(this.node_selected?.id.slice(5))?.class.includes('pump1Vertical') || 
         this.getNodeFromId(this.node_selected?.id.slice(5))?.class.includes('pump1Vertical_backward') || 
         this.getNodeFromId(this.node_selected?.id.slice(5))?.class.includes('pump1Vertical_forward') || 
         this.getNodeFromId(this.node_selected?.id.slice(5))?.class.includes('byPassLine-connectTwo') || 
         this.getNodeFromId(this.node_selected?.id.slice(5))?.class.includes('subEquip-connectTwo') ||
         this.getNodeFromId(this.node_selected?.id.slice(5))?.class.includes('btuMeter'))) || 
         this.connection_selected) {
        var deletebox = document.createElement('div');
        deletebox.classList.add("drawflow-delete");
        deletebox.innerHTML = "x";
        if(this.node_selected != null) {
          setTimeout(()=>{
            if(this.node_selected != null) {
            this.node_selected['style']['z-index'] = '1201';
            }
          },10)
          this.node_selected.appendChild(deletebox);
        }

        if(this.connection_selected && (this.connection_selected.parentElement.classList.length > 1)) {
          deletebox.style.top = e.clientY * ( this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)) - (this.precanvas.getBoundingClientRect().y *  ( this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)) ) + "px";
          deletebox.style.left = e.clientX * ( this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)) - (this.precanvas.getBoundingClientRect().x *  ( this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)) ) + "px";
          this.precanvas.appendChild(deletebox);
        }
      }
    }
    
    contextmenuDel() {
      if(this.precanvas.getElementsByClassName("drawflow-delete").length) {
        if(this.node_selected && (this.node_selected.classList.contains('headerPipe') || this.node_selected.classList.contains('GROUP'))) {
          this.node_selected['style']['z-index'] = '1000';
        }
        this.precanvas.getElementsByClassName("drawflow-delete")[0].remove();
      };
    }
  
    key(e) {
      this.dispatch('keydown', e);
      if(this.editor_mode === 'fixed' || this.editor_mode === 'view') {
        return false;
      }
      if ((e.key === 'Backspace' && e.metaKey)) {
        if(this.node_selected != null) {
          if(this.first_click.tagName !== 'INPUT' && this.first_click.tagName !== 'TEXTAREA' && this.first_click.hasAttribute('contenteditable') !== true) {
            // this.removeNodeId(this.node_selected.id);
          }
        }
        if(this.connection_selected != null) {
          this.removeConnection();
        }
      }
    }
  
    zoom_enter(event) {
      let checkScrollOnCanvas = true;
      checkScrollOnCanvas =  event?.srcElement?.parentElement?.offsetParent?.id != '' ? event?.srcElement?.parentElement?.offsetParent?.id?.includes('pointStatusContainerId') ? false : true : true;
      if(checkScrollOnCanvas) {
        if(event.deltaY > 0) {
          // Zoom Out
          this.zoom_out();
        } else {
          // Zoom In
          this.zoom_in();
        }
      }
    }

    zoom_refresh(){
      this.dispatch("zoom", this.zoom.toFixed(2))
      this.canvas_x = (this.canvas_x / this.zoom_last_value) * this.zoom;
      this.canvas_y = (this.canvas_y / this.zoom_last_value) * this.zoom;
      this.zoom_last_value = this.zoom;
      this.precanvas.style.transform = "translate("+this.canvas_x+"px, "+this.canvas_y+"px) scale("+this.zoom+")";
    }
    zoom_in() {
      if(this.zoom < this.zoom_max) {
          this.zoom+=this.zoom_value;
          this.zoom_refresh();
      }
    }
    zoom_out() {
      if(this.zoom > this.zoom_min) {
        this.zoom-=this.zoom_value;
          this.zoom_refresh();
      }
    }
    zoom_reset(){
      if(this.zoom != 1) {
        this.zoom = 1;
        this.zoom_refresh();
      }
    }
  
    createCurvature(start_pos_x, start_pos_y, end_pos_x, end_pos_y, curvature_value, type) {
      var line_x = start_pos_x;
      var line_y = start_pos_y;
      var x = end_pos_x;
      var y = end_pos_y;
      var curvature = curvature_value;
      //type openclose open close other
      // switch (type) {
      //   case 'open':
      //     if(start_pos_x >= end_pos_x) {
      //       var hx1 = line_x + Math.abs(x - line_x) * curvature;
      //       var hx2 = x - Math.abs(x - line_x) * (curvature*-1);
      //     } else {
      //       var hx1 = line_x + Math.abs(x - line_x) * curvature;
      //       var hx2 = x - Math.abs(x - line_x) * curvature;
      //     }
      //     return ' M '+ line_x +' '+ line_y +' C '+ hx1 +' '+ line_y +' '+ hx2 +' ' + y +' ' + x +'  ' + y;
      //   case 'close':
      //     if(start_pos_x >= end_pos_x) {
      //       var hx1 = line_x + Math.abs(x - line_x) * (curvature*-1);
      //       var hx2 = x - Math.abs(x - line_x) * curvature;
      //     } else {
      //       var hx1 = line_x + Math.abs(x - line_x) * curvature;
      //       var hx2 = x - Math.abs(x - line_x) * curvature;
      //     }
      //     return ' M '+ line_x +' '+ line_y +' C '+ hx1 +' '+ line_y +' '+ hx2 +' ' + y +' ' + x +'  ' + y;
      //   case 'other':
      //     if(start_pos_x >= end_pos_x) {
      //       var hx1 = line_x + Math.abs(x - line_x) * (curvature*-1);
      //       var hx2 = x - Math.abs(x - line_x) * (curvature*-1);
      //     } else {
      //       var hx1 = line_x + Math.abs(x - line_x) * curvature;
      //       var hx2 = x - Math.abs(x - line_x) * curvature;
      //     }
      //     return ' M '+ line_x +' '+ line_y +' C '+ hx1 +' '+ line_y +' '+ hx2 +' ' + y +' ' + x +'  ' + y;
      //   default:
  
      //     var hx1 = line_x + Math.abs(x - line_x) * curvature;
      //     var hx2 = x - Math.abs(x - line_x) * curvature;
  
      //     return ' M '+ line_x +' '+ line_y +' C '+ hx1 +' '+ line_y +' '+ hx2 +' ' + y +' ' + x +'  ' + y;
      // }
  
    }
  
    drawConnection(ele) {
        var id_output = ele.parentElement.parentElement.id.slice(5);
        var output_class = ele.classList[1];
        var connection = document.createElementNS('http://www.w3.org/2000/svg',"svg");
        this.connection_ele = connection;
        var path = document.createElementNS('http://www.w3.org/2000/svg',"path");
        var topPath = document.createElementNS('http://www.w3.org/2000/svg',"path");
        path.classList.add("main-path");
        path.classList.add("point-event-none");
        topPath.classList.add("pathBorder");
        path.setAttributeNS(null, 'd', '');
        topPath.setAttributeNS(null, 'd', '');
        // path.innerHTML = 'a';
        connection.classList.add("connection");
        connection.appendChild(topPath);
        connection.appendChild(path);
        this.precanvas.appendChild(connection);
        if (this.previousSelectedNode == null) {
          this.previousSelectedNode = id_output;
        }
        this.dispatch('connectionStart', { output_id: id_output, output_class:  output_class });
    }
  
    updateConnection(eX, eY) {
      const precanvas = this.precanvas;
      const zoom = this.zoom;
      let precanvasWitdhZoom = precanvas.clientWidth / (precanvas.clientWidth * zoom);
      precanvasWitdhZoom = precanvasWitdhZoom || 0;
      let precanvasHeightZoom = precanvas.clientHeight / (precanvas.clientHeight * zoom);
      precanvasHeightZoom = precanvasHeightZoom || 0;
      var path = this.connection_ele.children[1];
      var topPath = this.connection_ele.children[0];
      var line_x = this.ele_selected.offsetWidth/2 + (this.ele_selected.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
      var line_y = this.ele_selected.offsetHeight/2 + (this.ele_selected.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
  
      var x = eX * ( this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)) - (this.precanvas.getBoundingClientRect().x *  ( this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)) );
      var y = eY * ( this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)) - (this.precanvas.getBoundingClientRect().y *  ( this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)) );
  
      var curvature = this.curvature;
      var lineCurve = this.createCurvature(line_x, line_y, x, y, curvature, 'openclose');
      path.setAttributeNS(null, 'd', lineCurve);
      topPath.setAttributeNS(null, 'd', lineCurve);
    }
  
  addConnection(id_output, id_input, output_class, input_class, extraClass, addSensorBtwPipes = false, isGroupPipe = false, groupId, btuMeter = false, pump = false) {
    var nodeOneModule = this.getModuleFromNodeId(id_output);
    var nodeTwoModule = this.getModuleFromNodeId(id_input);
    // Create only single connection from output.
    if ((nodeOneModule === nodeTwoModule) || btuMeter) {
      var dataNode = this.getNodeFromId(id_output);
      if (dataNode) {
        var exist = false;
        for (var checkOutput in dataNode.outputs[output_class]?.connections) {
          var connectionSearch = dataNode.outputs[output_class].connections[checkOutput]
          if (connectionSearch.node == id_input && connectionSearch.output == input_class) {
            exist = true;
          }
        }
        // Check connection exist
        if (exist === false) {
          //Create Connection
          this.drawflow.drawflow[nodeOneModule].data[id_output].outputs[output_class].connections.push({ "node": id_input.toString(), "output": input_class });
          if (this.drawflow.drawflow[nodeOneModule].data[id_input].inputs[input_class]) {
            this.drawflow.drawflow[nodeOneModule].data[id_input].inputs[input_class].connections.push({ "node": id_output.toString(), "input": output_class });
          } else if (!this.drawflow.drawflow[nodeOneModule].data[id_input].inputs[input_class]) {
            this.drawflow.drawflow[nodeOneModule].data[id_output].outputs[input_class].connections.push({ "node": id_output.toString(), "output": output_class });
          }
          let is3WayValve = false;
          if (this.module === nodeOneModule) {
            //Draw connection
            var connection = document.createElementNS('http://www.w3.org/2000/svg', "svg");
            var path = document.createElementNS('http://www.w3.org/2000/svg', "path");
            var topPath = document.createElementNS('http://www.w3.org/2000/svg', "path");
            path.classList.add("main-path");
            if (btuMeter) {
              path.classList.add('btuMeter-pipe-connection');
              connection.style.setProperty('z-index', '1100', 'important');
            } else if (pump) {
              path.classList.add('pump-pipe-connection');
            }
            topPath.classList.add("pathBorder");
            path.setAttributeNS(null, 'd', '');
            topPath.setAttributeNS(null, 'd', '');
            // path.innerHTML = 'a';
            connection.classList.add("connection");
            connection.classList.add("node_in_node-" + id_input);
            connection.classList.add("node_out_node-" + id_output);
            connection.classList.add(output_class);
            connection.classList.add(input_class);

            let inputDataObj = this.getNodeFromId(id_input);
            let outputDataObj = this.getNodeFromId(id_output);

            if ((inputDataObj?.equipType == '3wayValve' || outputDataObj?.equipType == '3wayValve') && input_class == 'input_2' && !(inputDataObj?.equipType == '3wayValve' && outputDataObj?.equipType == '3wayValve') && !(inputDataObj.childData.equipId == outputDataObj.childData.equipId)) {
              connection.classList.add('connector-pipe');
            }

            if (extraClass != undefined) {
              const extraClassArray = extraClass?.split(' ');
              for (let i = 0; i < extraClassArray.length; i++) {
                if (connection.classList.value.indexOf(extraClassArray[i]) < 0) {
                  connection.classList.add(extraClassArray[i]);
                }
              }
            }
            connection.appendChild(topPath);
            connection.appendChild(path);
            if (isGroupPipe && groupId != undefined &&  groupId != '') {
              connection.classList.add(`groupPipe-${groupId}`);
            }
            this.precanvas.appendChild(connection);
            this.updateConnectionNodes('node-' + id_output);
            this.updateConnectionNodes('node-' + id_input);
          }

          this.dispatch('connectionCreated', { output_id: id_output, input_id: id_input, output_class: output_class, input_class: input_class, parentComponentClass: extraClass, is3WayValve: is3WayValve });
        }
      }
    }
  }
  
    updateConnectionNodes(id) {
      // Aquí nos quedamos;
      const idSearch = 'node_in_'+id;
      const idSearchOut = 'node_out_'+id;
      var line_path = this.line_path/2;
      const container = this.container;
      const precanvas = this.precanvas;
      const curvature = this.curvature;
      const createCurvature = this.createCurvature;
      const reroute_curvature = this.reroute_curvature;
      const reroute_curvature_start_end = this.reroute_curvature_start_end;
      const reroute_fix_curvature = this.reroute_fix_curvature;
      const rerouteWidth = this.reroute_width;
      const zoom = this.zoom;
      let precanvasWitdhZoom = precanvas.clientWidth / (precanvas.clientWidth * zoom);
      precanvasWitdhZoom = precanvasWitdhZoom || 0;
      let precanvasHeightZoom = precanvas.clientHeight / (precanvas.clientHeight * zoom);
      precanvasHeightZoom = precanvasHeightZoom || 0;
  
      const elemsOut = container.querySelectorAll(`.${idSearchOut}`);
  
      Object.keys(elemsOut).map(function(item, index) {
        if(elemsOut[item].querySelector('.point') === null) {
  
          var elemtsearchId_out = container.querySelector(`#${id}`);
  
          var id_search = elemsOut[item].classList[1].replace('node_in_', '');
          var elemtsearchId = container.querySelector(`#${id_search}`);
  
          var elemtsearch = elemtsearchId.querySelectorAll('.'+elemsOut[item].classList[4])[0]
          var eX = elemtsearch.offsetWidth/2 + (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
          var eY = elemtsearch.offsetHeight/2 + (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
  
          var elemtsearchOut = elemtsearchId_out.querySelectorAll('.'+elemsOut[item].classList[3])[0]
  
          var line_x =  elemtsearchOut.offsetWidth/2 + (elemtsearchOut.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
          var line_y =  elemtsearchOut.offsetHeight/2 + (elemtsearchOut.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
  
          var x = eX;
          var y = eY;
  
          const lineCurve = createCurvature(line_x, line_y, x, y, curvature, 'openclose');
          elemsOut[item].children[0].setAttributeNS(null, 'd', lineCurve );
          elemsOut[item].children[1].setAttributeNS(null, 'd', lineCurve );
        } else {
          const points = elemsOut[item].querySelectorAll('.point');
          let linecurve = '';
          const reoute_fix = [];
          points.forEach((item, i) => {
            if(i === 0 && ((points.length -1) === 0)) {
  
              var elemtsearchId_out = container.querySelector(`#${id}`);
              var elemtsearch = item;
  
              var eX =  (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var eY =  (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom + rerouteWidth;
  
              var elemtsearchOut = elemtsearchId_out.querySelectorAll('.'+item.parentElement.classList[3])[0]
              var line_x =  elemtsearchOut.offsetWidth/2 + (elemtsearchOut.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
              var line_y =  elemtsearchOut.offsetHeight/2 + (elemtsearchOut.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'open');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
  
              var elemtsearchId_out = item;
              var id_search = item.parentElement.classList[1].replace('node_in_', '');
              var elemtsearchId = container.querySelector(`#${id_search}`);
              var elemtsearch = elemtsearchId.querySelectorAll('.'+item.parentElement.classList[4])[0]
  
              var elemtsearchIn = elemtsearchId.querySelectorAll('.'+item.parentElement.classList[4])[0]
              var eX =  elemtsearchIn.offsetWidth/2 + (elemtsearchIn.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
              var eY =  elemtsearchIn.offsetHeight/2 + (elemtsearchIn.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
  
  
              var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom + rerouteWidth;
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'close');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
  
            } else if(i === 0) {
  
              var elemtsearchId_out = container.querySelector(`#${id}`);
              var elemtsearch = item;
  
              var eX = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var eY = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom + rerouteWidth;
  
              var elemtsearchOut = elemtsearchId_out.querySelectorAll('.'+item.parentElement.classList[3])[0]
              var line_x =  elemtsearchOut.offsetWidth/2 + (elemtsearchOut.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
              var line_y =  elemtsearchOut.offsetHeight/2 + (elemtsearchOut.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
  
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'open');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
  
              // SECOND
              var elemtsearchId_out = item;
              var elemtsearch = points[i+1];
  
              var eX = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var eY = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom + rerouteWidth;
              var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom + rerouteWidth;
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature, 'other');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
  
            } else if (i === (points.length -1)) {
  
              var elemtsearchId_out = item;
  
              var id_search = item.parentElement.classList[1].replace('node_in_', '');
              var elemtsearchId = container.querySelector(`#${id_search}`);
              var elemtsearch = elemtsearchId.querySelectorAll('.'+item.parentElement.classList[4])[0]
  
              var elemtsearchIn = elemtsearchId.querySelectorAll('.'+item.parentElement.classList[4])[0]
              var eX =  elemtsearchIn.offsetWidth/2 + (elemtsearchIn.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
              var eY =  elemtsearchIn.offsetHeight/2 + (elemtsearchIn.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
              var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * (precanvas.clientWidth / (precanvas.clientWidth * zoom)) + rerouteWidth;
              var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * (precanvas.clientHeight / (precanvas.clientHeight * zoom)) + rerouteWidth;
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'close');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
  
            } else {
              var elemtsearchId_out = item;
              var elemtsearch = points[i+1];
  
              var eX = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * (precanvas.clientWidth / (precanvas.clientWidth * zoom)) + rerouteWidth;
              var eY = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * (precanvas.clientHeight / (precanvas.clientHeight * zoom)) +rerouteWidth;
              var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * (precanvas.clientWidth / (precanvas.clientWidth * zoom)) + rerouteWidth;
              var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * (precanvas.clientHeight / (precanvas.clientHeight * zoom)) + rerouteWidth;
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature, 'other');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
            }
  
          });
          if(reroute_fix_curvature) {
            reoute_fix.forEach((itempath, i) => {
              elemsOut[item].children[i].setAttributeNS(null, 'd', itempath);
            });
  
          } else {
            elemsOut[item].children[0].setAttributeNS(null, 'd', linecurve);
            elemsOut[item].children[1].setAttributeNS(null, 'd', lineCurve );
          }
  
        }
      })
  
      const elems = container.querySelectorAll(`.${idSearch}`);
      Object.keys(elems).map(function(item, index) {
  
        if(elems[item].querySelector('.point') === null) {
          var elemtsearchId_in = container.querySelector(`#${id}`);
  
          var id_search = elems[item].classList[2].replace('node_out_', '');
          var elemtsearchId = container.querySelector(`#${id_search}`);
          var elemtsearch = elemtsearchId.querySelectorAll('.'+elems[item].classList[3])[0]
  
          var line_x = elemtsearch.offsetWidth/2 + (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
          var line_y = elemtsearch.offsetHeight/2 + (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
  
          var elemtsearchId_in = elemtsearchId_in.querySelectorAll('.'+elems[item].classList[4])[0]
          var x = elemtsearchId_in.offsetWidth/2 + (elemtsearchId_in.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
          var y = elemtsearchId_in.offsetHeight/2 + (elemtsearchId_in.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
  
          const lineCurve = createCurvature(line_x, line_y, x, y, curvature, 'openclose');
          elems[item].children[0].setAttributeNS(null, 'd', lineCurve );
          elems[item].children[1].setAttributeNS(null, 'd', lineCurve );
        } else {
          const points = elems[item].querySelectorAll('.point');
          let linecurve = '';
          const reoute_fix = [];
          points.forEach((item, i) => {
            if(i === 0 && ((points.length -1) === 0)) {
  
              var elemtsearchId_out = container.querySelector(`#${id}`);
              var elemtsearch = item;
  
              var line_x = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var line_y = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom +rerouteWidth;
  
              var elemtsearchIn = elemtsearchId_out.querySelectorAll('.'+item.parentElement.classList[4])[0]
              var eX =  elemtsearchIn.offsetWidth/2 + (elemtsearchIn.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
              var eY =  elemtsearchIn.offsetHeight/2 + (elemtsearchIn.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
  
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'close');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
  
              var elemtsearchId_out = item;
              var id_search = item.parentElement.classList[2].replace('node_out_', '');
              var elemtsearchId = container.querySelector(`#${id_search}`);
              var elemtsearch = elemtsearchId.querySelectorAll('.'+item.parentElement.classList[3])[0]
  
              var elemtsearchOut = elemtsearchId.querySelectorAll('.'+item.parentElement.classList[3])[0]
              var line_x =  elemtsearchOut.offsetWidth/2 + (elemtsearchOut.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
              var line_y =  elemtsearchOut.offsetHeight/2 + (elemtsearchOut.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
  
              var eX = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var eY = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom + rerouteWidth;
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'open');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
  
  
            } else if(i === 0) {
              // FIRST
              var elemtsearchId_out = item;
              var id_search = item.parentElement.classList[2].replace('node_out_', '');
              var elemtsearchId = container.querySelector(`#${id_search}`);
              var elemtsearch = elemtsearchId.querySelectorAll('.'+item.parentElement.classList[3])[0]
              var elemtsearchOut = elemtsearchId.querySelectorAll('.'+item.parentElement.classList[3])[0]
              var line_x =  elemtsearchOut.offsetWidth/2 + (elemtsearchOut.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
              var line_y =  elemtsearchOut.offsetHeight/2 + (elemtsearchOut.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
  
              var eX = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var eY = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom + rerouteWidth;
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'open');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
  
              // SECOND
              var elemtsearchId_out = item;
              var elemtsearch = points[i+1];
  
              var eX = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var eY = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom +rerouteWidth;
              var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom + rerouteWidth;
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature, 'other');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
  
            } else if (i === (points.length -1)) {
  
              var elemtsearchId_out = item;
  
              var id_search = item.parentElement.classList[1].replace('node_in_', '');
              var elemtsearchId = container.querySelector(`#${id_search}`);
              var elemtsearch = elemtsearchId.querySelectorAll('.'+item.parentElement.classList[4])[0]
  
              var elemtsearchIn = elemtsearchId.querySelectorAll('.'+item.parentElement.classList[4])[0]
              var eX =  elemtsearchIn.offsetWidth/2 + (elemtsearchIn.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom;
              var eY =  elemtsearchIn.offsetHeight/2 + (elemtsearchIn.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom;
  
              var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom + rerouteWidth;
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'close');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
  
            } else {
  
              var elemtsearchId_out = item;
              var elemtsearch = points[i+1];
  
              var eX = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var eY = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom +rerouteWidth;
              var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x ) * precanvasWitdhZoom + rerouteWidth;
              var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y ) * precanvasHeightZoom + rerouteWidth;
              var x = eX;
              var y = eY;
  
              var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature, 'other');
              linecurve += lineCurveSearch;
              reoute_fix.push(lineCurveSearch);
            }
  
          });
          if(reroute_fix_curvature) {
            reoute_fix.forEach((itempath, i) => {
              elems[item].children[i].setAttributeNS(null, 'd', itempath);
            });
  
          } else {
            elems[item].children[0].setAttributeNS(null, 'd', linecurve);
            elems[item].children[1].setAttributeNS(null, 'd', lineCurve );
          }
  
        }
      });
      this.addWarningIconOnPipes(id);
    }
  
    dblclick(e) {
      if(this.reroute) {
          // this.createReroutePoint(this.connection_selected);
          this.createSplitNode(e);
      }
  
      if(e.target.classList[0] === 'point') {
          this.removeReroutePoint(e.target);
      }
    }
  
    createReroutePoint(ele) {
        this.connection_selected.classList.remove("selected");
        const nodeUpdate = this.connection_selected.parentElement.classList[2].slice(9);
        const nodeUpdateIn = this.connection_selected.parentElement.classList[1].slice(13);
        const output_class = this.connection_selected.parentElement.classList[3];
        const input_class = this.connection_selected.parentElement.classList[4];
        this.connection_selected = null;
        const point = document.createElementNS('http://www.w3.org/2000/svg',"circle");
        point.classList.add("point");
        var pos_x = this.pos_x * ( this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)) - (this.precanvas.getBoundingClientRect().x * ( this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)));
        var pos_y = this.pos_y * ( this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)) - (this.precanvas.getBoundingClientRect().y * ( this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)));
  
        point.setAttributeNS(null, 'cx', pos_x);
        point.setAttributeNS(null, 'cy', pos_y);
        point.setAttributeNS(null, 'r', this.reroute_width);
  
        let position_add_array_point = 0;
        if(this.reroute_fix_curvature) {
  
          const numberPoints = ele.parentElement.querySelectorAll(".main-path").length;
          var path = document.createElementNS('http://www.w3.org/2000/svg',"path");
          var topPath = document.createElementNS('http://www.w3.org/2000/svg',"path");
          path.classList.add("main-path");
          topPath.classList.add("pathBorder");
          path.setAttributeNS(null, 'd', '');
          topPath.setAttributeNS(null, 'd', '');
          ele.parentElement.insertBefore(path, ele.parentElement.children[numberPoints]);
          if(numberPoints === 1) {
            ele.parentElement.appendChild(point);
          }  else {
            const search_point = Array.from(ele.parentElement.children).indexOf(ele)
            position_add_array_point = search_point;
            ele.parentElement.insertBefore(point, ele.parentElement.children[search_point+numberPoints+1]);
          }
  
        } else {
          ele.parentElement.appendChild(point);
        }
  
        const nodeId = nodeUpdate.slice(5);
        const searchConnection = this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections.findIndex(function(item,i) {
          return item.node ===  nodeUpdateIn && item.output === input_class;
        });
  
        if(this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points === undefined)  {
          this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points = [];
        }
  
        if(this.reroute_fix_curvature) {
  
          if(position_add_array_point > 0 || this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points !== []) {
            this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points.splice(position_add_array_point, 0, { pos_x: pos_x, pos_y: pos_y });
          } else {
            this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points.push({ pos_x: pos_x, pos_y: pos_y });
          }
  
          ele.parentElement.querySelectorAll(".main-path").forEach((item, i) => {
            item.classList.remove("selected");
          });
  
        } else {
          this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points.push({ pos_x: pos_x, pos_y: pos_y });
        }
  
        this.dispatch('addReroute', nodeId);
        this.updateConnectionNodes(nodeUpdate);
    }
    createSplitNode(event){
        this.dispatch('createSplitNode', event);
    }
    removeReroutePoint(ele) {
      const nodeUpdate = ele.parentElement.classList[2].slice(9)
      const nodeUpdateIn = ele.parentElement.classList[1].slice(13);
      const output_class = ele.parentElement.classList[3];
      const input_class = ele.parentElement.classList[4];
  
      let numberPointPosition = Array.from(ele.parentElement.children).indexOf(ele);
      const nodeId = nodeUpdate.slice(5);
      const searchConnection = this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections.findIndex(function(item,i) {
        return item.node ===  nodeUpdateIn && item.output === input_class;
      });
  
      if(this.reroute_fix_curvature) {
         const numberMainPath = ele.parentElement.querySelectorAll(".main-path").length
         ele.parentElement.children[numberMainPath-1].remove();
         numberPointPosition -= numberMainPath;
         if(numberPointPosition < 0) {
           numberPointPosition = 0;
         }
      } else {
        numberPointPosition--;
      }
      this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points.splice(numberPointPosition,1);
  
      ele.remove();
      this.dispatch('removeReroute', nodeId);
      this.updateConnectionNodes(nodeUpdate);
    }
  
    registerNode(name, html, props = null, options = null) {
      this.noderegister[name] = {html: html, props: props, options: options};
    }
  
    getNodeFromId(id) {
      var moduleName = this.getModuleFromNodeId(id);
      if (moduleName) {
        return JSON.parse(JSON.stringify(this.drawflow.drawflow[moduleName].data[id]));
      }
      return undefined;
    }
    getNodeFromIdAndModule(id, module){
      return JSON.parse(JSON.stringify(this.drawflow.drawflow[module].data[id]));
    }
    getNodesFromName(name) {
      var nodes = [];
      const editor = this.drawflow.drawflow
      Object.keys(editor).map(function(moduleName, index) {
        for (var node in editor[moduleName].data) {
          if(editor[moduleName].data[node].name == name) {
            nodes.push(editor[moduleName].data[node].id);
          }
        }
      });
      return nodes;
    }
  
    setImgPath(path) {
      return '/assets/images/cpmEquips/' + path;
    }

    isHeaderPipe(equipName) {
      return this.equipConfiguration?.headerPipeList.indexOf(equipName) != -1;
    }

    isBypassPipe(equipName) {
      return this.equipConfiguration.bypassPipeList.indexOf(equipName) != -1;
    }

    isDualTempPipe(equipName) {
      return this.equipConfiguration.dualTempPipeList.indexOf(equipName) != -1;
    }

    isThreeWayValve(equipName) {
      return this.equipConfiguration.threeWayValveList.indexOf(equipName) != -1;
    }
    
    // Check if the class name is a dual temp class
    isDualTempClass(className) {
      return this.equipConfiguration.dualTempPipeClass.indexOf(className) != -1;
    }

    /**
     * Adds a new node to the drawflow diagram.
     *
     * @param {string} name - The name of the node.
     * @param {number} num_in - The number of input ports for the node.
     * @param {number} num_out - The number of output ports for the node.
     * @param {number} ele_pos_x - The x-coordinate position of the node.
     * @param {number} ele_pos_y - The y-coordinate position of the node.
     * @param {string} classoverride - The CSS class to override the default styling of the node.
     * @param {object} data - The data associated with the node.
     * @param {string|HTMLElement} html - The HTML content of the node or the name of a registered node template.
     * @param {boolean} [typenode=false] - Indicates whether the node is a registered template or not.
     * @param {object} [nodeData] - Additional data for the node.
     * @param {boolean} [triggerDispatchEvent=false] - Indicates whether to trigger the 'nodeCreated' event.
     * @param {string} [nodeId] - The ID of the node.
     * @param {object} [parentEquip] - The parent equipment of the node.
     * @param {string} [nodePipeClass] - The CSS class for the node pipe.
     * @param {string} [groupNodeWidth=''] - The width of the group node.
     * @param {string} [groupNodeHeight=''] - The height of the group node.
     * @param {string} [parentNodeId] - The ID of the parent node.
     * @param {string} [groupPipeId] - The ID of the group pipe.
     * @param {string} [pointId] - The ID of the point.
     * @param {string} [drop_x=''] - The x-coordinate position of the drop point.
     * @param {string} [drop_y=''] - The y-coordinate position of the drop point.
     * @param {string} [pipeType] - The type of the pipe.
     * @returns {string} The ID of the newly created node.
     */
    addNode (name, num_in, num_out, ele_pos_x, ele_pos_y, classoverride, data, html, typenode = false, nodeData, triggerDispatchEvent, nodeId,parentEquip, nodePipeClass,groupNodeWidth = '',groupNodeHeight = '', parentNodeId, groupPipeId,pointId,drop_x = '',drop_y ='', pipeType) {
      if (this.useuuid) {
        var newNodeId = this.getUniqueid();
      } else {
        var newNodeId = this.nodeId;
      }
      if (nodeId && !triggerDispatchEvent && !this.isHeaderPipe(parentEquip?.class) || (name == "GROUP")) {
        newNodeId = nodeId;
      }
      const parent = document.createElement('div');
      parent.classList.add("parent-node");

      const node = document.createElement('div');
      node.innerHTML = "";
      node.setAttribute("id", "node-"+newNodeId);
      node.classList.add("drawflow-node");
  
      if(classoverride != '') {
        node.classList.add(...classoverride.split(' '));
      }
      const inputs = document.createElement('div');
      inputs.classList.add("inputs");
  
      const outputs = document.createElement('div');
      outputs.classList.add("outputs");
  
      const json_inputs = {};
      for(var x = 0; x < num_in; x++) {
        const input = document.createElement('div');
        input.classList.add("input");
        input.classList.add("input_"+(x+1));

        if (this.isThreeWayValve(nodeData.class)) {
          input.classList.add('3WayValve');
        } else if (this.isHeaderPipe(parentEquip?.class)) {
          input.classList.add('headerPipe');
        } else if (nodeData.class == 'connectorLbend') {
          input.classList.add('connectorLbend');
        }
        json_inputs["input_"+(x+1)] = { "connections": []};
        inputs.appendChild(input);
      }
  
      const json_outputs = {}
      for(var x = 0; x < num_out; x++) {
        const output = document.createElement('div');
        output.classList.add("output");
        output.classList.add("output_"+(x+1));
        if (this.isHeaderPipe(parentEquip?.class)) {
          output.classList.add('headerPipe');
        } else if (nodeData.class == 'connectorLbend') {
          output.classList.add('connectorLbend');
        }
        json_outputs["output_"+(x+1)] = { "connections": []};
        outputs.appendChild(output);
      }
  
      const content = document.createElement('div');
      content.classList.add("drawflow_content_node");
      if (nodePipeClass) {
        content.classList.add(nodePipeClass);
      }
      if(typenode === false) {
        content.innerHTML = html;
      } else if (typenode === true) {
        content.appendChild(this.noderegister[html].html.cloneNode(true));
      } else {
        if(parseInt(this.render.version) === 3 ) {
          //Vue 3
          let wrapper = this.render.h(this.noderegister[html].html, this.noderegister[html].props, this.noderegister[html].options);
          wrapper.appContext = this.parent;
          this.render.render(wrapper,content);
  
        } else {
          // Vue 2
          let wrapper = new this.render({
            parent: this.parent,
            render: h => h(this.noderegister[html].html, { props: this.noderegister[html].props }),
            ...this.noderegister[html].options
          }).$mount()
          //
          content.appendChild(wrapper.$el);
        }
      }
  
      Object.entries(data).forEach(function (key, value) {
        if(typeof key[1] === "object") {
          insertObjectkeys(null, key[0], key[0]);
        } else {
          var elems = content.querySelectorAll('[df-'+key[0]+']');
            for(var i = 0; i < elems.length; i++) {
              elems[i].value = key[1];
              if(elems[i].isContentEditable) {
                elems[i].innerText = key[1];
              }
            }
        }
      })
  
      function insertObjectkeys(object, name, completname) {
        if(object === null) {
          var object = data[name];
        } else {
          var object = object[name]
        }
        if(object !== null) {
          Object.entries(object).forEach(function (key, value) {
            if(typeof key[1] === "object") {
              insertObjectkeys(object, key[0], completname+'-'+key[0]);
            } else {
              var elems = content.querySelectorAll('[df-'+completname+'-'+key[0]+']');
                for(var i = 0; i < elems.length; i++) {
                  elems[i].value = key[1];
                  if(elems[i].isContentEditable) {
                    elems[i].innerText = key[1];
                  }
                }
            }
          });
        }
      }
      node.appendChild(inputs);
      node.appendChild(content);
      node.appendChild(outputs);
      node.style.top = ele_pos_y + "px";
      node.style.left = ele_pos_x + "px";
      node.style.width = groupNodeWidth + "px";
      node.style.height = groupNodeHeight + "px";
      node.classList.add(`parentEquipId-${parentEquip?.equipId}`);
      if (this.isHeaderPipe(parentEquip?.class)) {
        node.classList.add('headerPipe');
      }
      if (this.isBypassPipe(parentEquip?.class)) {
        node.classList.add('bypass-headerPipe');
      }
      if(classoverride == "GROUP"){
        node.classList.add(`group-${parentEquip?.equipId}`);
      }

      parent.appendChild(node);
      this.precanvas.appendChild(parent);
      var json = {
        id: newNodeId,
        equipId:parentEquip?.equipId,
        name: name,
        data: data,
        childData: parentEquip,
        class: classoverride,
        html: html,
        typenode: typenode,
        inputs: json_inputs,
        outputs: json_outputs,
        pos_x: ele_pos_x,
        pos_y: ele_pos_y,
        margin: nodeData.margin,
        img: nodeData.img,
        displayName: nodeData.displayName,
        cutAwayImg: nodeData.cutAwayImg,
        nodePipeClass: nodePipeClass ? nodePipeClass : '',
        groupNodeWidth: groupNodeWidth,
        groupNodeHeight: groupNodeHeight,
        equipData: nodeData.equipData,
        parentNodeId: parentNodeId,
        groupPipeId: groupPipeId,
        equipType: this.getEquipType(classoverride,parentEquip),
        pointId: pointId != ""? pointId: (pointId == "" && (classoverride == "connectTwo" || classoverride == "pipeDemarkation"))?"": parentEquip?.equipId,
        drop_x: drop_x,
        drop_y: drop_y
      }

      if (!pipeType) {
        json.pipeType = pipeType;
      }
        
      this.drawflow.drawflow[this.module].data[newNodeId] = json;
      if (triggerDispatchEvent) {
        this.dispatch('nodeCreated', newNodeId);
      }
      if (!this.useuuid) {
        this.nodeId++;
      }
      return newNodeId;
    }

  isBuildingEquip(equipName) {
    return this.equipConfiguration.buildingList.indexOf(equipName) != -1;
  }

  isForwardEquipment(equipName) {
    return this.equipConfiguration.rightsideEquipList.indexOf(equipName) != -1;
  }

  isBtuMeterSubEquip(dataNode) {
    if (dataNode?.childData?.class == "btuMeter" && dataNode?.class != "btuMeter") {
      return true;
    } else {
      return false;
    }
  }

  isLeftToRightAnimationPipe(pipeClassList) {
    return this.equipConfiguration.animationLefttoRight.find(pipeClass => pipeClassList.includes(pipeClass));
  }

  isRightToLeftAnimationPipe(pipeClassList) {
    return this.equipConfiguration.animationRighttoLeft.find(pipeClass => pipeClassList.includes(pipeClass));
  }

  /**
   * Checks if the given data node represents a bypass equipment or bypass pipe.
   * @param {Object} dataNode - The data node to check.
   * @returns {boolean} - Returns true if the data node represents a bypass equipment or pipe, false otherwise.
   */
  isBypassEquipandPipe(dataNode) {
    if (this.equipConfiguration.bypassPipeList.indexOf(dataNode?.childData.class) != -1 || dataNode?.class == "byPassLine-connectTwo") {
      return true
    } else {
      return false;
    }
  }

  getEquipIdForMasterView(dataNode) {
    if (dataNode.class == "btuMeter") {
      return dataNode.childData.equipData.subEquips[0].equipId;
    } else {
      return dataNode.pointId != "" ? dataNode.pointId : dataNode.equipId;
    }
  }

  isBtuOrPump(equipName){
    return this.equipConfiguration.btuandPumpList.indexOf(equipName) != -1;
  }

  /**
   * Checks if the given equipment name is a pump.
   *
   * @param {string} equipName - The name of the equipment to check.
   * @returns {boolean} - Returns true if the equipment is a pump, false otherwise.
   */
  isPumpEquip(equipName){
    return this.equipConfiguration.pumpList.indexOf(equipName) != -1;
  }

  addNodeImport(dataNode, precanvas, key) {
    dataNode.data.customPoint = [];
    const _this = this;
    const parent = document.createElement('div');
    parent.classList.add("parent-node");

    const node = document.createElement('div');
    node.innerHTML = "";
    node.setAttribute("id", "node-" + dataNode.id);
    node.classList.add("drawflow-node");
    if (dataNode.class != '') {
      node.classList.add(...dataNode.class.split(' '));
    }

    if(dataNode.nodePipeClass != '') {
      const classlist = dataNode.nodePipeClass.split(' ');
      if (classlist && classlist[0]){
        dataNode.childData.class == 'building4Pipe' ? node.classList.add(`${dataNode.childData.class}-${classlist[0]}-${dataNode.class}`) :  node.classList.add(`${classlist[0]}-${dataNode.class}`);
      }
    }

    if (dataNode?.equipType == "3wayValve") {
      node.classList.add('3WayValve');
    }else if (_this.isHeaderPipe(dataNode?.childData?.class)) {
      node.classList.add('headerPipe');
    }

    const inputs = document.createElement('div');
    inputs.classList.add("inputs");

    const outputs = document.createElement('div');
    outputs.classList.add("outputs");

    Object.keys(dataNode.inputs).map(function (input_item, index) {
      const input = document.createElement('div');
      input.classList.add("input");

      input.classList.add(input_item);  
      if (dataNode?.equipType == "3wayValve") {
        input.classList.add('3WayValve');
      } else if (_this.isHeaderPipe(dataNode?.childData?.class)) {
        input.classList.add('headerPipe');
      }
      inputs.appendChild(input);
      Object.keys(dataNode.inputs[input_item].connections).map(function (output_item, index) {

        var connection = document.createElementNS('http://www.w3.org/2000/svg', "svg");
        var path = document.createElementNS('http://www.w3.org/2000/svg', "path");
        var topPath = document.createElementNS('http://www.w3.org/2000/svg', "path");
        path.classList.add("main-path");
        if (dataNode.childData.class.includes("btuMeter") && dataNode.class.includes('temperatureSensor')) {
          if (_this.getNodeFromId(dataNode.inputs[input_item].connections[output_item].node)?.class == 'btuMeter') {
            path.classList.add('btuMeter-pipe-connection');
          }
        }
        topPath.classList.add("pathBorder");
        path.setAttributeNS(null, 'd', '');
        topPath.setAttributeNS(null, 'd', '');
        // path.innerHTML = 'a';
        connection.classList.add("connection");
        connection.classList.add("node_in_node-" + dataNode.id);
        connection.classList.add("node_out_node-" + dataNode.inputs[input_item].connections[output_item].node);
        connection.classList.add(dataNode.inputs[input_item].connections[output_item].input);
        connection.classList.add(input_item);
        // connection.classList.add("animate");

        let node1Data = _this.getNodeFromId(dataNode.id);
        let node2Data = _this.getNodeFromId(dataNode.inputs[input_item].connections[output_item].node);
        let plantData =Object.values( _this.drawflow.drawflow.Home.data);
        /***** Adding Group pipe Id class to the 
        connection between the equips 
        ******/

        // Adding class to the connection between the parent Equip and subEquip
        if ((_this.isParentEquip(node1Data.childData.class) && _this.isParentEquip(node2Data.childData.class)) && node1Data.childData.equipId == node2Data.childData.equipId) {
          connection.classList.add('parentSubEquip-pipe');
          connection.classList.add(`parentSubEquip-pipe-${node1Data.childData.class}`);
        }

        // Adding class to the connection for Heat Exchanger Equip
        if (_this.isHeatExchangerEquip(node1Data.childData.class) && _this.isHeatExchangerEquip(node2Data.childData.class)) {
          connection.classList.add('heatExchanger-pipe');
          if (_this.editor_mode == 'fixed') {
            let heatExchangerEquipData = plantData.filter(equipObj => equipObj.class === "GROUP" && equipObj.equipId == node1Data.childData.equipId);
            if (heatExchangerEquipData.length) {
              heatExchangerEquipData.forEach((equipData) => {
                if (equipData?.leadnLagConnection?.length) {
                  equipData.leadnLagConnection.forEach((id) => {
                    connection.classList.add(`groupPipe-${id}`);
                    connection.classList.add(`heatExchangerEquip-${id}`);
                  });
                }
              });
            }
          }
        }

        // Adding class to the connection between the 3wayValve and Heat Exchanger Equip
        if (_this.isHeatExchangerEquip(node1Data.childData.class) && (_this.isThreeWayValve(node2Data.class) || _this.isHeaderPipe(node2Data.childData.class))) {
          connection.classList.add(`heatExchanger-pipe`);
          connection.classList.add(`groupPipe-${node1Data.groupPipeId}`);


          // Adding class to the connection for Heat Exchanger Equip
          let heatExchangerEquipData = plantData.filter(equipObj => equipObj.class === "GROUP" && equipObj.equipId == node1Data.childData.equipId);
          if (heatExchangerEquipData.length) {
            heatExchangerEquipData.forEach((equipData) => {
              if (equipData?.leadnLagConnection?.length) {
                equipData.leadnLagConnection.forEach((id) => {
                  connection.classList.add(`groupPipe-${id}`);
                  connection.classList.add(`heatExchangerEquip-${id}`);
                });
              }
            });
          }


        }
        if (_this.isHeatExchangerEquip(node2Data.childData.class) && (_this.isThreeWayValve(node1Data.class) || _this.isHeaderPipe(node1Data.childData.class))) {
          connection.classList.add('heatExchanger-pipe');
          connection.classList.add(`groupPipe-${node2Data.groupPipeId}`);

          // Adding class to the connection for Heat Exchanger Equip
          let heatExchangerEquipData = plantData.filter(equipObj => equipObj.class === "GROUP" && equipObj.equipId == node2Data.childData.equipId);
          if (heatExchangerEquipData.length) {
            heatExchangerEquipData.forEach((equipData) => {
              if (equipData?.leadnLagConnection?.length) {
                equipData.leadnLagConnection.forEach((id) => {
                  connection.classList.add(`groupPipe-${id}`);
                  connection.classList.add(`heatExchangerEquip-${id}`);
                });
              }
            });
          }
        }
        
        // Adding class to the connection between the headerPipe and pipe and pump
        if ((_this.isHeaderPipe(node1Data.childData.class) && _this.isHeaderPipe(node2Data.childData.class)) || (_this.isHeaderPipe(node1Data.childData.class) && ((_this.isPumpEquip(node2Data.childData.class) && node2Data.groupPipeId == node1Data.groupPipeId)))
          || (_this.isHeaderPipe(node2Data.childData.class) && ((_this.isPumpEquip(node1Data.childData.class) && node1Data.groupPipeId == node2Data.groupPipeId)))) {
          if (_this.editor_mode == 'fixed') {
            connection.classList.add(`headerPipe-pipe`);
            connection.classList.add(`headerPipe-pipe-${node1Data.childData.equipId}`);
            let headerPipeGroup = plantData.find(equipObj => equipObj.class === "GROUP" && equipObj.equipId == node1Data.childData.equipId);
            let pumpEquipData = _this.getPumpData(headerPipeGroup);

            if (pumpEquipData != null) {
              connection.classList.add(`groupPipe-${pumpEquipData?.pointId}`)
              connection.classList.add(`headerPumpEquip-${pumpEquipData?.pointId}`);
            }

            if (headerPipeGroup != -1) {
              headerPipeGroup?.groupPipeRef?.map((groupPipeId) => {
                connection.classList.add(`groupPipe-${groupPipeId}`);
              });
            }
          }
        }
              
        if (_this.isParentEquip(node1Data.class) && node2Data.class == "connectTwo" && node1Data.childData.equipId == node2Data.childData.equipId) {
          connection.classList.add('parentSubEquip-pipe');
          connection.classList.add(`parentSubEquip-pipe-${node1Data.childData.class}`);
          connection.classList.add(`groupPipe-${node2Data.groupPipeId}`);
        }
    
        if (_this.isParentEquip(node2Data.class) && node1Data.class == "connectTwo" && node1Data.childData.equipId == node2Data.childData.equipId) {
          connection.classList.add('parentSubEquip-pipe');
          connection.classList.add(`parentSubEquip-pipe-${node2Data.childData.class}`);
          connection.classList.add(`groupPipe-${node1Data.groupPipeId}`);
        }

        if (node1Data.class == "subEquip-connectTwo" && node2Data.class != "subEquip-connectTwo") {
          connection.classList.add(`groupPipe-${node1Data.groupPipeId}`);
          if (_this.editor_mode == 'fixed') {
            if (_this.isPumpEquip(node2Data.class)) {
              connection.classList.add(`groupPipe-${node2Data.pointId}`);
              connection.classList.add(`seriesPump-${node2Data.pointId}`);
            }
          }
        }

        if (node2Data.class == "subEquip-connectTwo" && node1Data.class != "subEquip-connectTwo") {
          connection.classList.add(`groupPipe-${node2Data.groupPipeId}`);
          if (_this.editor_mode == 'fixed') {
            if (_this.isPumpEquip(node1Data.class)) {
              connection.classList.add(`groupPipe-${node1Data.pointId}`);
              connection.classList.add(`seriesPump-${node1Data.pointId}`);
            }
          }
        }

        if ((node1Data.class == "subEquip-connectTwo" && node2Data.class == "subEquip-connectTwo") && node1Data.groupPipeId != undefined && node1Data.groupPipeId == node2Data.groupPipeId) {
          connection.classList.add(`groupPipe-${node1Data.groupPipeId}`);
          connection.classList.add(`groupPipe-${node2Data.groupPipeId}`);
        }

        if (node2Data?.class === "pipeDemarkation" && node1Data?.class === "connectTwo" && node1Data?.groupPipeId != undefined && node1Data?.groupPipeId != '') {
          connection.classList.add('connector-pipe');
          connection.classList.add(`groupPipe-${node1Data?.groupPipeId}`);
          if (_this.isBuildingEquip(node1Data.childData.class)) {
            let headerPipeGroupData = plantData.find(equipObj => equipObj.class === "GROUP" && equipObj.equipId == node2Data.equipId);
            if (headerPipeGroupData?.groupPipeRef?.length) {
              headerPipeGroupData.groupPipeRef.map((groupPipeId) => {
                connection.classList.add(`groupPipe-${groupPipeId}`);
              });
            }
          }
        } else if (node2Data?.class === "connectTwo" && node1Data?.class === "pipeDemarkation" && node2Data?.groupPipeId != undefined && node2Data?.groupPipeId != '') {
          if (_this.isBuildingEquip(node2Data.childData.class)) {
            let headerPipeGroupData = plantData.find(equipObj => equipObj.class === "GROUP" && equipObj.equipId == node1Data.equipId);
            if (headerPipeGroupData?.groupPipeRef?.length) {
              headerPipeGroupData.groupPipeRef.map((groupPipeId) => {
                connection.classList.add(`groupPipe-${groupPipeId}`);
              });
            }
          }
          connection.classList.add('connector-pipe');
          connection.classList.add(`groupPipe-${node2Data?.groupPipeId}`);
        } else if (node1Data?.equipType === "3wayValve" && (node2Data?.class === "pipeDemarkation" || node2Data?.class === "connectTwo") && !(node2Data?.childData.equipId == node1Data?.childData.equipId) && node2Data?.groupPipeId != undefined && node2Data?.groupPipeId != '') {
          connection.classList.add('connector-pipe');
          connection.classList.add(`groupPipe-${node2Data.groupPipeId}`);
        } else if ((node1Data?.class === "pipeDemarkation" || node1Data?.class === "connectTwo") && node2Data?.equipType === "3wayValve" && !(node2Data?.childData.equipId == node1Data?.childData.equipId) && node1Data?.groupPipeId != undefined && node1Data?.groupPipeId != '') {
          connection.classList.add('connector-pipe');
          connection.classList.add(`groupPipe-${node1Data?.groupPipeId}`);
        } else if (_this.isBtuOrPump(node1Data.class)) {
          if (node2Data?.class == 'connectorLbend' || node2Data?.connectionEndNodeData == 'connectorLbend' && node1Data?.connectedParentSubEquipId != undefined && node1Data?.connectedParentSubEquipId != '') {
            connection.classList.add('lbend-connector-pipe');

            if (_this.editor_mode == 'fixed') {
              let LbendConnectorGroupData = _this.getGroupEquipData(plantData, node2Data.childData.equipId);

              let lbendPumpData = _this.getPumpData(LbendConnectorGroupData);

              if (lbendPumpData != null) {
                connection.classList.add(`groupPipe-${lbendPumpData?.pointId}`)
                connection.classList.add(`lbendPump-${lbendPumpData?.pointId}`);
              }
            }
            connection.classList.add(`groupPipe-${node1Data?.connectedParentSubEquipId}`)
          } else if (node1Data?.connectedParentSubEquipId != undefined && node1Data?.connectedParentSubEquipId != '') {

            if (node1Data.connectedParentClass == "connectorLbend") {
              connection.classList.add(`groupPipe-${node1Data?.pointId}`);
            }

            connection.classList.add(`groupPipe-${node1Data?.connectedParentSubEquipId}`)
          }
        } else if (_this.isBtuOrPump(node2Data?.class)) {
          if (node1Data?.class == 'connectorLbend' || node1Data?.connectionEndNodeData == 'connectorLbend' && node2Data?.connectedParentSubEquipId != undefined && node2Data?.connectedParentSubEquipId != '') {
            connection.classList.add('lbend-connector-pipe');

            if (_this.editor_mode == 'fixed') {
              let LbendConnectorGroupData = _this.getGroupEquipData(plantData, node1Data.childData.equipId);

              let lbendPumpData = _this.getPumpData(LbendConnectorGroupData);
              if (lbendPumpData != null) {
                connection.classList.add(`groupPipe-${lbendPumpData?.pointId}`)
                connection.classList.add(`lbendPump-${lbendPumpData?.pointId}`);
              }
            }
            connection.classList.add(`groupPipe-${node2Data?.connectedParentSubEquipId}`)
          } else if (node2Data?.connectedParentSubEquipId != undefined && node2Data?.connectedParentSubEquipId != '') {
            connection.classList.add(`groupPipe-${node2Data?.connectedParentSubEquipId}`)
          }
        } else if (_this.isBtuMeterSubEquip(node1Data) || _this.isBtuMeterSubEquip(node2Data)) {
          _this.isBtuMeterSubEquip(node1Data.class) ? node1Data?.connectedParentSubEquipId != undefined && node1Data?.connectedParentSubEquipId != '' ? connection.classList.add(`groupPipe-${node1Data?.connectedParentSubEquipId}`) : '' : node2Data?.connectedParentSubEquipId != undefined && node2Data?.connectedParentSubEquipId != '' ? connection.classList.add(`groupPipe-${node2Data?.connectedParentSubEquipId}`) : '';
        } else if ((node1Data?.class === "connectTwo" || node1Data?.class === "pipeDemarkation") && node2Data?.class === "connectorLbend" && node2Data?.groupPipeId != undefined && node2Data?.groupPipeId != '') {
          connection.classList.add('connector-pipe');
          connection.classList.add('lbend-connector-pipe');

          if (_this.editor_mode == 'fixed') {
            let LbendConnectorGroupData = _this.getGroupEquipData(plantData, node2Data.childData.equipId);

            let lbendPumpData = _this.getPumpData(LbendConnectorGroupData);

            if (lbendPumpData != null) {
              connection.classList.add(`groupPipe-${lbendPumpData?.pointId}`)
              connection.classList.add(`lbendPump-${lbendPumpData?.pointId}`);
            }
          }
          connection.classList.add(`groupPipe-${node2Data?.groupPipeId}`);
        } else if ((node2Data?.class === "connectTwo" || node2Data?.class === "pipeDemarkation") && node1Data.class === "connectorLbend" && node1Data?.groupPipeId != undefined && node1Data?.groupPipeId != '') {
          connection.classList.add('connector-pipe');
          connection.classList.add('lbend-connector-pipe');

          if (_this.editor_mode == 'fixed') {
            let LbendConnectorGroupData = _this.getGroupEquipData(plantData, node1Data.childData.equipId);


            let lbendPumpData = _this.getPumpData(LbendConnectorGroupData);

            if (lbendPumpData != null) {
              connection.classList.add(`groupPipe-${lbendPumpData?.pointId}`)
              connection.classList.add(`lbendPump-${lbendPumpData?.pointId}`);
            }
          }

          connection.classList.add(`groupPipe-${node1Data?.groupPipeId}`);
        } else if ((node1Data?.class === "connectorLbend" && node2Data?.class === "connectorLbend") && node1Data?.groupPipeId == node2Data?.groupPipeId && node1Data?.groupPipeId != undefined && node1Data?.groupPipeId != '') {
          connection.classList.add('LbendConnector-pipe');

          if (_this.editor_mode == 'fixed') {
            let LbendConnectorGroupData = _this.getGroupEquipData(plantData, node2Data.childData.equipId);
            let lbendPumpData = _this.getPumpData(LbendConnectorGroupData);

            if (lbendPumpData != null) {
              connection.classList.add(`groupPipe-${lbendPumpData?.pointId}`)
              connection.classList.add(`lbendPump-${lbendPumpData?.pointId}`);
            }
          }

          connection.classList.add(`groupPipe-${node1Data?.groupPipeId}`);
        } else if ((_this.isBypassEquipandPipe(node1Data)) && _this.isBypassEquipandPipe(node2Data)) {
          // Adding class to the connection between the bypassPipe and bypassPipe
          connection.classList.add('bypassConnection-pipe');
          if (node1Data.class == 'byPassLine-connectTwo') {
            let headerPipeGroupData = plantData.find(equipObj => equipObj.class === "GROUP" && equipObj.equipId == node1Data.equipId);
            if (headerPipeGroupData?.groupPipeRef?.length) {
              headerPipeGroupData.groupPipeRef.map((groupPipeId) => {
                connection.classList.add(`groupPipe-${groupPipeId}`);
              });
            }
          } else if (node2Data.class == 'byPassLine-connectTwo') {
            let headerPipeGroupData = plantData.find(equipObj => equipObj.class === "GROUP" && equipObj.equipId == node2Data.equipId);
            if (headerPipeGroupData?.groupPipeRef?.length) {
              headerPipeGroupData.groupPipeRef.map((groupPipeId) => {
                connection.classList.add(`groupPipe-${groupPipeId}`);
              });
            }
          } else if (_this.isBypassPipe(node1Data.childData.class)) {
            let byPassPipeGroupData = plantData.find(equipObj => equipObj.class === "GROUP" && equipObj.equipId == node1Data.equipId);
            if (byPassPipeGroupData?.connectedNodeData?.length) {
              let connectedHeaderPipeData = plantData.find(equipObj => equipObj.class === "GROUP" && equipObj.equipId == byPassPipeGroupData.connectedNodeData[0].childData.equipId);
              if (connectedHeaderPipeData?.groupPipeRef?.length) {
                connectedHeaderPipeData.groupPipeRef.map((groupPipeId) => {
                  connection.classList.add(`groupPipe-${groupPipeId}`);
                })
              }
            }
          } else if (_this.isBypassPipe(node2Data.childData.class)) {
            let byPassPipeGroupData = plantData.find(equipObj => equipObj.class === "GROUP" && equipObj.equipId == node2Data.equipId);
            if (byPassPipeGroupData?.connectedNodeData?.length) {
              let connectedHeaderPipeData = plantData.find(equipObj => equipObj.class === "GROUP" && equipObj.equipId == byPassPipeGroupData.connectedNodeData[0].childData.equipId);
              if (connectedHeaderPipeData?.groupPipeRef?.length) {
                connectedHeaderPipeData.groupPipeRef.map((groupPipeId) => {
                  connection.classList.add(`groupPipe-${groupPipeId}`);
                })
              }
            }
          }
        } else if (node1Data.class === "connectTwo" && node2Data.class === "connectTwo") {
          // Adding class to the connection between the heatExhanger 
          if (_this.isHeatExchangerEquip(node1Data.childData.class) || _this.isHeatExchangerEquip(node2Data.childData.class)) {
            connection.classList.add(`heatExhanger-connection-pipe`);
            if (_this.editor_mode == 'fixed') {
              if (_this.isHeatExchangerEquip(node1Data.childData.class) && _this.isNonBuildingParentEquip(node2Data.childData.class)) {
                connection.classList.add(`groupPipe-${node2Data.groupPipeId}`);
              }
              if (_this.isHeatExchangerEquip(node2Data.childData.class) && _this.isNonBuildingParentEquip(node1Data.childData.class)) {
                connection.classList.add(`groupPipe-${node1Data.groupPipeId}`);
              }
            }
          } else {
            // Adding class to the connection between the Lead and Lag connections
            if ((node1Data.nodePipeClass === "CWReturnClass" && node2Data.nodePipeClass === "CWSupplyClass") || node1Data.nodePipeClass === "CWSupplyClass" && node2Data.nodePipeClass === "CWReturnClass") {
              connection.classList.add(`CWSupplyClass`);
              connection.classList.add(`lead-and-lag-pipe`);
              if (node1Data.nodePipeClass === "CWReturnClass") {
                connection.classList.add(`groupPipe-${node1Data.groupPipeId}`);
              } else {
                connection.classList.add(`groupPipe-${node2Data.groupPipeId}`);
              }
            }
            if ((node1Data.nodePipeClass === "boilerReturnClass" && node2Data.nodePipeClass === "boilerSupplyClass") || node1Data.nodePipeClass === "boilerSupplyClass" && node2Data.nodePipeClass === "boilerReturnClass") {
              connection.classList.add(`boilerSupplyClass`);
              connection.classList.add(`lead-and-lag-pipe`);
              if (node1Data.nodePipeClass === "CWReturnClass") {
                connection.classList.add(`groupPipe-${node1Data.groupPipeId}`);
              } else {
                connection.classList.add(`groupPipe-${node2Data.groupPipeId}`);
              }
            }
          }
        }
        
        if (node1Data.groupPipeId == "") {
          if (_this.isParentEquip(node1Data.class)) {
            if (_this.isSensorEquip(node2Data.class) && node2Data?.groupPipeId != "") {
              node1Data.groupPipeId = node2Data.groupPipeId;
            } else if (node1Data.childData.equipId == node2Data.childData.equipId) {
              node1Data.groupPipeId = node2Data.groupPipeId;
            }
          }
        }
        if (node2Data.groupPipeId == "") {
          if (_this.isParentEquip(node2Data.class)) {
            if (_this.isSensorEquip(node1Data.class) && node1Data.groupPipeId != "") {
              node2Data.groupPipeId = node1Data.groupPipeId;
            } else if (node1Data.childData.equipId == node2Data.childData.equipId) {
              node1Data.groupPipeId = node2Data.groupPipeId;
            }
          }
        }

        if (node1Data?.groupPipeId == node2Data?.groupPipeId && node2Data?.groupPipeId != undefined && node2Data?.groupPipeId != '') {
          connection.classList.add(`groupPipe-${node2Data?.groupPipeId}`);
        }

  /* Add Pipe color class based while 
  Import based on the saved nodePipeClass  
  */ 
        if (node1Data.connectedParentSubEquipPipeClass && node1Data.class.includes('pump1Vertical')) {
          const classlist = node1Data.connectedParentSubEquipPipeClass.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if (node2Data.connectedParentSubEquipPipeClass && node2Data.class.includes('pump1Vertical')) {
          const classlist = node2Data.connectedParentSubEquipPipeClass.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if ((node1Data.class == "connectorLbend" && node2Data.class == "connectorLbend") && node1Data.equipId == node2Data.equipId) {
          let pipeColor;
          if (node1Data.connectedParentSubEquipPipeClass != undefined || node2Data.connectedParentSubEquipPipeClass != undefined) {
            pipeColor = node1Data.connectedParentSubEquipPipeClass != undefined ? node1Data.connectedParentSubEquipPipeClass : node2Data.connectedParentSubEquipPipeClass;
          } else {
            pipeColor = node1Data.nodePipeClass
          }
          const classlist = pipeColor.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if ((node1Data.class == "connectorLbend" && (node2Data.class == "pipeDemarkation" || node2Data.class == "connectTwo"))) {
          const classlist = node1Data?.connectedParentSubEquipPipeClass?.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if ((node2Data.class == "connectorLbend" && (node1Data.class == "pipeDemarkation" || node1Data.class == "connectTwo"))) {
          const classlist = node2Data?.connectedParentSubEquipPipeClass?.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if (((node1Data.class == 'btuMeter' || _this.isBtuMeterSubEquip(node1Data)) && node1Data.connectedParentSubEquipPipeClass)) {
          const classlist = node1Data?.connectedParentSubEquipPipeClass?.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if (((node1Data.class == 'btuMeter' || _this.isBtuMeterSubEquip(node1Data)) && node1Data.nodePipeClass)) {
          const classlist = node1Data?.nodePipeClass?.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if (node2Data.connectedParentSubEquipPipeClass && (node2Data.class == 'btuMeter' || _this.isBtuMeterSubEquip(node2Data))) {
          const classlist = node2Data?.connectedParentSubEquipPipeClass?.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if (node2Data.nodePipeClass && (node2Data.class == 'btuMeter' || _this.isBtuMeterSubEquip(node2Data))) {
          const classlist = node2Data?.nodePipeClass?.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if(_this.equipConfiguration.nonBuildingParentEquip.includes(node1Data.childData.class) && _this.equipConfiguration.dualTempEquip.includes(node2Data.childData.class)) {
          // Adding nonBuildingParentEquip class, when the pipe is connected from the dualTempEquip to nonBuildingParentEquip
          connection.classList.add(node1Data.nodePipeClass);
        } else if(_this.equipConfiguration.nonBuildingParentEquip.includes(node2Data.childData.class) && _this.equipConfiguration.dualTempEquip.includes(node1Data.childData.class)) {
          // Adding nonBuildingParentEquip class, when the pipe is connected from the nonBuildingParentEquip to dualTempEquip
          connection.classList.add(node2Data.nodePipeClass);
        } else if (node1Data.nodePipeClass && node1Data.equipType != 'equip' && node1Data.equipType != '3wayValve' && !_this.isBypassPipe(node1Data.childData.class)) {
          dataNode.nodePipeClass = node1Data?.nodePipeClass;
          _this.drawflow.drawflow[_this.module].data[key].nodePipeClass = dataNode.nodePipeClass;
          const classlist = node1Data.nodePipeClass.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if (node2Data.nodePipeClass && node2Data.equipType != 'equip' && node1Data.equipType != '3wayValve' && !_this.isBypassPipe(node2Data.childData.class)) {
          dataNode.nodePipeClass = node2Data.nodePipeClass;
          const classlist = node2Data?.nodePipeClass?.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
          _this.drawflow.drawflow[_this.module].data[key].nodePipeClass = dataNode.nodePipeClass;
        } else if (node1Data.equipType == '3wayValve' && node2Data.nodePipeClass) {
          const classlist = node2Data?.nodePipeClass?.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if (node2Data.equipType == '3wayValve' && node1Data.nodePipeClass) {
          const classlist = node1Data?.nodePipeClass?.split(' ');
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if (_this.isBypassPipe(node2Data.childData.class)) {
          const classlist = [node2Data.nodePipeClass];
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        } else if (_this.isBypassPipe(node1Data.childData.class)) {
          const classlist = [node1Data.nodePipeClass];
          classlist?.forEach(_c => {
            if (_c && connection) connection.classList.add(_c);
          });
        }

       if (_this.isBypassPipe(dataNode.childData.class)) {
          _this.drawflow.drawflow[_this.module].data[key].nodePipeClass = _this.getPipeClass(dataNode.childData.class);
        }



        connection.appendChild(topPath);
        connection.appendChild(path);
        if (dataNode?.subEquipRefDeleted || (_this.isHeaderPipe(dataNode.childData.class) && dataNode?.equipDeleted && dataNode.html.includes("pipeArrow.svg"))) {
          _this.drawflow.drawflow[_this.module].data[key]['warningIcon-cl'] = connection.className.baseVal;
        }  
        precanvas.appendChild(connection);
      });
    });

    for (var x = 0; x < Object.keys(dataNode.outputs).length; x++) {
      const output = document.createElement('div');
      output.classList.add("output");

      output.classList.add("output_" + (x + 1));
      if (_this.isHeaderPipe(dataNode?.childData?.class)) {
        output.classList.add('headerPipe');
      }
      outputs.appendChild(output);
    }

    const content = document.createElement('div');
    content.classList.add("drawflow_content_node");
    // Adding Node Id for the content div, which is used to get the node data on hover of the node(Hover view).
    content.setAttribute("id",`drawflow_content_node-${dataNode.id}`);

    if (dataNode?.nodePipeClass) {
      const classlist = dataNode.nodePipeClass.split(' ');
      classlist.forEach(_c => {
        if (_c) content.classList.add(_c);
      });
    }
    if (this.editor_mode == 'fixed') {
      if(dataNode.equipType == "equip" && !dataNode.class.includes('heatExchanger')){
        content.addEventListener('mouseover', (event)=> {
          const divHeight = content.getBoundingClientRect().height
          const divRect = content.getBoundingClientRect();
          const mouseY = event.clientY - divRect.top;
          const percentage = (mouseY / divHeight) * 100;
          if (dataNode.cutAwayImg) {
            document.getElementById(`node-${dataNode.id}`).getElementsByClassName('equipImg')[0].src = '/assets/images/cpmEquips/' + dataNode.cutAwayImg;
            document.getElementById(`node-${dataNode.id}`).style.setProperty('z-index', '1502', 'important');
          }
          if (percentage <= 65) {
            this.dispatch("equipHoverEnter",dataNode);
          } else if(!this.isBuildingEquip(dataNode.class)) {
            // Trigger method for the remaining 35% of the div
             this.dispatch("equipHoverAlertEnter",dataNode);
          }
          
        });
        content.addEventListener('mouseleave', ()=> {
          if (dataNode.img) {
            document.getElementById(`node-${dataNode.id}`).getElementsByClassName('equipImg')[0].src = '/assets/images/cpmEquips/' + dataNode.img;
            document.getElementById(`node-${dataNode.id}`).style.setProperty('z-index', '1052', 'important');
          }
          this.dispatch("equipHoverLeave",dataNode);
        });
      }
    }
    if(dataNode.class !="GROUP" && !dataNode.class.includes('heatExchanger')){
      content.classList.add("alertRing");
      let alertRingClass = this.getAlertRingClass("");
      let alertConf = {"severityRing": "", "htmlContent": "" };
//      if (Object.entries(dataNode).length != 0 && dataNode?.data?.alert?.length > 0) {
    //    alertConf = this.alertsPopUp(dataNode);
       alertRingClass = this.getAlertRingClass(alertConf.severityRing);
  //    }
      content.classList.add(alertRingClass);
    }

    if (dataNode.typenode === false) {

      var pointId = `pointStatusContainerId-${this.getEquipIdForMasterView(dataNode)}`; 
      let equipContent = '';
      equipContent = document.createElement('div');
      equipContent.classList.add("drag-drawflow");

      if (this.editor_mode == 'edit') {
        const equipTitle = document.createElement('div');
        if(dataNode.displayName != 'Connector'){
          equipTitle.classList.add("equipNamewithoutPoint");
        }
        if (dataNode.class != 'GROUP' && dataNode.displayName != 'Connector') {
        equipTitle.innerHTML = dataNode.displayName;
        }
        equipContent.appendChild(equipTitle);
      }
  
      const setMarginContent = document.createElement('div');
     // setMarginContent.classList.add(dataNode.margin);
      setMarginContent.classList.add("equipImage");
      // Adding warning icon and tooltip on equips if equipDeleted property is true.
      if (((dataNode?.equipDeleted && !this.isHeaderPipe(dataNode.childData.class)) || dataNode?.equipPointDeleted) && this.isSensorNode(dataNode.class)) {
        const warningIconDiv = document.createElement('div');
        const parentDiv = document.createElement('div');
      parentDiv.className = "warning-icon";
        warningIconDiv.className = "fas fa-exclamation-triangle";
        const tooltipDiv = document.createElement('div');
        tooltipDiv.className = 'tooltip_text';
        tooltipDiv.innerHTML = 'One or more equips are deleted, Please update the central plant.'
        parentDiv.appendChild(tooltipDiv);
        parentDiv.appendChild(warningIconDiv)
        setMarginContent.appendChild(parentDiv);
      }
      equipContent.appendChild(setMarginContent);

      if (dataNode.class == 'pipeDemarkation' && this.editor_mode == 'edit') {
          if(dataNode.html.includes("pipeArrow.svg")){
            const equipImgContent = document.createElement('img');
            if (dataNode.html.includes("pipeArrowImg_forward")) {
              equipImgContent.classList.add("pipeArrowImg_forward");
            } else if (dataNode.html.includes("pipeArrowImg_backward")) {
              equipImgContent.classList.add("pipeArrowImg_backward");
            }
            equipImgContent.setAttribute("id", `pipeRotateImg_${dataNode.equipId}`);
            equipImgContent.src = "/assets/images/pipeArrow.svg";
            setMarginContent.appendChild(equipImgContent);

            if((dataNode.inputs.input_1.connections.length && dataNode.outputs.output_1.connections.length) || (dataNode.inputs.input_1.connections.length > 1 || dataNode.outputs.output_1.connections.length > 1 )){
              equipImgContent.style.display = 'none';
            }
          }
      }

      if (dataNode.class == 'GROUP' && (this.isHeaderPipe(dataNode.childData.class) || this.isBypassPipe(dataNode.childData.class)) && this.editor_mode == 'edit') {
        let groupElements = dataNode.data.groupNodeIds;
        let groupElementsData = [];
        
        groupElements.map(id => {
          groupElementsData.push(this.getNodeFromId(id))
        });

        let isconnectedNode = 0;
        groupElementsData.forEach(equipData => {
          if (equipData.class == "pipeDemarkation") {
          let equipInput = [];
          let equipOutputs = [];
          Object.keys(equipData.inputs).forEach(key => equipInput.push(equipData.inputs[key]));
          Object.keys(equipData.outputs).forEach(key => equipOutputs.push(equipData.outputs[key]));

          if(equipInput[0].connections.length >= 1 && equipOutputs[0].connections.length >= 1){
            isconnectedNode ++;
          }else{
            if (Object.keys(equipData.inputs).length > 0) {
              equipInput.forEach(input => {
                if (input.connections.length == 0) {
                  equipOutputs.forEach(outputdata => {
                    if (outputdata.connections.length > 1) {
                       isconnectedNode ++;
                    }
                  })
                }
              })
            }
    
            if (Object.keys(equipData.outputs).length > 0) {
              equipOutputs.forEach(output => {
                if (output.connections.length == 0) {
                  equipInput.forEach(input => {
                    if (input?.connections?.length > 1) {
                      isconnectedNode ++;
                    }
                  })
                }
              })
            }
          }
          }
        });
        if (isconnectedNode > 0) {
          var els = document.querySelector(`#pipeRotateImg_${dataNode?.equipId}`);
          if (els != null) {
            els.style.display = 'none';
          }
        }
      }

      if (this.editor_mode == 'edit') {
        content.appendChild(setMarginContent);
      }

      if (!this.isSensorNode(dataNode.class) && dataNode.class != 'byPassLine-connectTwo' && dataNode.class != 'subEquip-connectTwo' && dataNode.class != 'GROUP') {
        const equipImgContent = document.createElement('img');
        equipImgContent.classList.add("equipImg");
        if (dataNode.margin) {
          equipImgContent.classList.add(dataNode.margin);
        }
        // Adding height for the equips in Master view.
        if (this.isBuildingEquip(dataNode.class)) {
          equipImgContent.style.height = '1400px';
        }
        equipImgContent.src = this.setImgPath(dataNode.img);
        // Adding warning icon and tooltips on equips if equipDeleted property is true.
        if ((dataNode?.equipDeleted && !this.isHeaderPipe(dataNode.childData.class)) || dataNode?.equipPointDeleted) {
          const warningIconDiv = document.createElement('div');
          const parentDiv = document.createElement('div');
          parentDiv.className = "warning-icon";
          warningIconDiv.className = "fas fa-exclamation-triangle";
          const tooltipDiv = document.createElement('div');
          tooltipDiv.className = 'tooltip_text';
          tooltipDiv.innerHTML = 'One or more equips are deleted, Please update the central plant.'
          parentDiv.appendChild(tooltipDiv);
          parentDiv.appendChild(warningIconDiv);
          setMarginContent.appendChild(parentDiv);
        }
        setMarginContent.appendChild(equipImgContent);
      }

      if(this.editor_mode == "fixed"){
      // Master view container 
        if (dataNode.class != 'GROUP' && dataNode.class != 'connectTwo' && dataNode.class != 'pipeDemarkation' && dataNode.class != 'connectorLbend' &&  dataNode.class != 'subEquip-connectTwo' &&  dataNode.class != 'byPassLine-connectTwo' && this.editor_mode == "fixed"  && !this.isBtuMeterSubEquip(dataNode)) {
          let collapseIdLeft,collapseIconLeft;
        if((dataNode.nodePipeClass.includes("CWSupplyClass") || dataNode.nodePipeClass.includes("CWReturnClass")  || dataNode.nodePipeClass.includes("boilerSupplyClass")) || dataNode.class == 'heatExchanger_forward' || ((dataNode.nodePipeClass.includes("CDWSupplyClass") || dataNode.nodePipeClass.includes("CDWReturnClass")) && this.isForwardCDWSensors(dataNode.class)) && dataNode.class != "building2Pipe" && dataNode.class != "building2PipeHot" && dataNode.class != "dualTempBuilding2Pipe"  && dataNode.class != "building4Pipe"){
          collapseIdLeft = 'equipCollapseIcon-' + dataNode.pointId;
          collapseIconLeft = document.createElement('i');
          collapseIconLeft.classList.add("fa");
          collapseIconLeft.classList.add("fa-angle-double-right");
          collapseIconLeft.classList.add("openCollapseIconLeft");
          collapseIconLeft.setAttribute("id", collapseIdLeft);
        }
        
        const equipStatusContainer = document.createElement('div');
        if((dataNode.nodePipeClass.includes("CWSupplyClass")|| dataNode.nodePipeClass.includes("CWReturnClass")  || dataNode.nodePipeClass.includes("boilerSupplyClass")) || dataNode.class == 'heatExchanger_forward' || ((dataNode.nodePipeClass.includes("CDWSupplyClass") || dataNode.nodePipeClass.includes("CDWReturnClass")) && this.isForwardCDWSensors(dataNode.class)) && dataNode.class != "building2Pipe" && dataNode.class != "building2PipeHot" && dataNode.class != "dualTempBuilding2Pipe"  && dataNode.class != "building4Pipe"){
          equipStatusContainer.appendChild(collapseIconLeft);
          let expandContId = 'equipExpandCont-' + dataNode.pointId;
          let expandId = 'equipExpandIcon-' + dataNode.pointId;
          const expandIconDiv = document.createElement('div');
          expandIconDiv.classList.add("equipNameCollapsedBarLeft");
          expandIconDiv.innerHTML = dataNode.displayName;
          expandIconDiv.setAttribute("id", expandContId);
  
          const expandIcon = document.createElement('div');
          expandIcon.innerHTML = `<i id=${expandId} class="fa fa-angle-double-left openExpandIconLeft"></i>`;
          expandIconDiv.appendChild(expandIcon);
          equipContent.appendChild(expandIconDiv);
        }

        equipStatusContainer.classList.add("equipview-container");
        equipStatusContainer.setAttribute("id", pointId);

        const equipTitle = document.createElement('div');
        equipTitle.classList.add("equipNameStatusBar");
        equipTitle.innerHTML = `${dataNode.displayName}`;
        equipStatusContainer.appendChild(equipTitle);
        equipContent.appendChild(equipStatusContainer);
     
        let collapseId = 'equipCollapseIcon-' + dataNode.pointId;
        const collapseIcon = document.createElement('i');
        collapseIcon.classList.add("fa");
        collapseIcon.classList.add("fa-angle-double-left");
        collapseIcon.classList.add("openCollapseIcon");
        collapseIcon.setAttribute("id", collapseId);
        if((!dataNode.nodePipeClass.includes("CWSupplyClass") && !((dataNode.nodePipeClass.includes("CDWSupplyClass") || dataNode.nodePipeClass.includes("CDWReturnClass")) && this.isForwardCDWSensors(dataNode.class)) && !dataNode.nodePipeClass.includes("CWReturnClass") && !dataNode.nodePipeClass.includes("boilerSupplyClass") && dataNode.class != 'heatExchanger_forward') ){
          equipStatusContainer.appendChild(collapseIcon);
          let expandContId = 'equipExpandCont-' + dataNode.pointId;
          let expandId = 'equipExpandIcon-' + dataNode.pointId;
          const expandIconDiv = document.createElement('div');
          expandIconDiv.classList.add("equipNameCollapsedBar");
          expandIconDiv.innerHTML = dataNode.displayName;
          expandIconDiv.setAttribute("id", expandContId);
  
          const expandIcon = document.createElement('div');
          expandIcon.innerHTML = `<i id=${expandId} class="fa fa-angle-double-right openExpandIcon"></i>`;
          expandIconDiv.appendChild(expandIcon);
          equipContent.appendChild(expandIconDiv);
        }


        var pointHeightId = `pointHeightId-${dataNode.pointId != "" ? dataNode.pointId : dataNode.equipId}`;
        var pointCont = document.createElement("div");
        pointCont.classList.add("pointContainerHeight");
        pointCont.setAttribute("id", pointHeightId);



        const pointGroupLoader = document.createElement('div');
        pointGroupLoader.classList.add("pointLoader");
        pointGroupLoader.setAttribute("id", `pointLoader-${dataNode.pointId != "" ? dataNode.pointId : dataNode.equipId}`);
        pointGroupLoader.innerHTML =`<img class="masterPointLoader" src='/assets/images/loader.gif'></img>`;
        pointCont.appendChild(pointGroupLoader);
          equipStatusContainer.appendChild(pointCont);           
          equipContent.appendChild(equipStatusContainer);
          content.innerHTML = equipContent.innerHTML;

        equipStatusContainer.appendChild(pointCont);

        var masterViewLineId = 'masterviewLine-' + dataNode.pointId;
        var masterViewSvgId = 'masterViewSvgId-' + dataNode.pointId;
        let masterViewSvg = document.createElement('svg');
        masterViewSvg.setAttribute("id",masterViewSvgId);
        masterViewSvg.classList.add("masterlineSvg");
        
        // Master view line Pointer
        let x1, y1, x2, y2;
        // let totalPointsSelected = this.getSelectedPointLength(dataNode.data);
        let totalPointsSelected  = 5;

     
          x1 = -50;
          y1 = -100;
          x2 = -50;
          y2 = 50;

          if (dataNode.class == 'waterCooledChiller') {
            if (totalPointsSelected == 1 || totalPointsSelected == 2) {
              x1 = -50;
              y1 = -20;
              x2 = -50;
              y2 = 150;
            } else {
              x1 = -50;
              y1 = -80;
              x2 = -50;
              y2 = 130;
            }
          } else if (dataNode.class == 'airCooledChiller') {
            if (totalPointsSelected == 1 || totalPointsSelected == 2) {
              x1 = -50;
              y1 = -20;
              x2 = -50;
              y2 = 150;
            } else {
              x1 = -50;
              y1 = -90;
              x2 = -50;
              y2 = 160;
            }
          } else if (dataNode.class == 'coolingTowerSingleFan' || dataNode.class == 'coolingTowerFourFan') {
            if (totalPointsSelected == 1 || totalPointsSelected == 2) {
              x1 = -50;
              y1 = -60;
              x2 = -50;
              y2 = 150;
            } else {
              x2 = -50;
              y2 = 200;
            }

          } else if (dataNode.class == 'coolingTowerTwoFan') {
            if (totalPointsSelected == 1 || totalPointsSelected == 2) {
              x1 = -50;
              y1 = -30;
              x2 = -50;
              y2 = 190;
            } else {
              y2 = 220;
            }
          } else if (dataNode.class == 'steamBoiler' || dataNode.class == 'steamBoiler') {
            if (totalPointsSelected == 1 || totalPointsSelected == 2) {
              x1 = -50;
              y1 = -20;
              x2 = -50;
              y2 = 150;
            } else {
              x1 = -50;
              y1 = -20;
              x2 = -50;
              y2 = 50;
            }
          } else if (dataNode.class == 'condenserBoiler') {
            if (totalPointsSelected == 1 || totalPointsSelected == 2) {
              x1 = -50;
              y1 = -20;
              x2 = -50;
              y2 = 150;
            } else {
              x1 = -50;
              y1 = -70;
              x2 = -50;
              y2 = 90;
            }
          } else if (dataNode.class.includes('heatExchanger')) {
            x1 = 380;
            y1 = -120;
            x2 = 380;
            y2 = 80;
          } else if (dataNode.class == 'building4Pipe') {
            x1 = 0;
            y1 = -120;
            x2 = 0;
            y2 = 30;
          } else if (dataNode.class == 'building2Pipe' || dataNode.class == 'building2PipeHot' || dataNode.class == 'dualTempBuilding2Pipe') {
            x1 = -100;
            y1 = -100;
            x2 = -100;
            y2 = 30;
          } else {
            if (dataNode.nodePipeClass) {
              // Top Placement Master point
              // Condensor (supply, return) Boiler- Return, DualTemp - Return, All Bypass Line 

              if (dataNode.childData.class == 'building4Pipe') {
                if (dataNode.nodePipeClass.includes("CWReturnClass")) {
                  if (dataNode.class.includes('temperatureSensor') || dataNode.class.includes('wellTemperature')) {
                    x1 = 0;
                    y1 = -130;
                    x2 = 0;
                    y2 = -30;
                  } else if (dataNode.class.includes("diffPressureSensor") || dataNode.class.includes('pressureSensor')) {
                    x1 = 110;
                    y1 = -150;
                    x2 = 110;
                    y2 = 50;
                  }
                  else if (dataNode.class.includes('flowSensor') && dataNode.class.includes('forward')) {
                    x1 = 100;
                    y1 = -95;
                    x2 = 100;
                    y2 = 50;
                  }
                  else if (dataNode.class.includes('flowSensor')) {
                    x1 = 100;
                    y1 = -30;
                    x2 = 100;
                    y2 = 50;
                  } else if (dataNode.class.includes('valveActuator')) {
                    x1 = 85;
                    y1 = -80;
                    x2 = 85;
                    y2 = 50;
                  } else if (dataNode.class.includes('threeWayValve')) {
                    x1 = 70;
                    y1 = -80;
                    x2 = 70;
                    y2 = 35;
                  } else if (dataNode.class.includes('btuMeter')) {
                    x1 = 110;
                    y1 = -40;
                    x2 = 110;
                    y2 = 50;
                  } else if (dataNode.class.includes('pump1Vertical')) {
                    x1 = 240;
                    y1 = -210;
                    x2 = 240;
                    y2 = 150;
                  }
                }
                else if (dataNode.nodePipeClass.includes("CWSupplyClass")) {
                  if (dataNode.class.includes('temperatureSensor') || dataNode.class.includes('wellTemperature')) {
                    x1 = 0;
                    y1 = -330;
                    x2 = 0;
                    y2 = -30;
                  } else if (dataNode.class.includes("diffPressureSensor") || dataNode.class.includes('pressureSensor')) {
                    x1 = 110;
                    y1 = -350;
                    x2 = 110;
                    y2 = 50;
                  } else if (dataNode.class.includes('flowSensor') && dataNode.class.includes('forward')) {
                    x1 = 100;
                    y1 = -290;
                    x2 = 100;
                    y2 = 60;
                  } else if (dataNode.class.includes('flowSensor')) {
                    x1 = 100;
                    y1 = -220;
                    x2 = 100;
                    y2 = 60;
                  } else if (dataNode.class.includes('valveActuator')) {
                    x1 = 85;
                    y1 = -280;
                    x2 = 85;
                    y2 = 50;
                  } else if (dataNode.class.includes('threeWayValve') && dataNode.class.includes('backward')) {
                    x1 = 70;
                    y1 = -270;
                    x2 = 70;
                    y2 = 40;
                  } else if (dataNode.class.includes('threeWayValve')) {
                    x1 = 70;
                    y1 = -200;
                    x2 = 70;
                    y2 = 40;
                  } else if (dataNode.class.includes('btuMeter')) {
                    x1 = 110;
                    y1 = -40;
                    x2 = 110;
                    y2 = 50;
                  } else if (dataNode.class.includes('pump1Vertical')) {
                    x1 = 260;
                    y1 = -110;
                    x2 = 260;
                    y2 = 110;
                  }
                }
                else if (dataNode.nodePipeClass.includes("boilerReturnClass")) {
                  if (dataNode.class.includes('temperatureSensor') || dataNode.class.includes('wellTemperature')) {
                    x1 = 20;
                    y1 = 440;
                    x2 = 20;
                    y2 = 40;
                  } else if (dataNode.class.includes("diffPressureSensor") || dataNode.class.includes('pressureSensor')) {
                    x1 = 110;
                    y1 = 430;
                    x2 = 110;
                    y2 = 80;
                  } else if (dataNode.class.includes('flowSensor')) {
                    x1 = 100;
                    y1 = 330;
                    x2 = 100;
                    y2 = 50;
                  } else if (dataNode.class.includes('valveActuator')) {
                    x1 = 80;
                    y1 = 460;
                    x2 = 80;
                    y2 = 50;
                  } else if (dataNode.class.includes('threeWayValve')) {
                    x1 = 90;
                    y1 = 460;
                    x2 = 90;
                    y2 = 120;
                  } else if (dataNode.class.includes('btuMeter')) {
                    x1 = 110;
                    y1 = 40;
                    x2 = 110;
                    y2 = 290;
                  } else if (dataNode.class.includes('pump1Vertical')) {
                    x1 = 260;
                    y1 = 510;
                    x2 = 260;
                    y2 = 120;
                  }
                }
                else if (dataNode.nodePipeClass.includes('boilerSupplyClass')){
                  if (dataNode.class.includes('temperatureSensor') || dataNode.class.includes('wellTemperature')) {
                    x1 = 20;
                    y1 = 240;
                    x2 = 20;
                    y2 = 40;
                  } else if (dataNode.class.includes("diffPressureSensor") || dataNode.class.includes('pressureSensor')) {
                    x1 = 110;
                    y1 = 230;
                    x2 = 110;
                    y2 = 80;
                  } else if (dataNode.class.includes('flowSensor')) {
                    x1 = 100;
                    y1 = 440;
                    x2 = 100;
                    y2 = 50;
                  } else if (dataNode.class.includes('valveActuator')) {
                    x1 = 80;
                    y1 = 280;
                    x2 = 80;
                    y2 = 50;
                  } else if (dataNode.class.includes('threeWayValve')) {
                    x1 = 95;
                    y1 = 285;
                    x2 = 95;
                    y2 = 120;
                  } else if (dataNode.class.includes('btuMeter')) {
                    x1 = 110;
                    y1 = 290;
                    x2 = 110;
                    y2 = 40;
                  } else if (dataNode.class.includes('pump1Vertical')) {
                    x1 = 236;
                    y1 = 430;
                    x2 = 236;
                    y2 = 140;
                  }
                }
            } else if (dataNode.nodePipeClass.includes("CDWSupplyClass") || dataNode.nodePipeClass.includes("boilerReturnClass") || dataNode.nodePipeClass.includes("DTReturnClass") || dataNode.nodePipeClass.includes('HotBypassClass') || dataNode.nodePipeClass.includes('DTBypassClass') || dataNode.nodePipeClass.includes('CDWBypassClass') || dataNode.nodePipeClass.includes('CWBypassClass')) {
                if (dataNode.class.includes('temperatureSensor') || dataNode.class.includes('wellTemperature')) {
                  x1 = 0;
                  y1 = -130;
                  x2 = 0;
                  y2 = -30;
                } else if (dataNode.class.includes("diffPressureSensor") || dataNode.class.includes('pressureSensor')) {
                  x1 = 110;
                  y1 = -150;
                  x2 = 110;
                  y2 = 50;
                }
                else if (dataNode.class.includes('flowSensor') && dataNode.class.includes('forward')) {
                  x1 = 100;
                  y1 = -95;
                  x2 = 100;
                  y2 = 50;
                }
                else if (dataNode.class.includes('flowSensor')) {
                  x1 = 100;
                  y1 = -30;
                  x2 = 100;
                  y2 = 50;
                } else if (dataNode.class.includes('valveActuator')) {
                  x1 = 85;
                  y1 = -80;
                  x2 = 85;
                  y2 = 50;
                } else if (dataNode.class.includes('threeWayValve')) {
                  x1 = 70;
                  y1 = -80;
                  x2 = 70;
                  y2 = 35;
                } else if (dataNode.class.includes('btuMeter')) {
                  x1 = 110;
                  y1 = -40;
                  x2 = 110;
                  y2 = 50;
                } else if (dataNode.class.includes('pump1Vertical')) {
                  x1 = 240;
                  y1 = -210;
                  x2 = 240;
                  y2 = 150;
                }
              } else if (dataNode.nodePipeClass.includes("CDWReturnClass")) {
                if (dataNode.class.includes('temperatureSensor') || dataNode.class.includes('wellTemperature')) {
                  x1 = 0;
                  y1 = -330;
                  x2 = 0;
                  y2 = -30;
                } else if (dataNode.class.includes("diffPressureSensor") || dataNode.class.includes('pressureSensor')) {
                  x1 = 110;
                  y1 = -350;
                  x2 = 110;
                  y2 = 50;
                } else if (dataNode.class.includes('flowSensor') && dataNode.class.includes('forward')) {
                  x1 = 100;
                  y1 = -290;
                  x2 = 100;
                  y2 = 60;
                } else if (dataNode.class.includes('flowSensor')) {
                  x1 = 100;
                  y1 = -220;
                  x2 = 100;
                  y2 = 60;
                } else if (dataNode.class.includes('valveActuator')) {
                  x1 = 85;
                  y1 = -280;
                  x2 = 85;
                  y2 = 50;
                } else if (dataNode.class.includes('threeWayValve') && dataNode.class.includes('backward')) {
                  x1 = 70;
                  y1 = -270;
                  x2 = 70;
                  y2 = 40;
                } else if (dataNode.class.includes('threeWayValve')) {
                  x1 = 70;
                  y1 = -200;
                  x2 = 70;
                  y2 = 40;
                } else if (dataNode.class.includes('btuMeter')) {
                  x1 = 110;
                  y1 = -40;
                  x2 = 110;
                  y2 = 50;
                } else if (dataNode.class.includes('pump1Vertical')) {
                  x1 = 260;
                  y1 = -110;
                  x2 = 260;
                  y2 = 110;
                }
              } else if (dataNode.nodePipeClass.includes("CWSupplyClass") || dataNode.nodePipeClass.includes("boilerSupplyClass") || dataNode.nodePipeClass.includes("DTSupplyClass")) {
                if (dataNode.class.includes('temperatureSensor') || dataNode.class.includes('wellTemperature')) {
                  x1 = 20;
                  y1 = 240;
                  x2 = 20;
                  y2 = 40;
                } else if (dataNode.class.includes("diffPressureSensor") || dataNode.class.includes('pressureSensor')) {
                  x1 = 110;
                  y1 = 230;
                  x2 = 110;
                  y2 = 80;
                } else if (dataNode.class.includes('flowSensor')) {
                  x1 = 100;
                  y1 = 440;
                  x2 = 100;
                  y2 = 50;
                } else if (dataNode.class.includes('valveActuator')) {
                  x1 = 80;
                  y1 = 280;
                  x2 = 80;
                  y2 = 50;
                } else if (dataNode.class.includes('threeWayValve')) {
                  x1 = 95;
                  y1 = 285;
                  x2 = 95;
                  y2 = 120;
                } else if (dataNode.class.includes('btuMeter')) {
                  x1 = 110;
                  y1 = 290;
                  x2 = 110;
                  y2 = 40;
                } else if (dataNode.class.includes('pump1Vertical')) {
                  x1 = 236;
                  y1 = 430;
                  x2 = 236;
                  y2 = 140;
                }
              } else if (dataNode.nodePipeClass.includes("CWReturnClass")) {
                if (dataNode.class.includes('temperatureSensor') || dataNode.class.includes('wellTemperature')) {
                  x1 = 20;
                  y1 = 440;
                  x2 = 20;
                  y2 = 40;
                } else if (dataNode.class.includes("diffPressureSensor") || dataNode.class.includes('pressureSensor')) {
                  x1 = 110;
                  y1 = 430;
                  x2 = 110;
                  y2 = 80;
                } else if (dataNode.class.includes('flowSensor')) {
                  x1 = 100;
                  y1 = 330;
                  x2 = 100;
                  y2 = 50;
                } else if (dataNode.class.includes('valveActuator')) {
                  x1 = 80;
                  y1 = 460;
                  x2 = 80;
                  y2 = 50;
                } else if (dataNode.class.includes('threeWayValve')) {
                  x1 = 90;
                  y1 = 460;
                  x2 = 90;
                  y2 = 120;
                } else if (dataNode.class.includes('btuMeter')) {
                  x1 = 110;
                  y1 = 40;
                  x2 = 110;
                  y2 = 290;
                } else if (dataNode.class.includes('pump1Vertical')) {
                  x1 = 260;
                  y1 = 510;
                  x2 = 260;
                  y2 = 120;
                }
              }
            }
          }
        

        let masterViewLine = document.createElement('line');
        masterViewLine.setAttribute("id", masterViewLineId);
        masterViewLine.setAttribute("x1", x1);
        masterViewLine.setAttribute("y1", y1);
        masterViewLine.setAttribute("x2", x2);
        masterViewLine.setAttribute("y2", y2);
        masterViewLine.setAttribute("stroke", "#666");

        let masterViewCircle = document.createElement('circle');
        masterViewCircle.setAttribute("cx", x2);
        masterViewCircle.setAttribute("cy", y2);
        masterViewCircle.setAttribute("r", 5);
        masterViewCircle.setAttribute("fill", "#666");

        masterViewSvg.appendChild(masterViewLine);
        masterViewSvg.appendChild(masterViewCircle);

        if (!this.isSensorNode(dataNode.class) && dataNode.class != 'heatExchanger') {
          equipContent.appendChild(masterViewSvg);
        }

        equipContent.appendChild(equipStatusContainer);

        // Added equipName container for status view.
        const actualEquipNameContainer = document.createElement('div');
        actualEquipNameContainer.setAttribute("id", `equipNameContainerStatusView-${pointId}`);
        actualEquipNameContainer.className = "equipNameContainerStatusView";
        const actualEquipName = document.createElement('div');
        actualEquipName.setAttribute("id", `equipName-${pointId}`);
        actualEquipName.className = "equipName-title";
        if (this.isSensorNode(dataNode.class)) {
          if (!this.isSensorNode(dataNode.childData.class)) {
            dataNode.childData.subEquips.forEach(_subEquip => {
              const foundChildEquip = _subEquip?.equips?.find(_childEquip => _childEquip.pointId == dataNode.pointId);
              if (foundChildEquip) {
                const name = foundChildEquip?.shortName ? foundChildEquip.shortName: foundChildEquip?.pointName;
                actualEquipName.innerHTML = name ? name : dataNode.displayName;
                actualEquipName.setAttribute("title", name ? name : dataNode.displayName);
              }
            });
          } else {
            actualEquipName.innerHTML = dataNode.childData.name;
            actualEquipName.setAttribute("title", dataNode.childData.name);
          }
        } else {
          actualEquipName.innerHTML = dataNode.name;
          actualEquipName.setAttribute("title", dataNode.name);
        }
        actualEquipNameContainer.appendChild(actualEquipName);
        masterViewSvg.setAttribute("id",`status-view-${masterViewSvgId}`);
        // actualEquipNameContainer.appendChild(masterViewSvg);
        equipContent.appendChild(actualEquipNameContainer);

        if (this.isSensorNode(dataNode.class) || dataNode.class == 'heatExchanger' || this.isBtuOrPump(dataNode.class)) {
          equipContent.appendChild(masterViewSvg);
        }
        content.innerHTML = equipContent.innerHTML;
      } else if(Object.entries(dataNode.data).length == 0) {
        const equipTitle = document.createElement('div');
        if(dataNode.displayName != 'Connector'){
          equipTitle.classList.add("equipNamewithoutPoint");
        }
        if (dataNode.class != 'GROUP' && dataNode.displayName != 'Connector' && dataNode.displayName != 'byPassLine-connectTwo') {
          equipTitle.innerHTML = dataNode.displayName;
        }
        equipContent.appendChild(equipTitle);
        content.innerHTML = equipContent.innerHTML;
      }

      // Alert Container
      if (dataNode.class != 'GROUP' && Object.entries(dataNode).length != 0  && !this.isBuildingEquip(dataNode.class)) {
        let equipId = dataNode.equipId;
        if (dataNode.class.includes("pump1Vertical")) {
          if (dataNode.childData.equipData.subEquips.filter(x=>x.equip[0] == 'pump1Vertical').length) {
            equipId = dataNode.childData.equipData.subEquips.filter(x=>x.equip[0] == 'pump1Vertical')[0].equipId;
          }
        }
        const alertsMainContainer = document.createElement('div');
        const alertId = `alertsContainer-${equipId}`;
        alertsMainContainer.classList.add("alerts-outer-container");
        alertsMainContainer.classList.add("display-none-important");
        alertsMainContainer.setAttribute("id", alertId);
        content.appendChild(alertsMainContainer);
      }
    
      // Hover View Container 
      if (dataNode.class != 'GROUP' && this.editor_mode == 'fixed') {

        if (dataNode.class == 'waterCooledChiller') {

          // if (this.checkHoverChillerSelected(dataNode.data)) {
            let hoverContainerCompressorId = 'hoverChiller-' + dataNode.equipId;
            const hoverCompresserContainer = document.createElement('div');
            hoverCompresserContainer.classList.add("hoverCompressorContainer");
            hoverCompresserContainer.setAttribute("id", hoverContainerCompressorId);

            const hoverCompresserHeader = document.createElement('div');
            hoverCompresserHeader.classList.add("hoverChillerTitle");
            hoverCompresserHeader.innerHTML = dataNode.displayName;
            hoverCompresserContainer.appendChild(hoverCompresserHeader);

            const loaderDiv = document.createElement('div');
            loaderDiv.classList.add('hoverLoaderDiv');
            const loader = document.createElement('img');
            loader.src = "/assets/images/loader.gif";
            loader.classList.add('hoverLoader');
            loaderDiv.appendChild(loader);
            hoverCompresserContainer.appendChild(loaderDiv);
            
            const hoverViewBottomStyle = document.createElement('div');
            hoverViewBottomStyle.classList.add('hoverViewBottomStyle');
            hoverCompresserContainer.appendChild(hoverViewBottomStyle);
            content.appendChild(hoverCompresserContainer);
          // }
        }

        if (dataNode.class == 'condenserBoiler' || dataNode.class == 'steamBoiler' || dataNode.class == 'airCooledChiller'){

          let hoverBoilerContainerId = 'hoverBoiler-' + dataNode.equipId;
          const hoverBoilerContainer = document.createElement('div');
          hoverBoilerContainer.classList.add("hoverBoilerContainer");
          hoverBoilerContainer.setAttribute("id", hoverBoilerContainerId);

          const hoverBoilerHeader = document.createElement('div');
          hoverBoilerHeader.classList.add("hoverCoolingTowerTitle");
          hoverBoilerHeader.innerHTML = dataNode.displayName;

          hoverBoilerContainer.appendChild(hoverBoilerHeader);
          const loaderDiv = document.createElement('div');
          loaderDiv.classList.add('hoverLoaderDiv');
          const loader = document.createElement('img');
          loader.src = "/assets/images/loader.gif";
          loader.classList.add('hoverLoader');
          loaderDiv.appendChild(loader);
          hoverBoilerContainer.appendChild(loaderDiv);

          const hoverViewBottomStyle = document.createElement('div');
          hoverViewBottomStyle.classList.add('hoverViewBottomStyle');
          hoverBoilerContainer.appendChild(hoverViewBottomStyle);
          content.appendChild(hoverBoilerContainer);
        }
        
      
        //Hover view tooltip content for "CoolingTower and PumpVertical"
        if (dataNode.class.includes('pump1Vertical') || dataNode.class.includes('coolingTowerSingleFan') || dataNode.class.includes('coolingTowerFourFan') || dataNode.class.includes('coolingTowerTwoFan')) {
  
          const loaderDiv = document.createElement('div');
          loaderDiv.classList.add('hoverLoaderDiv');
          const loader = document.createElement('img');
          loader.src = "/assets/images/loader.gif";
          loader.classList.add('hoverLoader');
          loaderDiv.appendChild(loader);

          let hoverContainerId = dataNode.class + "-" + (dataNode.class.includes("pump1Vertical") ?  dataNode.childData.equipData.subEquips.filter(x=>x.equip[0] == 'pump1Vertical')[0].equipId : dataNode.equipId);
          const hoverContainer = document.createElement('div');
          hoverContainer.classList.add("hoverCoolingTowerContainer");
          hoverContainer.setAttribute("id", hoverContainerId);
          hoverContainer.appendChild(loaderDiv);
          content.appendChild(hoverContainer);
        }

      }
    }
    
  } else if (dataNode.typenode === true) {
      content.appendChild(this.noderegister[dataNode.html].html.cloneNode(true));
    } else {
      if (parseInt(this.render.version) === 3) {
        //Vue 3
        let wrapper = this.render.h(this.noderegister[dataNode.html].html, this.noderegister[dataNode.html].props, this.noderegister[dataNode.html].options);
        wrapper.appContext = this.parent;
        this.render.render(wrapper, content);

      } else {
        //Vue 2
        let wrapper = new this.render({
          parent: this.parent,
          render: h => h(this.noderegister[dataNode.html].html, { props: this.noderegister[dataNode.html].props }),
          ...this.noderegister[dataNode.html].options
        }).$mount()
        content.appendChild(wrapper.$el);
      }
    }

    Object.entries(dataNode.data).forEach(function (key, value) {
      if (typeof key[1] === "object") {
        insertObjectkeys(null, key[0], key[0]);
      } else {
        var elems = content.querySelectorAll('[df-' + key[0] + ']');
        for (var i = 0; i < elems.length; i++) {
          elems[i].value = key[1];
          if (elems[i].isContentEditable) {
            elems[i].innerText = key[1];
          }
        }
      }
    })

    function insertObjectkeys(object, name, completname) {
      if (object === null) {
        var object = dataNode.data[name];
      } else {
        var object = object[name]
      }
      if (object !== null) {
        Object.entries(object).forEach(function (key, value) {
          if (typeof key[1] === "object") {
            insertObjectkeys(object, key[0], completname + '-' + key[0]);
          } else {
            var elems = content.querySelectorAll('[df-' + completname + '-' + key[0] + ']');
            for (var i = 0; i < elems.length; i++) {
              elems[i].value = key[1];
              if (elems[i].isContentEditable) {
                elems[i].innerText = key[1];
              }
            }
          }
        });
      }
    }
    node.appendChild(inputs);
    node.appendChild(content);
    node.appendChild(outputs);
    node.style.top = dataNode.pos_y + "px";
    node.style.left = dataNode.pos_x + "px";

    if (this.isPumpEquip(dataNode.class)) {
      node.classList.add(`parentEquipId-${dataNode.pointId}`);
    } else {
      node.classList.add(`parentEquipId-${dataNode.equipId}`);
    }

    if(dataNode.class == "GROUP"){
      node.style.width = dataNode.groupNodeWidth + "px";
      node.style.height = dataNode.groupNodeHeight + "px";
      node.style.visibility = 'hidden';
      node.classList.add(`group-${dataNode.equipId}`);
    }

    parent.appendChild(node);
    this.precanvas.appendChild(parent);
  }

  isPointListTagsAreSame(arr1, arr2) {
    return arr1.every(value => arr2.includes(value));
  }

  calculateInnerRadius(d,i) {
    const val = i == 0 ? 35: 40;
    return val+25*d.parentIndex
  }

  mapDataSetValues(map) {
    var values = [];
    for (var key in map) {
    values.push(map[key])}
    return values;
  }

  isPointListTagsAreSame(arr1, arr2) {
    return (arr1 && arr2) && (arr1.length === arr2.length) && arr1.every((value, index) => value === arr2[index]);
  }

  isForwardCDWSensors(dataNodeClass) {
    if ((this.equipConfiguration?.forwardSensorsList?.includes(dataNodeClass))) {
      return true;
    }
    return false;
  }

  getSelectedPointLength(data) {
    let totalPointslength = 0;
    let totalPoints = data.point.concat(data.customPoint);
    totalPointslength = totalPoints.filter(pointData => pointData.isMaster);
    return totalPointslength.length;
  }

  checkMasterViewOverlap(selectedMasterId, lineId,equipId,nodePipeClass) {
 
    if (document.getElementById(selectedMasterId)) {

      let selectedMasterViewBoundary = document.getElementById(selectedMasterId).getBoundingClientRect();
      selectedMasterViewBoundary.height = selectedMasterViewBoundary.height + 30;

      let masterViewContent = document.querySelectorAll('.equipview-container');
      let checkIsCollide = [];
      let getAllNodeBoundary = [];

      let allNodeData = this.drawflow.drawflow.Home.data;
      let allNodeIDs = Object.keys(allNodeData);

      if (masterViewContent.length > 0) {
        masterViewContent.forEach((equipMasterViewContainer, i) => {
          if (selectedMasterId != equipMasterViewContainer.id) {
            checkIsCollide.push(this.collide(selectedMasterViewBoundary, document.getElementById(equipMasterViewContainer.id).getBoundingClientRect()));
          }
        });

        if (checkIsCollide.includes(true)) {
          this.handleMasterCollide(selectedMasterId, lineId, equipId, nodePipeClass);
        }
      }
    }
  }

  handleMasterCollide(selectedMasterId, lineId, equipId, nodePipeClass) {
    let getSelctedMasterTopStyle = Number(window.getComputedStyle(document.getElementById(selectedMasterId)).top.slice(0, -2));
    let newStyle;
    if (nodePipeClass == "CDWReturnClass" || nodePipeClass == "CDWSupplyClass") {
      newStyle = getSelctedMasterTopStyle > 0 ? getSelctedMasterTopStyle - 50 : getSelctedMasterTopStyle + 90;
    } else if (nodePipeClass == "CWSupplyClass" || nodePipeClass == "CWReturnClass" || nodePipeClass == "boilerSupplyClass" || nodePipeClass == "boilerReturnClass") {
      newStyle = getSelctedMasterTopStyle > 0 ? getSelctedMasterTopStyle + 50 : getSelctedMasterTopStyle - 90;
    } else {
      newStyle = getSelctedMasterTopStyle > 0 ? getSelctedMasterTopStyle + 50 : getSelctedMasterTopStyle - 50;
    }
    // let newStyle = getSelctedMasterTopStyle > 0 ? getSelctedMasterTopStyle + 60 : getSelctedMasterTopStyle - 60;
    document.getElementById(selectedMasterId).style.top = `${newStyle}px`;

    let lineContent = document.getElementById(lineId);
    if (nodePipeClass == "CDWReturnClass" || nodePipeClass == "CDWSupplyClass") {
      newStyle = newStyle > 0 ? newStyle - 100 : newStyle - 380;
    } else if (nodePipeClass == "CWSupplyClass" || nodePipeClass == "CWReturnClass" || nodePipeClass == "boilerSupplyClass" || nodePipeClass == "boilerReturnClass") {
      newStyle = newStyle > 0 ? newStyle - 100 : newStyle - 380;
    } else {
      newStyle = newStyle > 0 ? newStyle - 100 : newStyle - 380;
    }
    if (lineContent) {
      lineContent.y1.baseVal.value = newStyle;
    }
    this.checkMasterViewOverlap(selectedMasterId, lineId, equipId, nodePipeClass);
  }
  
  collide(ra, rb) {
    var a = this.norm(ra), b = this.norm(rb);
    var inx = this.isect(a.left, b.left, b.right)
      || this.isect(a.right, b.left, b.right)
      || this.inside(a.left, a.right, b.left, b.right)
      || this.inside(b.left, b.right, a.left, a.right)
      ;
    var iny = this.isect(a.top, b.top, b.bottom)
      || this.isect(a.bottom, b.top, b.bottom)
      || this.inside(a.top, a.bottom, b.top, b.bottom)
      || this.inside(b.top, b.bottom, a.top, a.bottom)
      ;
    return inx && iny;
  };

  isect(x, lower, upper) {
    return x >= lower && x <= upper;
  }

  inside(a0, a1, b0, b1) {
    return a0 >= b0 && a1 <= b1;
  }

  norm(q) {
    var p = {
      left: q.left,
      right: q.right,
      top: q.top,
      bottom: q.bottom
    };
    if (p.left === undefined && q.x !== undefined) p.left = q.x;
    if (p.top === undefined && q.y !== undefined) p.top = q.y;

    var w = q.width || 0, h = q.height || 0;

    if (p.right === undefined && q.x !== undefined) p.right = q.x + w;
    if (p.bottom === undefined && q.y !== undefined) p.bottom = q.y + h;
    return p;
  }

  /**
   * Retrieves the equipment data for a specific group equipment ID from the plant data.
   *
   * @param {Array} plantData - The array of plant data objects.
   * @param {string} equipId - The ID of the group equipment to retrieve.
   * @returns {Object|undefined} - The equipment data object if found, otherwise undefined.
   */
  getGroupEquipData(plantData, equipId) {
    return plantData.find(equipObj => equipObj.class === "GROUP" && equipObj.equipId == equipId);
  }

  /**
   * Retrieves the pump equipment data from the provided group data.
   *
   * @param {Object} groupData - The group data containing elements.
   * @returns {Object|null} - The pump equipment data or null if not found.
   */
  getPumpData(groupData) {
    let pumpEquipData = null;
    if (groupData?.data?.elements?.length) {
      groupData.data.elements.map((childNodeId) => {
        let childNodeData = this.getNodeFromId(String(childNodeId));
        if (childNodeData && this.isPumpEquip(childNodeData.childData.class)) {
          pumpEquipData = childNodeData;
        }
      });
      return pumpEquipData;
    }
  }

  getAlertRingClass(status){
    switch(status){
      case "equipOn":
        return "equipOnStatus";
      case "low":
          return "alertLow";
      case "moderate":
        return "alertModerate";
      case "severe":
        return "alertSevere";
      default:
        return "equipOffStatus";
    }
  }

  addEllipsis(text, textLimit) {
    return (text.length > textLimit) ? text.slice(0, textLimit - 1) + '&hellip;' : text;
  }
    
  isSensorNode(equipName, connector = false) {
    if(equipName == 'pipeDemarkation' || equipName == "connectTwo" ){
       return !connector?true:false;
    }else {
      return this.equipConfiguration.sensorEquipList.indexOf(equipName) != -1;
    }
  }

  isPipeDemarkationOrConnectTwo(inputNodeClass, outputNodeClass) {
    if ((inputNodeClass === 'pipeDemarkation' && outputNodeClass === 'pipeDemarkation') ||
      ((this.isParentEquip(inputNodeClass) && outputNodeClass === 'connectTwo') || (inputNodeClass === 'connectTwo' && this.isParentEquip(outputNodeClass)))) {
      return true;
    } else {
      return false;
    }
  }

  isParentEquip(equipName) {
    return this.equipConfiguration.parentEquipList.indexOf(equipName) != -1;
  }

  isChiller_Or_Boiler(equipName) {
    return this.equipConfiguration.chiller_Or_Boiler.indexOf(equipName) != -1;
  }

  isCoolingTower(equipName) {
    return this.equipConfiguration.coolingTowerList.indexOf(equipName) != -1;
  }

    /**
   * Checks if the given equipment name is a heat exchanger.
   * @param equipName - The name of the equipment to check.
   * @returns `true` if the equipment is a heat exchanger, `false` otherwise.
   */
    isHeatExchangerEquip(equipName) {
      return this.equipConfiguration.heatExchangerList.indexOf(equipName) != -1;
    }

  /**
* Checks if the given equipment name is a non-building parent equipment.
* @param equipName - The name of the equipment to check.
* @returns A boolean value indicating whether the equipment is a non-building parent equipment.
*/
  isNonBuildingParentEquip(equipName) {
    return this.equipConfiguration.nonBuildingParentEquip.indexOf(equipName) != -1;
  }


  getEquipType(equipName, parentEquip) {
    if (this.equipConfiguration.parentEquipList.indexOf(equipName) != -1 || equipName == 'btuMeter') {
      return 'equip';
    } else if (this.equipConfiguration.threeWayValveList.indexOf(equipName) != -1) {
      return '3wayValve';
    }
     else if (equipName == "connectorLbend"){
      return 'Lbend';
    } else if (equipName == "GROUP"){
      if (parentEquip.class == "connectorLbendPipe") {
        return 'Lbend';
      } else {
        return 'connector';
      }
    }
    return 'connector';
  }

  isSensorEquip(equipName) {
    return this.equipConfiguration.sensorEquipList.indexOf(equipName) != -1;
  }

    addRerouteImport(dataNode) {
      const reroute_width = this.reroute_width
      const reroute_fix_curvature = this.reroute_fix_curvature
      const container = this.container;
      Object.keys(dataNode.outputs).map(function(output_item, index) {
        Object.keys(dataNode.outputs[output_item].connections).map(function(input_item, index) {
          const points = dataNode.outputs[output_item].connections[input_item].points
          if(points !== undefined) {
  
            points.forEach((item, i) => {
              const input_id = dataNode.outputs[output_item].connections[input_item].node;
              const input_class = dataNode.outputs[output_item].connections[input_item].output;
              const ele = container.querySelector('.connection.node_in_node-'+input_id+'.node_out_node-'+dataNode.id+'.'+output_item+'.'+input_class);
  
              if(reroute_fix_curvature) {
                if(i === 0) {
                  for (var z = 0; z < points.length; z++) {
                    var path = document.createElementNS('http://www.w3.org/2000/svg',"path");
                    var topPath = document.createElementNS('http://www.w3.org/2000/svg',"path");
                    path.classList.add("main-path");
                    topPath.classList.add("pathBorder");
                    path.setAttributeNS(null, 'd', '');
                    topPath.setAttributeNS(null, 'd', '');
                    ele.appendChild(topPath);
                    ele.appendChild(path);
                  }
                }
              }
  
              const point = document.createElementNS('http://www.w3.org/2000/svg',"circle");
              point.classList.add("point");
              var pos_x = item.pos_x;
              var pos_y = item.pos_y;
  
              point.setAttributeNS(null, 'cx', pos_x);
              point.setAttributeNS(null, 'cy', pos_y);
              point.setAttributeNS(null, 'r', reroute_width);
  
              ele.appendChild(point);
            });
          };
        });
      });
    }
  
    updateNodeValue(event) {
      var attr = event.target.attributes
      for (var i = 0; i < attr.length; i++) {
              if (attr[i].nodeName.startsWith('df-')) {
                  var keys = attr[i].nodeName.slice(3).split("-");
                  var target = this.drawflow.drawflow[this.module].data[event.target.closest(".drawflow_content_node").parentElement.id.slice(5)].data;
                  for (var index = 0; index < keys.length - 1; index += 1) {
                      if (target[keys[index]] == null) {
                          target[keys[index]] = {};
                      }
                      target = target[keys[index]];
                  }
                  target[keys[keys.length - 1]] = event.target.value;
                  if(event.target.isContentEditable) {
                    target[keys[keys.length - 1]] = event.target.innerText;
                  }
                  this.dispatch('nodeDataChanged', event.target.closest(".drawflow_content_node").parentElement.id.slice(5));
            }
      }
    }
  
    updateNodeDataFromId(id, data) {
      var moduleName = this.getModuleFromNodeId(id)
      this.drawflow.drawflow[moduleName].data[id].data = data;
      if(this.module === moduleName) {
        const content = this.container.querySelector('#node-'+id);
  
        Object.entries(data).forEach(function (key, value) {
          if(typeof key[1] === "object") {
            insertObjectkeys(null, key[0], key[0]);
          } else {
            var elems = content.querySelectorAll('[df-'+key[0]+']');
              for(var i = 0; i < elems.length; i++) {
                elems[i].value = key[1];
                if(elems[i].isContentEditable) {
                  elems[i].innerText = key[1];
                }
              }
          }
        })
  
        function insertObjectkeys(object, name, completname) {
          if(object === null) {
            var object = data[name];
          } else {
            var object = object[name]
          }
          if(object !== null) {
            Object.entries(object).forEach(function (key, value) {
              if(typeof key[1] === "object") {
                insertObjectkeys(object, key[0], completname+'-'+key[0]);
              } else {
                var elems = content.querySelectorAll('[df-'+completname+'-'+key[0]+']');
                  for(var i = 0; i < elems.length; i++) {
                    elems[i].value = key[1];
                    if(elems[i].isContentEditable) {
                      elems[i].innerText = key[1];
                    }
                  }
              }
            });
          }
        }
  
      }
    }
  
    addNodeInput(id) {
      var moduleName = this.getModuleFromNodeId(id)
      const infoNode = this.getNodeFromId(id)
      const numInputs = Object.keys(infoNode.inputs).length;
      if(this.module === moduleName) {
        //Draw input
        const input = document.createElement('div');
        input.classList.add("input");
        input.classList.add("input_"+(numInputs+1));
        const parent = this.container.querySelector('#node-'+id+' .inputs');
        parent.appendChild(input);
        this.updateConnectionNodes('node-'+id);
  
      }
      this.drawflow.drawflow[moduleName].data[id].inputs["input_"+(numInputs+1)] = { "connections": []};
    }
  
    addNodeOutput(id) {
      var moduleName = this.getModuleFromNodeId(id)
      const infoNode = this.getNodeFromId(id)
      const numOutputs = Object.keys(infoNode.outputs).length;
      if(this.module === moduleName) {
        //Draw output
        const output = document.createElement('div');
        output.classList.add("output");
        output.classList.add("output_"+(numOutputs+1));
        const parent = this.container.querySelector('#node-'+id+' .outputs');
        parent.appendChild(output);
        this.updateConnectionNodes('node-'+id);
  
      }
      this.drawflow.drawflow[moduleName].data[id].outputs["output_"+(numOutputs+1)] = { "connections": []};
    }
  
    removeNodeInput(id, input_class) {
      var moduleName = this.getModuleFromNodeId(id)
      const infoNode = this.getNodeFromId(id)
      if(this.module === moduleName) {
        this.container.querySelector('#node-'+id+' .inputs .input.'+input_class).remove();
      }
      const removeInputs = [];
      Object.keys(infoNode.inputs[input_class].connections).map(function(key, index) {
        const id_output = infoNode.inputs[input_class].connections[index].node;
        const output_class = infoNode.inputs[input_class].connections[index].input;
        removeInputs.push({id_output, id, output_class, input_class})
      })
      // Remove connections
      removeInputs.forEach((item, i) => {
        this.removeSingleConnection(item.id_output, item.id, item.output_class, item.input_class);
      });
  
      delete this.drawflow.drawflow[moduleName].data[id].inputs[input_class];
  
      // Update connection
      const connections = [];
      const connectionsInputs = this.drawflow.drawflow[moduleName].data[id].inputs
      Object.keys(connectionsInputs).map(function(key, index) {
        connections.push(connectionsInputs[key]);
      });
      this.drawflow.drawflow[moduleName].data[id].inputs = {};
      const input_class_id = input_class.slice(6);
      let nodeUpdates = [];
      connections.forEach((item, i) => {
        item.connections.forEach((itemx, f) => {
          nodeUpdates.push(itemx);
        });
        this.drawflow.drawflow[moduleName].data[id].inputs['input_'+ (i+1)] = item;
      });
      nodeUpdates =  new Set(nodeUpdates.map(e => JSON.stringify(e)));
      nodeUpdates = Array.from(nodeUpdates).map(e => JSON.parse(e));
  
      if(this.module === moduleName) {
        const eles = this.container.querySelectorAll("#node-"+id +" .inputs .input");
        eles.forEach((item, i) => {
          const id_class = item.classList[1].slice(6);
          if(parseInt(input_class_id) < parseInt(id_class)) {
            item.classList.remove('input_'+id_class);
            item.classList.add('input_'+(id_class-1));
          }
        });
  
      }
  
      nodeUpdates.forEach((itemx, i) => {
        this.drawflow.drawflow[moduleName].data[itemx.node].outputs[itemx.input].connections.forEach((itemz, g) => {
            if(itemz.node == id) {
              const output_id = itemz.output.slice(6);
              if(parseInt(input_class_id) < parseInt(output_id)) {
                if(this.module === moduleName) {
                  const ele = this.container.querySelector(".connection.node_in_node-"+id+".node_out_node-"+itemx.node+"."+itemx.input+".input_"+output_id);
                  ele.classList.remove('input_'+output_id);
                  ele.classList.add('input_'+(output_id-1));
                }
                if(itemz.points) {
                    this.drawflow.drawflow[moduleName].data[itemx.node].outputs[itemx.input].connections[g] = { node: itemz.node, output: 'input_'+(output_id-1), points: itemz.points }
                } else {
                    this.drawflow.drawflow[moduleName].data[itemx.node].outputs[itemx.input].connections[g] = { node: itemz.node, output: 'input_'+(output_id-1)}
                }
              }
            }
        });
      });
      this.updateConnectionNodes('node-'+id);
    }
  
    removeNodeOutput(id, output_class) {
      var moduleName = this.getModuleFromNodeId(id)
      const infoNode = this.getNodeFromId(id)
      if(this.module === moduleName) {
        this.container.querySelector('#node-'+id+' .outputs .output.'+output_class).remove();
      }
      const removeOutputs = [];
      Object.keys(infoNode.outputs[output_class].connections).map(function(key, index) {
        const id_input = infoNode.outputs[output_class].connections[index].node;
        const input_class = infoNode.outputs[output_class].connections[index].output;
        removeOutputs.push({id, id_input, output_class, input_class})
      })
      // Remove connections
      removeOutputs.forEach((item, i) => {
        this.removeSingleConnection(item.id, item.id_input, item.output_class, item.input_class);
      });
  
      delete this.drawflow.drawflow[moduleName].data[id].outputs[output_class];
  
      // Update connection
      const connections = [];
      const connectionsOuputs = this.drawflow.drawflow[moduleName].data[id].outputs
      Object.keys(connectionsOuputs).map(function(key, index) {
        connections.push(connectionsOuputs[key]);
      });
      this.drawflow.drawflow[moduleName].data[id].outputs = {};
      const output_class_id = output_class.slice(7);
      let nodeUpdates = [];
      connections.forEach((item, i) => {
        item.connections.forEach((itemx, f) => {
          nodeUpdates.push({ node: itemx.node, output: itemx.output });
        });
        this.drawflow.drawflow[moduleName].data[id].outputs['output_'+ (i+1)] = item;
      });
      nodeUpdates =  new Set(nodeUpdates.map(e => JSON.stringify(e)));
      nodeUpdates = Array.from(nodeUpdates).map(e => JSON.parse(e));
  
      if(this.module === moduleName) {
        const eles = this.container.querySelectorAll("#node-"+id +" .outputs .output");
        eles.forEach((item, i) => {
          const id_class = item.classList[1].slice(7);
          if(parseInt(output_class_id) < parseInt(id_class)) {
            item.classList.remove('output_'+id_class);
            item.classList.add('output_'+(id_class-1));
          }
        });
  
      }
  
      nodeUpdates.forEach((itemx, i) => {
        this.drawflow.drawflow[moduleName].data[itemx.node].inputs[itemx.output].connections.forEach((itemz, g) => {
            if(itemz.node == id) {
              const input_id = itemz.input.slice(7);
              if(parseInt(output_class_id) < parseInt(input_id)) {
                if(this.module === moduleName) {
  
                  const ele = this.container.querySelector(".connection.node_in_node-"+itemx.node+".node_out_node-"+id+".output_"+input_id+"."+itemx.output);
                  ele.classList.remove('output_'+input_id);
                  ele.classList.remove(itemx.output);
                  ele.classList.add('output_'+(input_id-1));
                  ele.classList.add(itemx.output);
                }
                if(itemz.points) {
                    this.drawflow.drawflow[moduleName].data[itemx.node].inputs[itemx.output].connections[g] = { node: itemz.node, input: 'output_'+(input_id-1), points: itemz.points }
                } else {
                    this.drawflow.drawflow[moduleName].data[itemx.node].inputs[itemx.output].connections[g] = { node: itemz.node, input: 'output_'+(input_id-1)}
                }
              }
            }
        });
      });
  
      this.updateConnectionNodes('node-'+id);
    }

    getPipeClassOfNode(id) {
      const classArray = ["CDWReturnClass", "CDWSupplyClass", "CWReturnClass", "CWSupplyClass", "boilerReturnClass", "boilerSupplyClass", "DTSupplyClass", "DTReturnClass"];
      let pipeClass = '';
      document.getElementById(id).getElementsByClassName('drawflow_content_node')[0].classList.forEach(_class => {
        if (classArray.includes(_class)) {
          pipeClass = _class;
        }
      });
      return pipeClass;
    }
  
    removeNodeId(id) {
      var moduleName = this.getModuleFromNodeId(id.slice(5));
      const deletedNodeData = this.getNodeFromId(id.slice(5));
      if (deletedNodeData) {
        this.deletedNodeData =  JSON.parse(JSON.stringify(deletedNodeData));
        this.removeConnectionNodeId(id);
        if (this.deletedNodeData?.class == 'pump1Vertical') {
          if (this.deletedNodeData?.connectedParentClass == "connectorLbend") {
            this.deletedNodeData.currentDeletedPipeClass = this.deletedNodeData.connectedParentSubEquipPipeClass;
          } else {
            this.deletedNodeData.currentDeletedPipeClass = this.getPipeClassOfNode(id);
          }
        }
        if (this.container.querySelector(`#${id}`)) {
          if(this.module === moduleName) {
            this.container.querySelector(`#${id}`).remove();
          }
          if(this.deletedNodeData?.class == "GROUP"){
            this.dispatch('nodeGroupRemoved', id.slice(5));
          }else{
            this.dispatch('nodeRemoved', id.slice(5));
          }
          delete this.drawflow?.drawflow[moduleName]?.data[id.slice(5)];
        }
      }
    }

    hasKeywords(element) {
      const keywords = ['btu', 'pump', 'temperatureSensor', 'valveActuator', 'wellTemperature', 'pressureSensor', 'diffPressureSensor', 'flowSensor', 'flowSwitch'];
      return keywords.some(keyword => element.innerHTML.includes(keyword));
    }

  btuOrPumpOnConnectingPipe(listclass) {
    if (listclass.contains("connector-pipe")) {
      const nodeInTokens = Array.from(listclass).filter(token => token.startsWith('node_in'));
      const nodeOutTokens = Array.from(listclass).filter(token => token.startsWith('node_out'));
      const nodeInTokensAsString = nodeInTokens.join(' ');
      const nodeOutTokensAsString = nodeOutTokens.join(' ');
      const nodeInMatch = nodeInTokensAsString.match(/node_in_node-(\d+)/);
      const nodeOutMatch = nodeOutTokensAsString.match(/node_out_node-(\d+)/);

      const classLeft = `node_in_node-${nodeOutMatch[1]}`;
      const classRight = `node_out_node-${nodeInMatch[1]}`;
      const classLeft2 = `node_in_node-${nodeInMatch[1]}`;
      const classRight2 = `node_out_node-${nodeOutMatch[1]}`;

      const elementsArrayLeft = document.getElementsByClassName(classLeft);
      const filteredElementsLeft1 = Array.from(elementsArrayLeft).filter(
        element => (element.classList.contains('connector-pipe') && this.hasKeywords(element))
      );

      const elementsArrayLeft2 = document.getElementsByClassName(classLeft2);
      const filteredElementsLeft2 = Array.from(elementsArrayLeft2).filter(
        element => (element.classList.contains('connector-pipe') && this.hasKeywords(element))
      );

      const elementsArrayRight = document.getElementsByClassName(classRight);
      const filteredElementsRight1 = Array.from(elementsArrayRight).filter(
        element => (element.classList.contains('connector-pipe')&& this.hasKeywords(element))
      );
      const elementsArrayRight2 = document.getElementsByClassName(classRight2);
      const filteredElementsRight2 = Array.from(elementsArrayRight2).filter(
        element => (element.classList.contains('connector-pipe')&& this.hasKeywords(element))
      );

      const filteredElements = filteredElementsLeft1.concat(filteredElementsRight1, filteredElementsLeft2,filteredElementsRight2 );
      if(filteredElements.length > 0){
        return true;
      }else{
        return false
      }
    }
  }
  
    removeConnection() {
      if(this.connection_selected != null) {
        var listclass = this.connection_selected.parentElement.classList;
        
        if(this.btuOrPumpOnConnectingPipe(listclass)){
          this.dispatch('connectionNotRemoved', 'Delete Sensors on the connecting Pipe then delete the connecting pipe')
          return;
        }
        this.connection_selected.parentElement.remove();
        var index_out = this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.findIndex(function(item,i) {
          return item.node === listclass[1].slice(13) && item.output === listclass[4];
        });
        this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.splice(index_out,1);
        var index_in = this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.findIndex(function(item,i) {
          return item.node === listclass[2].slice(14) && item.input === listclass[3];
        });
        this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.splice(index_in,1);
        this.dispatch('connectionRemoved', { output_id: listclass[2].slice(14), input_id: listclass[1].slice(13), output_class: listclass[3], input_class: listclass[4] } );
        this.connection_selected = null;
      }
    }
  
    removeSingleConnection(id_output, id_input, output_class, input_class) {
      var nodeOneModule = this.getModuleFromNodeId(id_output);
      var nodeTwoModule = this.getModuleFromNodeId(id_input);
      if(nodeOneModule === nodeTwoModule) {
        // Check nodes in same module.
  
        // Check connection exist
        var exists = this.drawflow.drawflow[nodeOneModule].data[id_output].outputs[output_class].connections.findIndex(function(item,i) {
          return item.node == id_input && item.output === input_class
        });
        if(exists > -1) {
  
          if(this.module === nodeOneModule) {
            // In same module with view.
            this.container.querySelector('.connection.node_in_node-'+id_input+'.node_out_node-'+id_output+'.'+output_class+'.'+input_class).remove();
          }
  
          var index_out = this.drawflow.drawflow[nodeOneModule].data[id_output].outputs[output_class].connections.findIndex(function(item,i) {
            return item.node == id_input && item.output === input_class
          });
          this.drawflow.drawflow[nodeOneModule].data[id_output].outputs[output_class].connections.splice(index_out,1);
  
          var index_in = this.drawflow.drawflow[nodeOneModule].data[id_input].inputs[input_class].connections.findIndex(function(item,i) {
            return item.node == id_output && item.input === output_class
          });
          this.drawflow.drawflow[nodeOneModule].data[id_input].inputs[input_class].connections.splice(index_in,1);
  
          this.dispatch('connectionRemoved', { output_id: id_output, input_id: id_input, output_class:  output_class, input_class: input_class});
          return true;
  
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  
    removeConnectionNodeId(id) {
      const idSearchIn = 'node_in_'+id;
      const idSearchOut = 'node_out_'+id;
  
      const elemsOut = this.container.querySelectorAll(`.${idSearchOut}`);
      for(var i = elemsOut.length-1; i >= 0; i--) {
        var listclass = elemsOut[i].classList;
  
        var index_in = this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.findIndex(function(item,i) {
          return item.node === listclass[2].slice(14) && item.input === listclass[3]
        });
        this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.splice(index_in,1);
  
        var index_out = this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.findIndex(function(item,i) {
          return item.node === listclass[1].slice(13) && item.output === listclass[4]
        });
        this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.splice(index_out,1);
  
        elemsOut[i].remove();
  
        this.dispatch('connectionRemoved', { output_id: listclass[2].slice(14), input_id: listclass[1].slice(13), output_class: listclass[3], input_class: listclass[4] } );
      }
  
      const elemsIn = this.container.querySelectorAll(`.${idSearchIn}`);
      for(var i = elemsIn.length-1; i >= 0; i--) {
  
        var listclass = elemsIn[i].classList;
  
        var index_out = this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.findIndex(function(item,i) {
          return item.node === listclass[1].slice(13) && item.output === listclass[4]
        });
        this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.splice(index_out,1);
  
        var index_in = this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.findIndex(function(item,i) {
          return item.node === listclass[2].slice(14) && item.input === listclass[3]
        });
        this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.splice(index_in,1);
  
        elemsIn[i].remove();
  
        this.dispatch('connectionRemoved', { output_id: listclass[2].slice(14), input_id: listclass[1].slice(13), output_class: listclass[3], input_class: listclass[4] } );
      }
    }
  
    getModuleFromNodeId(id) {
      var nameModule;
      const editor = this.drawflow.drawflow
      Object.keys(editor).map(function(moduleName, index) {
        Object.keys(editor[moduleName].data).map(function(node, index2) {
          if(node == id) {
            nameModule = moduleName;
          }
        })
      });
      return nameModule;
    }
  
    addModule(name) {
      this.drawflow.drawflow[name] =  { "data": {} };
      this.dispatch('moduleCreated', name);
    }
    changeModule(name) {
      this.dispatch('moduleChanged', name);
      this.module = name;
      this.precanvas.innerHTML = "";
      this.canvas_x = 0;
      this.canvas_y = 0;
      this.pos_x = 0;
      this.pos_y = 0;
      this.mouse_x = 0;
      this.mouse_y = 0;
      this.zoom = 1;
      this.zoom_last_value = 1;
      this.precanvas.style.transform = '';
      this.import(this.drawflow, false);
    }
  
    removeModule(name) {
      if(this.module === name) {
        this.changeModule('Home');
      }
      delete this.drawflow.drawflow[name];
      this.dispatch('moduleRemoved', name);
    }
  
    clearModuleSelected() {
      this.precanvas.innerHTML = "";
      this.drawflow.drawflow[this.module] =  { "data": {} };
    }
  
    clear () {
      this.precanvas.innerHTML = "";
      this.drawflow = { "drawflow": { "Home": { "data": {} }}};
    }
    export () {
      const dataExport = JSON.parse(JSON.stringify(this.drawflow));
      this.dispatch('export', dataExport);
      return dataExport;
    }
  
    import (data, notifi = true) {
      this.clear();
      this.drawflow = JSON.parse(JSON.stringify(data));
      this.load();
      if(notifi) {
        this.dispatch('import', 'import');
      }
    }
  
    /* Events */
    on (event, callback) {
         // Check if the callback is not a function
         if (typeof callback !== 'function') {
             console.error(`The listener callback must be a function, the given type is ${typeof callback}`);
             return false;
         }
         // Check if the event is not a string
         if (typeof event !== 'string') {
             console.error(`The event name must be a string, the given type is ${typeof event}`);
             return false;
         }
         // Check if this event not exists
         if (this.events[event] === undefined) {
             this.events[event] = {
                 listeners: []
             }
         }
         this.events[event].listeners.push(callback);
     }
  
     removeListener (event, callback) {
        // Check if this event not exists
  
        if (!this.events[event]) return false
  
        const listeners = this.events[event].listeners
        const listenerIndex = listeners.indexOf(callback)
        const hasListener = listenerIndex > -1
        if (hasListener) listeners.splice(listenerIndex, 1)
     }
  
     dispatch (event, details) {
         // Check if this event not exists
         if (this.events[event] === undefined) {
             // console.error(`This event: ${event} does not exist`);
             return false;
         }
         this.events[event].listeners.forEach((listener) => {
             listener(details);
         });
     }
  
    getUniqueid() {
      // http://www.ietf.org/rfc/rfc4122.txt
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 24; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      var uuid = s.join("") + String(Date.now());
      return uuid;
    }

    // Adding warning icons on pipes, when subEquipPipe is deleted.
    addWarningIconOnPipes(key) {
      const self = this;
      const id = key.split('-')[1];
      // Checking if it is header pipe or equip.
      if (this.isHeaderPipe(this.drawflow.drawflow.Home.data[id].childData.class)) {
        this.addWarningIconOnHeaderPipe(id);
      } else {
        // Checking the "subEquipRefDeleted" is true or not. To identify the subequippiperef is deleted on equip.
        if (this.drawflow.drawflow.Home.data[id]?.subEquipRefDeleted) {
          // From this property "warningIcon-cl" will get know to which svg class warning icon to attach.
          const className = this.drawflow.drawflow.Home.data[id]['warningIcon-cl'].replaceAll(' ', '.');
          // Creating a SVG element and adding to drawflow element.
          const svgElement = document.querySelector('.' + className);
          const mainDiv = document.getElementsByClassName("drawflow")[0];
          const pathCords = svgElement?.querySelector('.main-path').getAttribute("d").trim().split(' ');
          const svgWarningIcon = document.createElementNS('http://www.w3.org/2000/svg',"svg");
          svgWarningIcon.setAttribute("viewBox", "0 0 40 40");
          svgWarningIcon.setAttribute("width", 40);
          svgWarningIcon.setAttribute("height", 40);
          svgWarningIcon.setAttribute('class', `svg_warning-icon ${this.drawflow.drawflow.Home.data[id].equipId}_warning-icon`);
          const g = document.createElementNS('http://www.w3.org/2000/svg',"g");
          // Below is the code to add to show tooltip when hover on the icon.
          svgWarningIcon.addEventListener('mouseover', function(event) {
            const mainDiv = document.getElementsByClassName("drawflow")[0];
            document.getElementById('warningIconTooltipOnPipe')?.remove();
            const tooltip = document.createElement('div');
            tooltip.id = 'warningIconTooltipOnPipe';
            tooltip.innerHTML = 'One or more equips are deleted, please update the central plant.';
            tooltip.style.display = "block";

            if (pathCords?.length) {
              if (this.equipConfiguration?.rightSideEquips?.includes(self.drawflow.drawflow.Home.data[id].childData.class)) {
                tooltip.style.left = Number(pathCords[1]) + 300 + 'px';
                tooltip.style.top = Number(pathCords[2]) - 180 + 'px';
              } else {
                tooltip.style.left = Number(pathCords[1]) + 300 + 'px';
                tooltip.style.top = Number(pathCords[2]) + 95 + 'px';
              }
            }

            tooltip.style.position = 'absolute';
            tooltip.style.textAlign = 'center';
            tooltip.style.zIndex = 1111;
            mainDiv.appendChild(tooltip);
          });
          // Below is the code to hide tooltip when mouse out of the icon.
          svgWarningIcon.addEventListener('mouseout', function() {
            const tooltipDiv = document.getElementById('warningIconTooltipOnPipe');
            tooltipDiv.style.display = "none";
          });
          // Getting pathcords and adding the icon to that pipe.
          const path = document.createElementNS('http://www.w3.org/2000/svg',"path");
          path.classList.add('exclamation-triangle-fill');
          let pos_x;
          let pos_y;

          // differentiating the equips forward or backward.
          if (pathCords?.length) {
            if (this.equipConfiguration?.rightSideEquips?.includes(this.drawflow.drawflow.Home.data[id].childData.class)) {
              pos_x = Number(pathCords[1]) + 250;
              pos_y = Number(pathCords[2]) - 160;
            } else {
              pos_x = Number(pathCords[1]) + 250;
              pos_y = Number(pathCords[2]) + 125;
            }
          }
  
          g.style.transform = "translate("+pos_x+"px, "+pos_y+"px) scale(2)";
          path.setAttributeNS(null, 'd', 'M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z');
          g.appendChild(path);
          svgWarningIcon.appendChild(g);
          // Appending the warning icon to the drawflow div.
          mainDiv.appendChild(svgWarningIcon);
        }
      }
    }

    addWarningIconOnHeaderPipe(key) {
      const self = this;
      // Checking the "equipDeleted" is true or not and pipeArrow present in the node data.
      if (this.drawflow.drawflow.Home.data[key]?.equipDeleted && this.drawflow.drawflow.Home.data[key].html.includes("pipeArrow.svg")) {
        // From this property "warningIcon-cl" will get know to which svg class warning icon to attach.
        const className = this.drawflow.drawflow.Home.data[key]['warningIcon-cl']?.replaceAll(' ', '.');
        // Creating a SVG element and adding to drawflow element.
        const svgElement = document.querySelector('.' + className);
        const mainDiv = document.getElementsByClassName("drawflow")[0];
        const pathCords = svgElement?.querySelector('.main-path').getAttribute("d").trim().split(' ');
        const svgWarningIcon = document.createElementNS('http://www.w3.org/2000/svg',"svg");
        svgWarningIcon.setAttribute("viewBox", "0 0 40 40");
        svgWarningIcon.setAttribute("width", 40);
        svgWarningIcon.setAttribute("height", 40);
        svgWarningIcon.setAttribute('class', `svg_warning-icon ${this.drawflow.drawflow.Home.data[key].equipId}_warning-icon`);
        const g = document.createElementNS('http://www.w3.org/2000/svg',"g");
        // Below is the code to add to show tooltip when hover on the icon.
        svgWarningIcon.addEventListener('mouseover', function(event) {
          const mainDiv = document.getElementsByClassName("drawflow")[0];
          document.getElementById('warningIconTooltipOnPipe')?.remove();
          const tooltip = document.createElement('div');
          tooltip.id = 'warningIconTooltipOnPipe';
          tooltip.innerHTML = 'One or more equips are deleted, please update the central plant.';
          tooltip.style.display = "block";
          let pos_x = Number(pathCords[1]) + 250;
          let pos_y = Number(pathCords[2]) + 125;
          if (self.drawflow.drawflow.Home.data[key].html.includes('pipeArrowImg_forward')) {
            pos_x = Number(pathCords[7]) - 40;
            pos_y = Number(pathCords[8]) + 20;
          }
          tooltip.style.left = pos_x + 'px';
          tooltip.style.top = pos_y + 'px';
          tooltip.style.position = 'absolute';
          tooltip.style.textAlign = 'center';
          tooltip.style.zIndex = 1111;
          mainDiv.appendChild(tooltip);
        });
        // Getting pathcords and adding the icon to that pipe.
        svgWarningIcon.addEventListener('mouseout', function() {
          const tooltipDiv = document.getElementById('warningIconTooltipOnPipe');
          tooltipDiv.style.display = "none";
        });
        const path = document.createElementNS('http://www.w3.org/2000/svg',"path");
        path.classList.add('exclamation-triangle-fill');
        let pos_x = Number(pathCords[1]) + 250;
        let pos_y = Number(pathCords[2]) + 125;
        // Checking node data property html consists of "pipeArrowImg_forward". this will say that it is first node element for the header pipe.
        if (this.drawflow.drawflow.Home.data[key].html.includes('pipeArrowImg_forward')) {
          pos_x = Number(pathCords[7]) - 100;
          pos_y = Number(pathCords[8]) + 20;
        }
        g.style.transform = "translate("+pos_x+"px, "+pos_y+"px) scale(2)";
        path.setAttributeNS(null, 'd', 'M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z');
        g.appendChild(path);
        svgWarningIcon.appendChild(g);
        mainDiv.appendChild(svgWarningIcon);
      }
    }

  /**
   * Fits the nodes within the view of the drawflow container.
   *
   * @param {Object} drawflow - The drawflow object.
   */
  fitNodesToView(drawflow) {
    const nodes = drawflow.container.querySelectorAll('.drawflow-node');
    if (nodes?.length === 0) {
      this.zoom = this.zoom_last_value = 0.3;
      this.zoom_refresh();
      return;
    } 

    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

    // Find the min and max x and y coordinates of the nodes.
    nodes.forEach(node => {
      if (!node.classList.contains('GROUP')) {
        const nodeX = parseInt(node.style.left);
        const nodeY = parseInt(node.style.top);
        const nodeWidth = node.offsetWidth;
        const nodeHeight = node.offsetHeight;

        minX = Math.min(minX, nodeX);
        minY = Math.min(minY, nodeY);
        maxX = Math.max(maxX, nodeX + nodeWidth);
        maxY = Math.max(maxY, nodeY + nodeHeight);
      }
    });

    const drawflowRect = drawflow.container.getBoundingClientRect();
    // calculate the width and height of the nodes area
    const nodesWidth = maxX - minX;
    const nodesHeight = maxY - minY;

    // calculate the scale to fit the nodes within the view
    const scaleX = drawflowRect.width / nodesWidth;
    const scaleY = drawflowRect.height / nodesHeight;

    // calculate the scale to fit the nodes within the view from ScaleX and ScaleY
    let scale = Math.min(scaleX, scaleY);

    // decrease the scale by 0.02 to avoid the nodes to be at the edge of the view
    if (scale < 0) {
      scale = 0.1;
    } else if (scale > 0.03) {
      scale = scale - 0.02;
    }
  

    // calculate the translation to center the nodes within the view
    this.canvas_x = (drawflowRect.width / nodesWidth * scale) / 2 - minX * scale;
    this.canvas_y = (drawflowRect.height / nodesHeight * scale) / 2 - minY * scale;

    // Apply the translation and scale to the drawflow container to center 
    this.canvas_x = this.canvas_x - drawflowRect.width / 3;
    this.canvas_y = this.canvas_y - drawflowRect.height / 3;

    // set the zoom value to the calculated scale
    this.zoom = this.zoom_last_value = scale;
    
    // set the transform style to the drawflow container
    this.precanvas.style.transform = `translate(${this.canvas_x}px, ${this.canvas_y}px) scale(${scale})`;

    // dispatch the zoom event
    this.dispatch('zoom', scale.toFixed(2));
  }

  }





